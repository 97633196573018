import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import footeIcon from "./send.svg";
import ReactWhatsapp from "react-whatsapp";
import { useDispatch } from "react-redux";
import { onUserOfferSubmission } from "../../redux/homeAction";

const Footer = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const subscribingUser = (e) => {
    e.preventDefault();
    const data = {
      email: email,
    };
    dispatch(onUserOfferSubmission(data, setEmail));
  };

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="form-box">
                <h1>SUBSCRIBING TO OUR NEWSLETTER :</h1>
                <div className="footer-form">
                  <form className="d-flex" onSubmit={subscribingUser}>
                    <i className="fa fa-envelope-o" />
                    <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Email"
                      aria-label="Search"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <a>
                      <img src={footeIcon} />
                    </a>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="text-1">
                <h1>ABOUT :</h1>
                <ul>
                  {/* <li>
                    <NavLink
                      to="/"
                      className={({ isActive }) => (isActive ? "active" : "")}
                      end
                    >
                      HOME
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/about-us">ABOUT US</NavLink>
                  </li>
                  <li>
                    <NavLink to="/term-condition">TERMS AND CONDITIONS</NavLink>
                  </li>
                  <li>
                    <NavLink to="/cookies-policy">COOKIE POLICY</NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacy-policy">PRIVACY POLICY</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/contact-us">CONTACT US</NavLink>
                  </li>
                  <li>
                    <NavLink to="/faq">FAQ</NavLink>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="text-1">
                <h1>COMMUNITY :</h1>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/triunfador.net"
                      target="__blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>{" "}
                      <span>FACEBOOK</span>{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/TriunfadorWeb"
                      target="__blank"
                    >
                      <i className="fa-brands fa-twitter"></i>
                      <span>X</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@Triunfadorweb"
                      target="__blank"
                    >
                      <i className="fa-brands fa-youtube"></i>{" "}
                      <span>YOUTUBE</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/triunfadorweb"
                      target="__blank"
                    >
                      <i className="fa-brands fa-instagram"></i>{" "}
                      <span>INSTAGRAM</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/@triunfadorweb"
                      target="__blank"
                    >
                      <i className="fa-brands fa-tiktok"></i>{" "}
                      <span>TIKTOK</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="text-1">
                <h1>SUPPORT :</h1>
                <ul>
                  {/* <li>
                    <ReactWhatsapp number="9865336545" message="Hello World!!!">
                      {" "}
                      +91-9865336545
                    </ReactWhatsapp>
                  </li> */}
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">CONTACT US</Link>
                  </li>
                  <li
                    onClick={() =>
                      (window.location = "mailto:soporte@triunfador.net")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Link>soporte@triunfador.net</Link>
                    {/* <Link>SOPORTE@TRIUNFADOR.NET</Link> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="copy-right">
                <p>Copyright 2023 triunfador.net | All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
