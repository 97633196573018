import React from "react";

const AboutSection = () => {
  return (
    <>
      <section className="about">
        <div className="row">
          <div className="col-lg-6">
            <img src="./images/aboutus.jpg" />
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div className="about-content">
              <h1 className="section_head">
                About <span className="color_yellow">Us</span>
              </h1>
              <p className="mb-3">
                Triunfador.net is a FREE entertainment portal where you can
                withdraw REAL MONEY through Paypal, BitCoin, and Binance,
                wallets of altcoins like XRP, TRX, SOL, ADA, DOGE, LTC, among
                others. You can also win completely free gifts like consoles,
                video games, Smart TVs, shirts of your favorite teams and many
                other prizes.
              </p>
              <p className="mb-3">
                Triunfador focuses on providing free, high-quality entertainment
                for those who enjoy competing, betting and playing, with the
                possibility of winning wonderful prizes and real money. These
                are the main sections that make up the portal:
              </p>
              <p className="mb-3">
                <b>SPORTS:</b> You can make bets on your favorite sports and
                teams. You can bet with points that have no real value, which
                you can redeem for fantastic real prizes. Visit the SPORTS
                BETTING section and bet without any risk and the possibility of
                winning many prizes.
              </p>
              <p className="mb-3">
                <b>COMPETITIONS:</b> You can find a wide variety of competitions
                where you will demonstrate your knowledge, analysis,
                predictions, picks, and everything you know about sports. You
                will have to defeat the other participants in the competitions
                with your skill and knowledge, and win wonderful prizes in real
                money. Visit each competition and find out all the details.
              </p>
              <p className="mb-3">
                <b>CASINO:</b> You can play Casino games like Blackjack,
                Roulette, and others, and withdraw your real money winnings.
              </p>
              <p className="mb-3">
                <b>GAMES:</b> You can enjoy games in many categories that will
                allow you to increase your balance and increase your real money
                balance to be withdrawn. Stop wasting your time on games that do
                not reward you and come and compete to win for real. Your time
                is worth gold!
              </p>
              <p>
                On triunfador.net you can turn your time, knowledge and talent
                into real money. Your time, effort, and skill are worth a lot.
                Why continue losing them on websites that do not reward you? On
                Triunfador you will have a profitable, enjoyable and
                entertaining experience
              </p>
            </div>
          </div>
          <div className="ad_section">
            <div className="container">
              <div className="row ad_content">
                <div className="col-md-12">
                  <img src="./images/bonus_ad.png" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSection;
