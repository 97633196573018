import React from "react";

const Pagination = ({ setShowPerPage, showPerPage, totalPosts }) => {
  const pages = [];

  for (let i = 1; i <= Math.ceil(totalPosts / 10); i++) {
    pages.push(i);
  }

  return (
    <>
      {" "}
      <nav aria-label="Page navigation">
        <ul className="pagination display mt-3">
          <li className="page-item previous">
            <a
              className="page-link"
              onClick={() => {
                if (showPerPage > 1) {
                  setShowPerPage(showPerPage - 1);
                }
              }}
            >
              Previous
            </a>
          </li>
          {pages &&
            pages.map((page, index) => (
              <li className="page-item" key={index}>
                <a
                  className={`page-link ${
                    showPerPage === page ? "active" : ""
                  }`}
                  onClick={() => setShowPerPage(page)}
                >
                  {page}
                </a>
              </li>
            ))}
          <li className="page-item next">
            <a
              className="page-link active"
              onClick={() => {
                if (showPerPage < pages.length) {
                  setShowPerPage(showPerPage + 1);
                }
              }}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
