import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import BetModel from "../Models/BetModel/BetModel";
import RestrictedModel from "../Models/RestrictedModel/RestrictedModel";
import Navbar from "../Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  onFetchSports,
  onGetCountriesLeagues,
  onGetCountriesList,
  onGetMatchList,
  onUserBetSport,
} from "../../redux/homeAction";
import moment from "moment";
import Banner from "../Banner/Banner";
import Loader from "../loader/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const GameBets = ({ socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState("");
  const [country, setCountry] = useState("");
  const [loader, setLoader] = useState(false);
  const [matchData, setMatchData] = useState({});
  const [teamId, setTeamId] = useState("");
  const [showData, setShowData] = useState([]);
  const [amount, setAmount] = useState("");
  const classSend = "position-relative";

  const getOnGetFetchSports = useSelector(
    (state) => state.home.getOnGetFetchSports
  );

  const getOnGetCountriesList = useSelector(
    (state) => state.home.getOnGetCountriesList
  );

  const getOnGetMatchList = useSelector(
    (state) => state.home.getOnGetMatchList
  );

  const getOnGetCountriesLeagues = useSelector(
    (state) => state.home.getOnGetCountriesLeagues
  );

  const getUserBetsSports = useSelector(
    (state) => state.home.getUserBetsSports
  );

  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    setLoader(true);
    dispatch(onFetchSports(setLoader));
    dispatch(onGetCountriesList());
  }, []);

  // useEffect(() => {
  //   console.log(show, "show");
  //   dispatch(onGetCountriesLeagues(data));
  // }, [show]);

  useEffect(() => {
    if (getOnGetMatchList) {
      addSocketData();
    }
  }, [getOnGetMatchList]);

  const changeValueAndApi = (e) => {
    const data = {
      countries_id: e.target.value,
    };
    setCountry(e.target.value);
    dispatch(onGetCountriesLeagues(data));
  };

  const changeLeague = (e) => {
    const data = {
      countries_id: country,
      countries_leagues_id: e.target.value,
      sport_types: show,
    };
    dispatch(onGetMatchList(data));
  };

  const userBetsGame = (id) => {
    if (!teamId) {
      toast.error("please selete team bet");
    } else if (!amount) {
      toast.error("please enter a amount");
    } else {
      const data = {
        user_id: userDeatils && userDeatils.user_id,
        listmatches_id: id,
        team_id: teamId,
        amount: amount,
      };
      dispatch(onUserBetSport(data, setTeamId, setAmount));
    }
  };

  const openModelBYId = (id) => {
    const data = showData && showData.find((data, index) => data.id === id);
    setMatchData(data);
  };

  const lang = localStorage.getItem("lang");

  useEffect(() => {
    if (getOnGetMatchList) {
      for (let i = 0; i < getOnGetMatchList.length; i++) {
        if (getOnGetMatchList[i].match_status === "Live") {
          const dataA = {
            match_key: getOnGetMatchList[i].matchkey,
            match_format: "Decimal",
            match_status: "live",
          };
          socket.emit("match-data-odds", dataA);
        }
      }
    }
  }, [getOnGetMatchList]);

  const addSocketData = () => {
    var emptyArray = [];
    var dataStore = [];
    socket.on("match-data-odds", async (res) => {
      emptyArray.push(JSON.parse(res));
      for (let i = 0; i < getOnGetMatchList?.length; i++) {
        const matchItem = emptyArray.find(
          (element) =>
            parseFloat(element.eventsId) ===
            parseFloat(getOnGetMatchList[i].matchkey)
        );
        if (matchItem) {
          var updateDataValue = { ...getOnGetMatchList[i], ...matchItem };
          dataStore.push(updateDataValue);
        }
        setShowData(dataStore);
      }
    });
  };

  const todayDate = new Date();

  return (
    <>
      <Navbar classSend={classSend} />
      <section id="Tabs_section" className="Bats_tab">
        <div className="container">
          <h1 className="h1_60">BETS</h1>
          <div className="ad_section ad-top">
            <div className="row ad_content">
              <div className="col-md-12">
                <img src="./images/ad2.png" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="Sports_tabs">
            {loader ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="tabs_nav">
                <div className="row w-100 justify-content-center">
                  {getOnGetFetchSports &&
                    getOnGetFetchSports.map((data, i) => (
                      <div
                        onClick={() => {
                          const dataA = {
                            sport_types: data.id,
                          };
                          dispatch(onGetMatchList(dataA));
                          setShow(data.id);
                        }}
                        className={`col-md-4 sports_active_show mb-2 ${
                          show === data.id ? "sports_active" : ""
                        }`}
                        key={i}
                      >
                        <div className="">
                          <div className="sports_img sports_img1">
                            <img
                              src={data.image}
                              // alt={data.sport_name}
                              className="img-fluid"
                            />
                          </div>
                          <a>{data.sport_name}</a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div className="bets_section">
              <div className="bets_content">
                <div className="matches_history">
                  <div className="row dropdowns gy-3">
                    <div className="col-md-6 col-sm-6 col-lg-3">
                      <div className="selectCountry">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => changeValueAndApi(e)}
                        >
                          <option>Country</option>
                          {getOnGetCountriesList &&
                            getOnGetCountriesList.map((data, i) => (
                              <option value={data.id} key={i}>
                                {data.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-3">
                      <div className="selectLeague">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => changeLeague(e)}
                        >
                          <option>league</option>
                          {getOnGetCountriesLeagues &&
                            getOnGetCountriesLeagues.map((data, i) => (
                              <option value={data.id} key={i}>
                                {data.league_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="upcoming_matches_list d-flex justify-content-between">
                    <div className="col-md-auto">
                      <h1>
                        Upcoming <span className="color_yellow">Matches</span>
                      </h1>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="selectFormat">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option>Format</option>
                          <option>One</option>
                          <option>Two</option>
                          <option>Three</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="upcoming_matches_table">
                    {getOnGetMatchList && getOnGetMatchList.length === 0 ? (
                      <div className="">
                        <p
                          style={{
                            color: "#ff6a0d",
                            marginBottom: "18px",
                            textAlign: "center",
                            fontSize: 18,
                            fontWeight: 600,
                          }}
                        >
                          Data Not Found
                        </p>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" />
                              <th scope="col">Clashes</th>
                              <th scope="col">Garter Type</th>
                              <th scope="col">
                                {lang === "es" ? "Local" : "Home"}
                              </th>
                              <th scope="col">
                                {" "}
                                {lang === "es" ? "Empate" : "Tie"}
                              </th>
                              <th scope="col">
                                {" "}
                                {lang === "es" ? "Visitante" : "Away"}
                              </th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {showData &&
                              showData.map((data, i) => (
                                <tr key={i}>
                                  <td scope="row">
                                    <img
                                      src={data.sport_image}
                                      className="set_height_match"
                                    />
                                  </td>
                                  <td>
                                    <h5>{data.sport_name}</h5>
                                    <h5>{data.name}</h5>
                                    <p>
                                      {moment(todayDate).format(
                                        "YYYY-MM-DD hh:mm:ss"
                                      ) < data.start_date ? (
                                        // <span className="bold_text">upcomming</span>
                                        ""
                                      ) : (
                                        <>
                                          <img src="./images/live_icon.png" />
                                          <span className="bold_text">
                                            LIVE
                                          </span>
                                        </>
                                      )}
                                      &nbsp;{" "}
                                      {moment(data.start_date)
                                        .endOf("day")
                                        .fromNow()}
                                    </p>
                                  </td>
                                  <td>{data.league_name}</td>
                                  <td>{data?.moneyline?.home}</td>
                                  <td>-</td>
                                  <td>{data?.moneyline?.away}</td>

                                  <td>
                                    {userDeatils ? (
                                      <>
                                        {moment(todayDate).format(
                                          "YYYY-MM-DD hh:mm:ss"
                                        ) < data.start_date ? (
                                          <div
                                            className="btn post_btn btnFill betbtn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#betHere"
                                            onClick={() =>
                                              openModelBYId(data.id)
                                            }
                                          >
                                            <div className="btnfilltext">
                                              Bet Here Now
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="btn post_btn btnFill betbtn">
                                            <div className="btnfilltext">
                                              Bet Closed
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <div
                                        className="btn post_btn btnFill betbtn"
                                        onClick={() => {
                                          {
                                            toast.error("Please login first");
                                            navigate("/login");
                                          }
                                        }}
                                      >
                                        <div className="btnfilltext">
                                          Bet Here Now
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Banner />
          </div>
        </div>
      </section>
      <Footer />

      <BetModel
        userBetsGame={userBetsGame}
        matchData={matchData}
        setTeamId={setTeamId}
        teamId={teamId}
        setAmount={setAmount}
        amount={amount}
      />
      <RestrictedModel />
    </>
  );
};

export default GameBets;
