import React, { Suspense, lazy } from "react";
import Footer from "../../componets/Footer/Footer";
import Navbar from "../../componets/Navbar/Navbar";
import Loader from "../../componets/loader/Loader";
const FAQSection = React.lazy(() => import("./FAQSection"));

const FAQ = () => {
  const classSend = "position-relative";

  return (
    <>
      <Navbar classSend={classSend} />
      <Suspense
        fallback={
          <div className="mt-4 mb-4 text-center">
            <Loader />
          </div>
        }
      >
        <FAQSection />
      </Suspense>
      <Footer />
    </>
  );
};

export default FAQ;
