import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import {
  onUserAwardsApi,
  onUserMyBets,
  onUserMyTransaction,
} from "../../../redux/homeAction";
import Banner from "../../Banner/Banner";
import Footer from "../../Footer/Footer";
import Loader from "../../loader/Loader";
import Navbar from "../../Navbar/Navbar";
import Profile from "../Profile/Profile";

const MyBets = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [show, setShow] = useState("");
  const [addClass, setAddClass] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get("path");
    if (paramValue === "bet") {
      setShow(0);
    } else {
      setShow(1);
    }
  }, [location]);

  const getUserMyTransaction = useSelector(
    (state) => state.home.getUserMyTransaction
  );
  const getUserAwardsApi = useSelector((state) => state.home.getUserAwardsApi);

  const getUserBalance = useSelector((state) => state.home.getUserBalance);
  const getUserMyBets = useSelector((state) => state.home.getUserMyBets);

  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    setLoader(true);
    const data = {
      user_id: userDeatils && userDeatils.user_id,
      // user_id: "1",
    };
    dispatch(onUserMyTransaction(data, setLoader));
  }, []);

  useEffect(() => {
    setLoader(true);
    const data = {
      user_id: userDeatils && userDeatils.user_id,
      countries_id: "0",
      countries_leagues_id: "0",
      sport_types: 0,
      // user_id: "1",
    };
    dispatch(onUserMyBets(data, setLoader));
  }, []);

  useEffect(() => {
    setLoader(true);
    const data = {
      user_id: userDeatils && userDeatils.user_id,
      // user_id: "1",
    };
    dispatch(onUserAwardsApi(data, setLoader));
  }, []);

  const classSend = "position-relative";

  return (
    <>
      <Navbar classSend={classSend} />
      <Profile />
      <section id="Tabs_section">
        <div className="container">
          <div className="tabs_container">
            <div className="tabs_nav">
              <ul id="tabs">
                {/* <li>
                  <div>
                    <NavLink to="/dashboard-profile">Wall</NavLink>
                  </div>
                </li> */}
                <li>
                  <div>
                    <NavLink to="/dashboard-notification">notification</NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-my-wallet">my wallet</NavLink>
                  </div>
                </li>
                <li className={`${addClass ? "active_tab" : ""} `}>
                  <div>
                    <NavLink
                      to="/dashboard-my-bets"
                      className={({ isActive }) => {
                        setAddClass(isActive);
                      }}
                    >
                      my bets
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-friend-referrelas">
                      friends &amp; referrelas
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-setting">settings</NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="tebs_content">
            <div className="tabs_section" id="bets">
              <div className="theWall">
                <h1>
                  My <span className="color_yellow">Bets</span>
                </h1>
                <div className="bats_nav">
                  <ul id="bets">
                    <li
                      className={`${show === 0 ? "active_bat" : ""}`}
                      onClick={() => setShow(0)}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <a>My Bets</a>
                      </div>
                    </li>
                    <li
                      className={`${show === 1 ? "active_bat" : ""}`}
                      onClick={() => setShow(1)}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <a>MY TRANSACTIONS</a>
                      </div>
                    </li>
                    <li
                      className={`${show === 2 ? "active_bat" : ""}`}
                      onClick={() => setShow(2)}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <a>AWARDS</a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="bets_section">
                  {show === 0 && (
                    <div className="bets_content">
                      <div className="balence_Row d-flex justify-content-between">
                        <div>
                          <div className="balence_col">
                            <img
                              src="/images/balence_icon.png"
                              className="img-fluid balence_icon"
                            />
                            <div className="balence_box">
                              <h2>Current Balance</h2>
                              <p>{getUserBalance && getUserBalance.balance}</p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="balence_col">
                            <img
                              src="/images/game_iocn.png"
                              className="img-fluid balence_icon"
                            />
                            <div className="balence_box">
                              <h2>In Game</h2>

                              <p>
                                {getUserMyTransaction &&
                                  getUserMyTransaction.gamein}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="matches_history">
                        {loader ? (
                          <div className="text-center">
                            <Loader />
                          </div>
                        ) : (
                          <div className="upcoming_matches_table">
                            {getUserMyBets && getUserMyBets.length ? (
                              <div className="table-responsive">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col" />
                                      <th scope="col">Clashes</th>
                                      <th scope="col">Garter Type</th>
                                      <th scope="col">One</th>
                                      <th scope="col">Tie</th>
                                      <th scope="col">Two</th>
                                      <th scope="col">Amount</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {getUserMyBets &&
                                      getUserMyBets.map((data, i) => (
                                        <tr key={i}>
                                          <td scope="row">
                                            <img
                                              src={data.sport_image}
                                              className="set_height_match"
                                            />
                                          </td>
                                          <td>
                                            <h5>{data.name}</h5>
                                            <p>
                                              {moment(data.start_date)
                                                .endOf("day")
                                                .fromNow()}
                                            </p>
                                          </td>
                                          <td>{data.league_name}</td>
                                          <td>{data.team1point}</td>
                                          <td>-</td>
                                          <td>{data.team2point}</td>
                                          <td>{data.amount}</td>
                                          <td>Bet Placed</td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <div className="text-center mt-3 mb-3">
                                <h2 className="text-center">No Data Found</h2>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {show === 1 && (
                    <div className="bets_content">
                      <div className="balence_Row d-flex justify-content-between">
                        <div>
                          <div className="balence_col">
                            <img
                              src="/images/balence_icon.png"
                              className="img-fluid balence_icon"
                            />
                            <div className="balence_box">
                              <h2>Current Balance</h2>
                              <p>{getUserBalance && getUserBalance.balance}</p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="balence_col">
                            <img
                              src="/images/game_iocn.png"
                              className="img-fluid balence_icon"
                            />
                            <div className="balence_box">
                              <h2>In Game</h2>
                              <p>
                                {getUserMyTransaction &&
                                  getUserMyTransaction.gamein}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="transition_table">
                        {loader ? (
                          <div className="text-center">
                            <Loader />
                          </div>
                        ) : (
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Date</th>
                                  <th scope="col">Detail</th>
                                  <th scope="col">Debit</th>
                                  <th scope="col">Credit</th>
                                  <th scope="col">Balence</th>
                                </tr>
                              </thead>
                              {getUserMyTransaction &&
                              getUserMyTransaction.result &&
                              getUserMyTransaction.result.length ? (
                                <tbody>
                                  {getUserMyTransaction &&
                                    getUserMyTransaction.result &&
                                    getUserMyTransaction.result.map(
                                      (data, i) => (
                                        <tr key={i}>
                                          <td scope="row">
                                            {moment(data.created_at).format(
                                              "MMM DD, LT"
                                            )}
                                          </td>
                                          <td>{data.name}</td>
                                          <td>-</td>
                                          <td className="value_up">
                                            {data.amount_type === 1 ? (
                                              <i className="fa-solid fa-arrow-up" />
                                            ) : (
                                              <i className="fa-solid fa-arrow-down" />
                                            )}{" "}
                                            {""}
                                            {data.amount}
                                          </td>
                                          <td>{data.total_available_amt}</td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              ) : (
                                <h2 className="text-center mt-3 mb-3">
                                  No Data Found
                                </h2>
                              )}
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {show === 2 && (
                    <div className="bets_content">
                      {getUserAwardsApi && getUserAwardsApi.length ? (
                        <div className="awards_container">
                          {getUserAwardsApi &&
                            getUserAwardsApi.map((data, i) => (
                              <div className="Product_box" key={i}>
                                <img src={data.image} className="product_img" />
                                <h5>{data.tittle}</h5>
                                <p>{data.price} Points</p>
                                {/* <div className="product_option">
                                <button className="detail_btn btn">
                                  Detail
                                </button>
                                <button className="Apply_btn btn">
                                  Apply For
                                </button>
                              </div> */}
                              </div>
                            ))}
                        </div>
                      ) : (
                        <h2 className="text-center mt-3 mb-3">No Data Found</h2>
                      )}
                    </div>
                  )}
                </div>
                <Banner />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default MyBets;
