import React, { useRef } from "react";
import "./Home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

const Home = () => {
  const navigate = useNavigate();
  const userLogin = localStorage.getItem("userToken");

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 5,

    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const classSend = "";

  const startPlayingLink = () => {
    navigate("/sports-betting");
  };

  const loginPage = () => {
    navigate("/login");
  };

  const lang = localStorage.getItem("lang");

  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
  return (
    <>
      <Navbar classSend={classSend} />
      <section className="tf banner d-none">
        <div className="bnr-heading">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="banner-text-box">
                  <h6>
                    Play for <span className="orange-txt">FREE</span> and win{" "}
                    <span className="orange-txt">Money and Gifts !</span>
                  </h6>
                  <button className="new-btn" type="submit">
                    <div className="text-box">START PLAYING</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf Top_home">
        <div className="home_top">
          <div className="container">
            <div className="row home-content">
              <div className="col-md-12">
                <div className="banner-text-box">
                  <h6>
                    Play for <span className="orange-txt">FREE</span> and win{" "}
                    <span className="orange-txt">Money and Gifts !</span>
                  </h6>
                  <button
                    className="new-btn"
                    type="submit"
                    onClick={startPlayingLink}
                  >
                    <div className="text-box">START PLAYING</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="home_img">
              <div className="video_section position-relative">
                <img src="./images/home.png" />
                <div className="video">
                  <div className="main-video">
                    {lang === "es" ? (
                      //   <iframe

                      //   src="https://www.youtube.com/embed/og7l8zI-L2c"
                      //   title="¿Qué es Triunfador.net?"
                      //   frameBorder={0}
                      //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      //   allowFullScreen
                      // />
                      <iframe
                        className="video_video"
                        width={568}
                        height={320}
                        src="https://www.youtube.com/embed/hUCoo4mNx0U?si=yE6RX5uhDZBQxX5V"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      <iframe
                        className="video_video"
                        width={568}
                        height={320}
                        src="https://www.youtube.com/embed/KREvg78Hw90?si=DrN9OsdGXnahSghe"
                        title="What is Triunfador.net?"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    )}

                    {/* <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/KREvg78Hw90"
                      title="YouTube video player"
                      frameBorder={0}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* banner-section-end */}
      {/* section-start */}
      <section className="tf Multiple-Sports-card">
        <div className="container">
          <div className="row g-0">
            <div className="col-md-4">
              <div className="card">
                <div className="inner-card">
                  <div className="img-box">
                    <img src="./images/Bets.png" alt="" />
                  </div>
                  <div className="text-box">
                    <h4>BET for free on your favorite sports</h4>
                    <p>Bet without investing a penny and win real money.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="inner-card">
                  <div className="img-box">
                    <img src="./images/Casino.png" alt="" />
                  </div>
                  <div className="text-box">
                    <h4>Play CASINO for free</h4>
                    <p>Play various casino games without any risk.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="inner-card">
                  <div className="img-box">
                    <img src="./images/Contests.png" alt="" />
                  </div>
                  <div className="text-box">
                    <h4>Compete in skill CONTESTS</h4>
                    <p>Beat your rivals in the competitions and win prizes.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section-end */}
      {/* section-start */}
      <section className="tf Multiple-Sports">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="orange-txt">Earn With Sports</h3>
              <h6>
                Bet for Free on your Favorite{" "}
                <span className="orange-txt">Sports !</span>
              </h6>
              <p>
                Bet without any risk and the chance to win real money. You can
                bet on your favorite football, tennis, basketball, baseball
                teams, and many more sports.
                <br />
                <br />
                If you hit your bets, you receive the winnings, and if you lose,
                we reload your points so you can keep betting.
              </p>
            </div>
            <div className="col-md-6">
              <img src="./images/s2.png" />
            </div>
          </div>
        </div>
      </section>
      {/* section-end */}
      {/* section-start */}
      <section className="tf bg-img">
        {/* section-start */}
        <section className="tf Earn-Real-Money">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="money-img-box">
                  <img src="./images/s3.png" />
                </div>
              </div>
              <div className="col-md-6">
                <h3 className="orange-txt">Earn with Skill</h3>
                <h6>
                  Compete for <span className="orange-txt">FREE </span> in our
                  contests and win
                  <span className="orange-txt"> REAL MONEY!</span>
                </h6>
                <p>
                  Make predictions on results, scores, challenges, fantasy
                  matches and trivia in our range of competitions where you can
                  showcase your sports knowledge, beat your rivals and win the
                  prizes.
                  <br />
                  <br />
                  Or if you are into games, you can also show your skill in our
                  contests and be the best in the ranking.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section-end */}
        {/* section-start */}
        <section className="tf Earn-with-Casino">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="orange-txt">Earn with Casino</h3>
                <h6>
                  Play <span className="orange-txt">CASINO</span> for FREE !
                </h6>
                <p className="padding-b-50px">
                  Play blackjack, roulette, poker and other casino games totally
                  free with the chance of winning real money.
                  <br />
                  <br />
                  If you win, your points multiply, and if you lose, don't
                  worry, we'll reload your points so you can keep playing.
                </p>
                <button
                  className="btn-black"
                  type="submit"
                  onClick={startPlayingLink}
                >
                  <div className="text-box">START PLAYING</div>
                </button>
              </div>
              <div className="col-md-6">
                <div className="casino-img-box">
                  <img src="./images/s4.png" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section-end */}
        {/* section-start */}
        <section className="tf redeem-your-points">
          <div className="container">
            <div className="row position-relative">
              <div className="col-md-12">
                <h3 className="orange-txt">Earn Real Money</h3>
                <h6>
                  Redeem your <span className="orange-txt">POINTS </span> for
                  <span className="orange-txt"> MONEY </span> and{" "}
                  <span className="orange-txt">PRIZES! </span>
                </h6>
                <p className="padding-b-50px">
                  Once you have enough points you can request a withdrawal of
                  real money to cryptocurrency wallets or Paypal. In addition,
                  you can claim prizes such as PS5, Xbox One, tablets, video
                  games, smartwatches, and many other fantastic prizes.
                </p>
              </div>
              <div className="row g-0">
                <div className="col-md-3">
                  <div className="card">
                    <div className="inner-card">
                      <div className="points-img-box">
                        <img className="icon" src="./images/gopro.jpg" />
                      </div>
                      <div className="text-box">
                        <h2> *GoPro HERO9</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="inner-card">
                      <div className="points-img-box">
                        <img className="icon" src="./images/ps5.jpg" />
                      </div>
                      <div className="text-box">
                        <h2>*PS5</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="card">
                    <div className="inner-card">
                      <div className="points-img-box">
                        <img className="icon" src="./images/AmazonFire7.webp" />
                      </div>
                      <div className="text-box">
                        <h2>*Amazon Fire 7 </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="card">
                    <div className="inner-card">
                      <div className="points-img-box">
                        <img className="icon" src="./images/EchoShow8.jpg" />
                      </div>
                      <div className="text-box">
                        <h2> *Echo Show 8</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section-end */}
      </section>
      {/* section-end */}
      {/* section-start */}
      <section className="tf ads-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <img src="./images/ads-banner.png" />
            </div>
          </div>
        </div>
      </section>
      {/* section-end */}
      {/* section-start */}
      <section id="Testimonial_section" className="section">
        <div className="container text-center">
          <div className="section_heading">
            <h2>Testimonials</h2>
            <div className="heading_line" />
          </div>
          <h1 className="section_head">
            What our<span className="color_yellow"> Players say?</span>
          </h1>
          <div className="row gy-3 testimonial_row">
            <div className="col-md-4 testimonial-column1">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                navigation={{
                  prevEl: ".swiper-button-prev",
                  nextEl: ".swiper-button-next",
                  clickable: true,
                }}
                loop={true}
              >
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide">
                    <div className="first_slide_content text-start">
                      <img
                        src="./images/column_icon.png"
                        className="column_icon"
                      />
                      <div className="testimonial_user">
                        <h3>Fabi173 </h3>
                        <p>Spain</p>
                      </div>
                      <p className="testimonial_p">
                        Triunfador is, in my opinion, the best free betting
                        website: incredible odds, live markets, competitions,
                        casino, cash withdrawal. Excellent website!{" "}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide">
                    <div className="first_slide_content text-start">
                      <img
                        src="./images/column_icon.png"
                        className="column_icon"
                      />
                      <div className="testimonial_user">
                        <h3>Pulchox </h3>
                        <p>France</p>
                      </div>
                      <p className="testimonial_p">
                        Wonderful website to make sports bets without investing
                        any money and with the possibility of winning real
                        money. The functionality of withdrawal to Cryptos and
                        Paypal is fabulous.{" "}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide">
                    <div className="first_slide_content text-start">
                      <img
                        src="./images/column_icon.png"
                        className="column_icon"
                      />
                      <div className="testimonial_user">
                        <h3>Cammus85 </h3>
                        <p>Argentina</p>
                      </div>
                      <p className="testimonial_p">
                        The sports competitions are very well structured for
                        those of us who are competitive. It's fantastic to be
                        able to showcase your sports knowledge to defeat
                        opponents and win prizes for free!{" "}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="first_slide_content text-start">
                      <img
                        src="./images/column_icon.png"
                        className="column_icon"
                      />
                      <div className="testimonial_user">
                        <h3>Frike37 </h3>
                        <p>USA</p>
                      </div>
                      <p className="testimonial_p">
                        Thank you very much to Triunfador for the prize of 100
                        usd that I received in Paypal. I am really very grateful
                        for the effort to maintain this site, offering free
                        entertainment and with the opportunity to win money.{" "}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="first_slide_content text-start">
                      <img
                        src="./images/column_icon.png"
                        className="column_icon"
                      />
                      <div className="testimonial_user">
                        <h3>Mukesh Jat</h3>
                        <p>India</p>
                      </div>
                      <p className="testimonial_p">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        At adipiscing purus nascetur in lacus. Magna urna, id
                        felis tempor, elementum eget. Risus, vitae egestas ipsum
                        leo.{" "}
                      </p>
                    </div>
                  </div>
                </SwiperSlide> */}
              </Swiper>
            </div>
            <div className="col-md-8 position-relative">
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                navigation={{
                  prevEl: ".swiper-button-prev",
                  nextEl: ".swiper-button-next",
                  clickable: true,
                }}
                loop={true}
                breakpoints={{
                  1024: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  576: {
                    slidesPerView: 1,
                  },
                  0: {
                    slidesPerView: 1,
                  },
                }}
              >
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide">
                    <div className="second_slide_content">
                      <div className="testimonial_img">
                        <img src="./images/Fabi173.jpg" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide">
                    <div className="second_slide_content">
                      <div className="testimonial_img">
                        <img src="./images/Pulchox.jpg" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide">
                    <div className="second_slide_content">
                      <div className="testimonial_img">
                        <img src="./images/Cammus85.jpg" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide">
                    <div className="second_slide_content">
                      <div className="testimonial_img">
                        <img src="./images/Frike37.jpg" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="testimonial_arrows">
                <div className="left_arrow swiper-button-prev ml-2">
                  <button className="btn post_btn btnFill smallbtn gry_btn">
                    <div className="btnfilltext">
                      <i className="fa-solid fa-arrow-left" />
                    </div>
                  </button>
                </div>
                <div className="left_arrow swiper-button-next">
                  <button className="btn post_btn btnFill smallbtn">
                    <div className="btnfilltext">
                      <i className="fa-solid fa-arrow-right" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section-end */}
      <section id="Winner_section" className="section">
        <div className="container text-center">
          {/* <div className="section_heading">
            <h2>Testimonials</h2>
            <div className="heading_line" />
          </div> */}
          <h1 className="section_head">
            Last <span className="color_yellow">winners</span>
          </h1>
          {/* <p className="testimonial_p">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
            adipiscing purus nascetur in lacus. Magna urna, id felis tempor,
            elementum eget. Risus, vitae egestas ipsum leo.{" "}
          </p> */}
          <Slider {...settings}>
            <div className="swiper-slide">
              <div className="winners_box">
                <div className="winnerss_box">
                  <div className="winner_user">
                    <img src="./images/Paorz80.jpg" />
                  </div>
                </div>
                <div className="winner_content text-center">
                  <h5>Paorz80 </h5>
                  <p className="testimonial_p">Withdrew 200 USD to BTC.</p>
                  <div className="user_location text_normal">
                    <img src="./images/carbon_location.png" /> &nbsp;Spain
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="winners_box">
                <div className="winnerss_box">
                  <div className="winner_user">
                    <img src="./images/Lacrezi.jpg" />
                  </div>
                </div>
                <div className="winner_content text-center">
                  <h5>Lacrezi </h5>
                  <p className="testimonial_p">Withdrew 155 USD to TRX.</p>
                  <div className="user_location text_normal">
                    <img src="./images/carbon_location.png" /> &nbsp;USA
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="winners_box">
                <div className="winnerss_box">
                  <div className="winner_user">
                    <img src="./images/Bulzoli7.jpg" />
                  </div>
                </div>
                <div className="winner_content text-center">
                  <h5>Bulzoli7 </h5>
                  <p className="testimonial_p">Redeemed Amazon Fire 7.</p>
                  <div className="user_location text_normal">
                    <img src="./images/carbon_location.png" /> &nbsp;Brazil
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="winners_box">
                <div className="winnerss_box">
                  <div className="winner_user">
                    <img src="./images/Carmi968.jpg" />
                  </div>
                </div>
                <div className="winner_content text-center">
                  <h5>Carmi968 </h5>
                  <p className="testimonial_p">Withdrew 122 USD to Paypal.</p>
                  <div className="user_location text_normal">
                    <img src="./images/carbon_location.png" /> &nbsp;Mexico
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="winners_box">
                <div className="winnerss_box">
                  <div className="winner_user">
                    <img src="./images/Dopler11.jpg" />
                  </div>
                </div>
                <div className="winner_content text-center">
                  <h5>Dopler11 </h5>
                  <p className="testimonial_p">Withdrew 95 USD to ETH.</p>
                  <div className="user_location text_normal">
                    <img src="./images/carbon_location.png" /> &nbsp;United
                    Kingdom
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="winners_box">
                <div className="winnerss_box">
                  <div className="winner_user">
                    <img src="./images/Skotch6.jpg" />
                  </div>
                </div>
                <div className="winner_content text-center">
                  <h5>Skotch6 </h5>
                  <p className="testimonial_p">
                    Redeemed DualShock 4 Wireless.
                  </p>
                  <div className="user_location text_normal">
                    <img src="./images/carbon_location.png" /> &nbsp;USA
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>

      {userLogin ? (
        ""
      ) : (
        <section id="gameBegin_section" className="section">
          <div className="container ">
            <div className="row gamebegin_row g-3 p-lg-3">
              <div className="col-md-5 d-flex align-items-center">
                <div className="gameBegin">
                  <h1 className="section_head">
                    {/* Let the <span className="color_yellow">Game Begin .</span> */}
                    Start <span className="color_yellow">WINNING</span> Now!
                  </h1>
                  <p className="testimonial_p">
                    At Triunfador you can transform your time, knowledge and
                    talent into real money. Your time, effort and skill are
                    worth a lot. Why continue wasting them on websites that
                    don't reward you? At Triunfador, you will have a lucrative,
                    enjoyable and entertaining experience.{" "}
                  </p>
                  <button className="btn-black" onClick={loginPage}>
                    <div className="text-box">
                      <img className="btn-icon" src="./images/user.svg" />
                      Login
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-md-7">
                <div className="gameBegin_right">
                  <img src="./images/CTC_right.png" />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default Home;
