import Home from "./componets/Home/Home";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import Login from "./componets/Login/Login";
import ForgetPassword from "./componets/ForgetPassword/ForgetPassword";
import Register from "./componets/Register/Register";
import "./App.css";
import Academy from "./componets/Academy/Academy";
import GameBets from "./componets/GameBets/GameBets";
import Awards from "./componets/Awards/Awards";
import AwardsItem from "./componets/AwardsItem/AwardsItem";
import AcademyBlog from "./componets/Academy/AcademyBlog";
import { useEffect, useState } from "react";
import EditProfile from "./componets/DashboardPage/EditProfile/EditProfile";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notification from "./componets/DashboardPage/Notification/Notification";
import Wallet from "./componets/DashboardPage/Wallet/Wallet";
import MyBets from "./componets/DashboardPage/MyBets/MyBets";
import FriendReferrelas from "./componets/DashboardPage/FriendReferrelas/FriendReferrelas";
import Setting from "./componets/DashboardPage/Setting/Setting";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "./firebase";
import PrivateRoutes from "./PrivateRoutes/PrivateRoutes";
import EarnPoints from "./componets/EarnPoints/EarnPoints";
import ContactUs from "./Pages/ContactUs/ContactUs";
import AboutUs from "./Pages/AboutUs/AboutUs";
import FAQ from "./Pages/FAQ/FAQ";
import TermAndCondition from "./Pages/Term&Condition/TermAndCondition";
import PrivacyAndPolicy from "./Pages/Privacy&Policy/PrivacyAndPolicy";
import { onFaceBookLogin } from "./redux/homeAction";
import { useDispatch } from "react-redux";
import AboutSection from "./Pages/AboutUs/AboutSection";
import TermAndConditionSection from "./Pages/Term&Condition/TermAndConditionSection";
import PrivacyAndPolicySection from "./Pages/Privacy&Policy/PrivacyAndPolicySection";
import FAQSection from "./Pages/FAQ/FAQSection";
import io from "socket.io-client";
import CookiesPolicy from "./Pages/CookiesPolicy/CookiesPolicy";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const ENDPT = "http://triunfador.rglabs.net:3456/";
  const socket = io(ENDPT, {
    transports: ["websocket", "polling"],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const facebookProvider = new FacebookAuthProvider();
  const googleProvider = new GoogleAuthProvider();

  const facebookLogin = () => {
    signInWithPopup(auth, facebookProvider)
      .then((data) => {
        const sendData = {
          email: data.user.email,
          imageUrl: data.user.photoURL,
          fcmToken: data.user.accessToken,
          deviceId: data.user.uid,
          social_id: data.user.uid,
          socialLoginType: "facebook",
        };
        dispatch(onFaceBookLogin(sendData, navigate, setLoader));
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const googleLogin = () => {
    signInWithPopup(auth, googleProvider)
      .then((data) => {
        const sendData = {
          email: data.user.email,
          imageUrl: data.user.photoURL,
          fcmToken: data.user.accessToken,
          deviceId: data.user.uid,
          social_id: data.user.uid,
          socialLoginType: "google",
        };
        dispatch(onFaceBookLogin(sendData, navigate, setLoader));
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <div className="">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={
            <Login
              facebookLogin={facebookLogin}
              setLoader={setLoader}
              loader={loader}
              googleLogin={googleLogin}
            />
          }
        />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route
          path="/register"
          element={
            <Register
              facebookLogin={facebookLogin}
              setLoader={setLoader}
              loader={loader}
              googleLogin={googleLogin}
            />
          }
        />
        <Route path="/academy" element={<Academy />} />
        <Route path="/sports-betting" element={<GameBets socket={socket} />} />
        <Route path="/prizes" element={<Awards />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/about-section" element={<AboutSection />} />
        <Route
          path="/term-condition-section"
          element={<TermAndConditionSection />}
        />
        <Route
          path="/privacy-policy-section"
          element={<PrivacyAndPolicySection />}
        />
        <Route path="/faq-section" element={<FAQSection />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/award-item/:id" element={<AwardsItem />} />
        <Route path="/academy-blog/:id" element={<AcademyBlog />} />
        <Route path="/earn-points" element={<EarnPoints />} />
        <Route path="/term-condition" element={<TermAndCondition />} />
        <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
        {/* <Route
          path="/dashboard-profile"
          element={
            <PrivateRoutes>
              <TheWall />
            </PrivateRoutes>
          }
        /> */}
        <Route
          path="/dashboard-notification"
          element={
            <PrivateRoutes>
              <Notification />
            </PrivateRoutes>
          }
        />
        <Route
          path="/dashboard-my-wallet"
          element={
            <PrivateRoutes>
              <Wallet />
            </PrivateRoutes>
          }
        />
        <Route
          path="/dashboard-my-bets"
          element={
            <PrivateRoutes>
              <MyBets />
            </PrivateRoutes>
          }
        />
        <Route
          path="/dashboard-friend-referrelas"
          element={
            <PrivateRoutes>
              <FriendReferrelas />
            </PrivateRoutes>
          }
        />
        <Route
          path="/dashboard-setting"
          element={
            <PrivateRoutes>
              <Setting />
            </PrivateRoutes>
          }
        />
        <Route
          path="/dashboard-edit-profile"
          element={
            <PrivateRoutes>
              <EditProfile />
            </PrivateRoutes>
          }
        />
      </Routes>
    </div>
  );
}

export default App;

// cros error apis
///getmatchlist
// get-countries-leagues
