import React from "react";

const PrivacyAndPolicySection = () => {
  return (
    <>
      <section className="about">
        <div className="container">
          <div className="about-content">
            <h1 className="section_head text-center">
              Privacy and<span className="color_yellow"> Cookies Policy</span>
            </h1>
          </div>
          <div className="content-container">
            <ul className="termCondition">
              <li>
                PRIVACY
                <ul>
                  <li>
                    You are visiting the website www.triunfador.net, owned by
                    the trade name FELIX TECHNOLOGY registered in the commercial
                    registry of the city of Armenia under registration number:
                    00187024 of July 9, 2013, located at Cll 23 Cra 15 Armenia,
                    Q; and with a contact email: soporte@triunfador.net.
                  </li>
                  <li>
                    We are committed to protecting your personal data. We have
                    created this Privacy Policy to communicate the information
                    we store when you use the services of TRIUNFADOR.NET
                    (collectively, the "Services"), why we store it, and how we
                    use it. As defined in the General Provisions, this Privacy
                    Policy is agreed upon between the user and triunfador.net,
                    TRIUNFADOR, or us as appropriate."
                  </li>
                </ul>
              </li>
              <li>
                INFORMATION RECEIVED AND DATA PROCESSING
                <ul>
                  <li>
                    We consider information that can be used to identify a user,
                    including but not limited to, name and surname, date of
                    birth, email address, home address or any other address,
                    telephone number and any other information as 'Personal
                    Information'. We may ask you to provide personal data when
                    using our website, opening an account or using our services.
                    The Personal Information that we store may include: contact
                    information (phone number), shipping information, and
                    preferences in using the website and comments related to
                    services on our social channels. We store this information
                    on our servers. Similarly, when you interact with the
                    Services, our servers record each user's unique activity
                    history and certain administrative and traffic information
                    that includes: source IP address, visited web pages,
                    language, time, type of search engine, date, and software
                    blocking reports. This information is essential to the
                    quality of our Services.
                  </li>
                  <li>
                    It is possible that we receive personal data from online
                    providers and service providers, as well as customer lists
                    legally obtained from third-party providers. Similarly, it
                    is possible that we contract the services of third-party
                    service providers to provide technical support, process your
                    online transactions, and maintain your online account. We
                    will have access to all the information that you provide to
                    such providers, service providers, and third-party
                    e-commerce services, and we may share this data. We make our
                    best effort to ensure that agreements with third parties and
                    online collaborators respect your privacy."
                  </li>
                </ul>
              </li>
              <li>
                USE OF INFORMATION
                <ul>
                  <li>
                    We use the personal information you provide to offer our
                    Services, provide user support, perform necessary security
                    and identification checks, process prize payments, help you
                    participate in third-party, company, and other promotions
                    related to the management of the Services. As such, we may
                    share your personal information with carefully selected
                    partners and collaborators (including any other party that
                    these partners have entered into information exchange
                    agreements with). We may also use your personal information
                    to send you: (a) promotional offers and information about
                    our products and services, and (b) promotional offers and
                    information about the products and services of our carefully
                    selected partners in order to offer you the best possible
                    range of products, services, and offers.
                  </li>
                  <li>
                    You agree that we may notify other users of our portal who
                    have an account and who have your email address saved in
                    their email contact list that such email address has already
                    been registered in an account. We may also request
                    information from you through surveys or contests.
                    Participation in these surveys or contests is completely
                    voluntary and you may choose to provide or not to provide
                    such information. The information requested may include
                    contact information (such as name, mailing address, and
                    phone number) as well as demographic information (such as
                    postal code or age). By accepting contest prizes, you agree
                    to the use of your name and other information for marketing
                    purposes without additional compensation. Unless you have
                    opted not to receive promotional information, we may also
                    use your personal information (including your email address
                    and phone number) to offer you information about our
                    products, services, and promotions, including the products
                    and services of third parties we have carefully selected,
                    and advertisers on our site."
                  </li>
                </ul>
              </li>
              <li>
                EXCLUSIONS FROM THE DISCLOSURE OF INFORMATION
                <ul>
                  <li>
                    We may disclose your personal information if required by law
                    or if we believe in good faith that such action is necessary
                    to: (1) comply with any legal process served on us, (2)
                    protect and defend our rights or property; or (3) act to
                    protect the personal safety of users of the Services or the
                    public. If we determine, at our sole discretion, that you
                    have cheated or attempted to defraud our portal or our
                    Services in any way, including but not limited to
                    manipulation of games, computer codes, IP alterations and
                    identity fraud
                  </li>
                </ul>
              </li>
              <li>
                ACCESS FILES (LOG FILES)
                <ul>
                  <li>
                    We use log files that include internet protocol (IP)
                    addresses, browser types, ISPs, referral/exit pages,
                    platform types, date/time stamps, and click number to
                    analyze trends, administer the site, track user's movements,
                    and gather broader demographic information".
                  </li>
                  {/* <li>
                    <ul>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus ut faucibus fusce integer vel.
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus ut faucibus fusce integer vel.
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus ut faucibus fusce integer vel.
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus ut faucibus fusce integer vel.
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus ut faucibus fusce integer vel.
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus ut faucibus fusce integer vel.
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </li>
              <li>
                ACCESS
                <ul>
                  <li>
                    You may not receive any information about promotions by
                    selecting this option when it is available on our websites
                    or services or in an email we send you or at any time you
                    wish by sending an email to soporte@triunfador.net. In
                    addition, you can also contact us at this address if: 1) you
                    wish to confirm the personal information we have collected
                    about you; 2) you want to update your personal information;
                    and/or 3) you have any complaint regarding the use we make
                    of your personal information. If you request it, (a) we will
                    update any information you have provided as long as you
                    provide evidence of this, as we may require this for such
                    changes or (b) we will take note that such information
                    should not be used for marketing purposes. To avoid any
                    doubt, nothing in this Privacy Policy can prevent us from
                    retaining your personal information when it is mandatory by
                    law.
                  </li>
                  <li>
                    The personal data obtained as a result of your registration
                    will be incorporated into a file owned by TRIUNFADOR. You
                    may exercise your rights of access, rectification,
                    cancellation, and opposition by making a request to the
                    email address soporte@triunfador.net asking not to receive
                    marketing information and removing your email from our
                    lists. To do this, you must attach a copy of your official
                    identification document in order to verify and guarantee
                    your identity and protect your data by preventing
                    unauthorized third parties from accessing them. By selecting
                    the Privacy Policy and Cookies acceptance boxes, you give us
                    full authorization to use your data for the sending of our
                    own advertising, and of those services or products that our
                    advertisers, partners, investors, or any partner related to
                    our portal offers.
                  </li>
                  <li>
                    In conclusion, each user is fully informed and accepts that:
                  </li>
                  <li>
                    <ul>
                      <li>
                        The data they provide is integrated into a file of
                        personal data, of the purposes of collecting their data
                        and its subsequent processing and the recipients of the
                        same.
                      </li>
                      <li>
                        • The data they provide is integrated into a file of
                        personal data, of the purposes of collecting their data
                        and its subsequent processing and the recipients of the
                        same.
                      </li>
                      <li>
                        • The data they provide is integrated into a file of
                        personal data, of the purposes of collecting their data
                        and its subsequent processing and the recipients of the
                        same.
                      </li>
                      <li>
                        • The data they provide is integrated into a file of
                        personal data, of the purposes of collecting their data
                        and its subsequent processing and the recipients of the
                        same.
                      </li>
                      <li>
                        • The data they provide is integrated into a file of
                        personal data, of the purposes of collecting their data
                        and its subsequent processing and the recipients of the
                        same.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/* <li>
                COOKIES
                <ul className="big_ul">
                  <li>
                    Information recorded on your computer
                    <ul>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus ut faucibus fusce integer vel. Id massa
                        ullamcorper sed dignissim vel. Pellentesque sit enim id
                        ornare consequat, faucibus egestas congue condimentum.
                        Non mi sit sed dui. Quis egestas sodales eget duis sit
                        mauris id quis. Convallis quis faucibus adipiscing
                        malesuada pulvinar dolor viverra. Fermentum, cursus
                        aliquam massa sed urna eu neque, ullamcorper. Tristique
                        enim viverra nulla sed sagittis consequat sem. Leo
                        facilisis orci, nisi, sem elementum maecenas at integer.
                        Vel lorem augue condimentum lorem adipiscing enim.
                      </li>
                      <li>
                        Donec aliquam volutpat orci volutpat urna, turpis. At
                        placerat praesent enim vitae vel. Vel, diam egestas
                        molestie lacus, eu. In sed mi, sed vestibulum
                        scelerisque pulvinar risus. Aliquam vestibulum arcu, est
                        sed vitae velit dictum ornare.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Collection of cookies
                    <ul>
                      <li>
                        Donec aliquam volutpat orci volutpat urna, turpis. At
                        placerat praesent enim vitae vel. Vel, diam egestas
                        molestie lacus, eu. In sed mi, sed vestibulum
                        scelerisque pulvinar risus. Aliquam vestibulum arcu, est
                        sed vitae velit dictum ornare.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Can cookies be rejected?
                    <ul>
                      <li>
                        Donec aliquam volutpat orci volutpat urna, turpis. At
                        placerat praesent enim vitae vel. Vel, diam egestas
                        molestie lacus, eu. In sed mi, sed vestibulum
                        scelerisque pulvinar risus. Aliquam vestibulum arcu, est
                        sed vitae velit dictum ornare.s.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}
              <li>
                IDENTITY VERIFICATION AUTHORIZATION
                <ul>
                  <li>
                    We reserve the right to conduct security checks at any time
                    to verify your identity, age, and all the information you
                    have provided to us. By accepting our terms and conditions
                    and this privacy and cookie policy, you authorize our
                    personnel, affiliates, subsidiaries, agents, and providers
                    to use your personal information and share it with third
                    parties for the sole purpose of validating the information
                    you have provided when using Triunfador's services. You also
                    agree to provide us with information or documentation that
                    verifies your identity and information when we require it.
                  </li>
                </ul>
              </li>
              <li>
                SECURITY
                <ul>
                  <li>
                    We are fully aware of the importance of information security
                    and the techniques necessary to protect information. All
                    personal data we receive from you is stored in a database
                    protected by a password and located in our security network
                    protected by sophisticated active firewall software, and our
                    websites and services are compatible with SSL version 3 with
                    128-bit encryption. We take specific measures to protect our
                    partners, advertisers, agents, affiliates, and providers,
                    and employ appropriate security levels for this purpose.
                  </li>
                </ul>
              </li>
              <li>
                MINORS' PROTECTION
                <ul>
                  <li>
                    The Services are not aimed at or directed to persons under
                    the age of 18. Any person who provides their data confirms
                    that they are over 18 years of age. If we become aware that
                    a minor under 18 years of age has tried to send personal
                    data through the online website, we will not accept this
                    information and will take the necessary measures to remove
                    such information from our system.
                  </li>
                </ul>
              </li>
              <li>
                THIRD PARTY PRACTICES
                <ul>
                  <li>
                    We cannot ensure the protection of any information or images
                    that are provided to third-party websites through a link to
                    TRIUNFADOR or any information stored by third parties who
                    manage our partner program, advertisers, or affiliates (if
                    applicable), or any other program, as these websites are
                    independent of us and belong to third parties. Any
                    information stored by third parties is governed by the
                    privacy policy of such third party if they have one and not
                    by our own. If you have any questions about the information
                    practices of third parties, please read their privacy policy
                    as TRIUNFADOR does not assume any responsibility for the
                    practices, actions, or policies of such third parties and we
                    are not responsible for the content or privacy practices of
                    such online websites.
                  </li>
                </ul>
              </li>
              <li>
                DISCLAIMER OF LIABILITY
                <ul>
                  <li>
                    Due to the changing nature of our technology and business,
                    we cannot guarantee or imply that there will be error-free
                    performance regarding the management of your personal data,
                    and we will not be liable in any case for any punitive,
                    indirect, incidental or consequential damages arising from
                    the use or release of personal data.
                  </li>
                  <li>
                    The Services operate as offered and when available, without
                    prejudice of any kind.
                  </li>
                </ul>
              </li>
              <li>
                TRANSFER
                <ul>
                  <li>
                    If in the future we are acquired by a third party or merge
                    with it, transfer substantially all or part of such assets
                    to a third party, or sell all or part of our assets, we will
                    have the right to share your Personal Information and any
                    other type of information that you have provided through the
                    Services with potential and future partners of the merging
                    company. By accepting these terms and conditions you give
                    your full consent to the transfer of such information.
                  </li>
                </ul>
              </li>
              <li>
                CONSENT TO THE PRIVACY POLICY
                <ul>
                  <li>
                    When you click on "Submit" or "I Agree" during the
                    registration process or when you continue to use the
                    Services after the publication of this Privacy Policy (when
                    applicable), YOU CONFIRM to be in agreement with this
                    Privacy Policy which must be read in conjunction with our
                    Terms and Conditions."
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyAndPolicySection;
