import React from "react";
import Banner from "../Banner/Banner";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";

const EarnPoints = () => {
  const classSend = "position-relative";

  return (
    <>
      <Navbar classSend={classSend} />
      <section className="awards_section">
        <div className="container">
          <h1 className="h1_60">
            EARN MORE <span className="color_yellow">POINTS</span>
          </h1>
          <div className="ad_section ad-top">
            <div className="row ad_content">
              <div className="col-md-12">
                <img src="./images/ad2.png" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="awards_container academy_container">
            <div className="academy_box earnpoints_box">
              <img src="./images/academy_img.png" className="product_img" />
              <div className="points_box">
                <p>1006 Points</p>
              </div>
              <h4>Adscend Media</h4>
              <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit. </p>
              <div
                className="btn post_btn btnFill betbtn"
                data-bs-toggle="modal"
                data-bs-target="#betHere"
              >
                <div className="btnfilltext">Collect Points</div>
              </div>
            </div>
            <div className="academy_box earnpoints_box">
              <img src="./images/academy_img.png" className="product_img" />
              <div className="points_box">
                <p>1006 Points</p>
              </div>
              <h4>Adscend Media</h4>
              <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit. </p>
              <div
                className="btn post_btn btnFill betbtn"
                data-bs-toggle="modal"
                data-bs-target="#betHere"
              >
                <div className="btnfilltext">Collect Points</div>
              </div>
            </div>
            <div className="academy_box earnpoints_box">
              <img src="./images/academy_img.png" className="product_img" />
              <div className="points_box">
                <p>1006 Points</p>
              </div>
              <h4>Adscend Media</h4>
              <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit. </p>
              <div
                className="btn post_btn btnFill betbtn"
                data-bs-toggle="modal"
                data-bs-target="#betHere"
              >
                <div className="btnfilltext">Collect Points</div>
              </div>
            </div>
            <div className="academy_box earnpoints_box">
              <img src="./images/academy_img.png" className="product_img" />
              <div className="points_box">
                <p>1006 Points</p>
              </div>
              <h4>Adscend Media</h4>
              <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit. </p>
              <div
                className="btn post_btn btnFill betbtn"
                data-bs-toggle="modal"
                data-bs-target="#betHere"
              >
                <div className="btnfilltext">Collect Points</div>
              </div>
            </div>
            <div className="academy_box earnpoints_box">
              <img src="./images/academy_img.png" className="product_img" />
              <div className="points_box">
                <p>1006 Points</p>
              </div>
              <h4>Adscend Media</h4>
              <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit. </p>
              <div
                className="btn post_btn btnFill betbtn"
                data-bs-toggle="modal"
                data-bs-target="#betHere"
              >
                <div className="btnfilltext">Collect Points</div>
              </div>
            </div>
            <div className="academy_box earnpoints_box">
              <img src="./images/academy_img.png" className="product_img" />
              <div className="points_box">
                <p>1006 Points</p>
              </div>
              <h4>Adscend Media</h4>
              <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit. </p>
              <div
                className="btn post_btn btnFill betbtn"
                data-bs-toggle="modal"
                data-bs-target="#betHere"
              >
                <div className="btnfilltext">Collect Points</div>
              </div>
            </div>
            <div className="academy_box earnpoints_box">
              <img src="./images/academy_img.png" className="product_img" />
              <div className="points_box">
                <p>1006 Points</p>
              </div>
              <h4>Adscend Media</h4>
              <p>Lorem ipsum dolor sit amet,consectetur adipiscing elit. </p>
              <div
                className="btn post_btn btnFill betbtn"
                data-bs-toggle="modal"
                data-bs-target="#betHere"
              >
                <div className="btnfilltext">Collect Points</div>
              </div>
            </div>
          </div>
          <Banner />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default EarnPoints;
