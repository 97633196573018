import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { onUserAcademy } from "../../redux/homeAction";
import Banner from "../Banner/Banner";
import Footer from "../Footer/Footer";
import Loader from "../loader/Loader";
import Navbar from "../Navbar/Navbar";

const Academy = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const classSend = "position-relative";

  const getUserAcademy = useSelector((state) => state.home.getUserAcademy);
  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    setLoader(true);
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onUserAcademy(data, setLoader));
  }, []);

  return (
    <>
      <Navbar classSend={classSend} />
      <section className="awards_section">
        <div className="container">
          <h1 className="h1_60">
            IMPROVE YOUR <span className="color_yellow">KNOWLEDGE</span>
          </h1>
          <p className="P_gry_normal firstP">
            Triunfador's academy will help you acquire the fundamentals and
            tools to face the world of betting and casino in an intelligent,
            effective and profitable way; and will give you the best advice if
            you are interested in dabbling for real money.
          </p>
          <div className="ad_section ad-top">
            <div className="row ad_content">
              <div className="col-md-12">
                <img src="./images/ad2.png" className="img-fluid" />
              </div>
            </div>
          </div>
          {getUserAcademy ? (
            <>
              {" "}
              {loader ? (
                <div className="text-center">
                  <Loader />
                </div>
              ) : (
                <div className="awards_container academy_container">
                  {getUserAcademy &&
                    getUserAcademy.map((data, i) => (
                      <div className="academy_box" key={i}>
                        {getUserAcademy && getUserAcademy.length ? (
                          <>
                            <img src={data.image} className="product_img" />
                            <h5>{data.tittle}</h5>
                            <Link to={`/academy-blog/${data.id}`}>
                              See More
                            </Link>
                          </>
                        ) : (
                          <h2 className="text-center">No Data Found</h2>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </>
          ) : (
            <div className="">
              <h2 className="text-center">No Data Found</h2>
            </div>
          )}
          <Banner />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Academy;
