import React from "react";
import Footer from "../../componets/Footer/Footer";
import Navbar from "../../componets/Navbar/Navbar";
import PrivacyAndPolicySection from "./PrivacyAndPolicySection";

const PrivacyAndPolicy = () => {
  const classSend = "position-relative";

  return (
    <>
      <Navbar classSend={classSend} />
      <PrivacyAndPolicySection />
      <Footer />
    </>
  );
};

export default PrivacyAndPolicy;
