import React, { useEffect, useState } from "react";
import Banner from "../Banner/Banner";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { onUserAcademyDetails } from "../../redux/homeAction";
import { useParams } from "react-router-dom";
import Loader from "../loader/Loader";

const AcademyBlog = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const getUserAcademyDetails = useSelector(
    (state) => state.home.getUserAcademyDetails
  );
  const [loader, setLoader] = useState(false);
  const classSend = "position-relative";

  useEffect(() => {
    const data = {
      academy_id: id,
    };
    setLoader(true);
    dispatch(onUserAcademyDetails(data, setLoader));
  }, []);

  const wordsArray = getUserAcademyDetails?.tittle?.split(" ");
  const lastWord = wordsArray && wordsArray[wordsArray?.length - 1];
  const allWords = wordsArray && wordsArray.filter((data) => data !== lastWord);

  return (
    <>
      <Navbar classSend={classSend} />

      <div>
        <section id="academy_top">
          {/* <img
            src={getUserAcademyDetails && getUserAcademyDetails?.image}
            className="img-fluid"
          /> */}
          <img src="/images/academy_top.png" className="img-fluid" />
        </section>
        {loader ? (
          <div className="text-center mt-4 mb-4">
            <Loader />
          </div>
        ) : (
          <section id="academy_container">
            <div className="container">
              <div className="width_set">
                <h1 className="h1_60">
                  {allWords && allWords?.map((data) => <span>{data} </span>)}{" "}
                  <span className="color_yellow">{lastWord}</span>
                </h1>
                {/* <h1 className="h1_60">
                The best online casinos to play for real
                <span className="color_yellow"> money in Spain</span>
              </h1> */}
                <p
                  className="P_gry_normal"
                  dangerouslySetInnerHTML={{
                    __html:
                      getUserAcademyDetails &&
                      getUserAcademyDetails?.description,
                  }}
                ></p>

                {/* <p className="P_gry_normal">
                Convallis quis faucibus adipiscing malesuada pulvinar dolor
                viverra. Fermentum, cursus aliquam massa sed urna eu neque,
                ullamcorper. Tristique enim viverra nulla sed sagittis consequat
                sem. Leo facilisis orci, nisi, sem elementum maecenas at
                integer. Vel lorem augue condimentum lorem adipiscing
                enim.Egestas facilisi egestas tincidunt mauris risus.
              </p>
              <h2 className=" ">The difference between segments</h2>
              <p className="P_gry_normal">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus ut faucibus fusce integer vel. Id massa ullamcorper
                sed dignissim vel. Pellentesque sit enim id ornare consequat,
                faucibus egestas congue condimentum. Non mi sit sed dui. Quis
                egestas sodales eget duis sit mauris id quis. Convallis quis
                faucibus adipiscing malesuada pulvinar dolor viverra. Fermentum,
                cursus aliquam massa sed urna eu neque, ullamcorper. Tristique
                enim viverra nulla sed sagittis consequat sem. Leo facilisis
                orci, nisi, sem elementum maecenas at integer. Vel lorem augue
                condimentum lorem adipiscing enim.
              </p>
              <p className="P_gry_normal">
                Donec aliquam volutpat orci volutpat urna, turpis. At placerat
                praesent enim vitae vel. Vel, diam egestas molestie lacus, eu.
                In sed mi, sed vestibulum scelerisque pulvinar risus. Aliquam
                vestibulum arcu, est sed vitae velit dictum ornare.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Phasellus ut
                faucibus fusce integer vel. Id massa ullamcorper sed dignissim
                vel. Pellentesque sit enim id ornare consequat, faucibus egestas
                congue condimentum. Non mi sit sed dui. Quis egestas sodales
                eget duis sit mauris id quis. Convallis quis faucibus adipiscing
                malesuada pulvinar dolor viverra. Fermentum, cursus aliquam
                massa sed urna eu neque, ullamcorper. Tristique enim viverra
                nulla sed sagittis consequat sem. Leo facilisis orci, nisi, sem
                elementum maecenas at integer. Vel lorem augue condimentum lorem
                adipiscing enim.
              </p>
              <p className="P_gry_normal">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus ut faucibus fusce integer vel. Id massa ullamcorper
                sed dignissim vel. Pellentesque sit enim id ornare consequat,
                faucibus egestas congue condimentum. Non mi sit sed dui. Quis
                egestas sodales eget duis sit mauris id quis. Convallis quis
                faucibus adipiscing malesuada pulvinar dolor viverra. Fermentum,
                cursus aliquam massa sed urna eu neque, ullamcorper. Tristique
                enim viverra nulla sed sagittis consequat sem. Leo facilisis
                orci, nisi, sem elementum maecenas at integer. Vel lorem augue
                condimentum lorem adipiscing enim.
              </p>
              <p className="P_gry_normal">
                Donec aliquam volutpat orci volutpat urna, turpis. At placerat
                praesent enim vitae vel. Vel, diam egestas molestie lacus, eu.
                In sed mi, sed vestibulum scelerisque pulvinar risus. Aliquam
                vestibulum arcu, est sed vitae velit dictum ornare.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Phasellus ut
                faucibus fusce integer vel. Id massa ullamcorper sed dignissim
                vel.
              </p> */}
              </div>
              {/* <img
              src="/images/football_ground.png"
              className="football_ground img-fluid"
            /> */}
              {/* <div className="width_set">
              <h2 className=" ">The difference between segments</h2>
              <p className="P_gry_normal">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus ut faucibus fusce integer vel. Id massa ullamcorper
                sed dignissim vel. Pellentesque sit enim id ornare consequat,
                faucibus egestas congue condimentum. Non mi sit sed dui. Quis
                egestas sodales eget duis sit mauris id quis. Convallis quis
                faucibus adipiscing malesuada pulvinar dolor viverra. Fermentum,
                cursus aliquam massa sed urna eu neque, ullamcorper. Tristique
                enim viverra nulla sed sagittis consequat sem. Leo facilisis
                orci, nisi, sem elementum maecenas at integer. Vel lorem augue
                condimentum lorem adipiscing enim.
              </p>
              <p className="P_gry_normal">
                Donec aliquam volutpat orci volutpat urna, turpis. At placerat
                praesent enim vitae vel. Vel, diam egestas molestie lacus, eu.
                In sed mi, sed vestibulum scelerisque pulvinar risus. Aliquam
                vestibulum arcu, est sed vitae velit dictum ornare.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Phasellus ut
                faucibus fusce integer vel. Id massa ullamcorper sed dignissim
                vel. Pellentesque sit enim id ornare consequat, faucibus egestas
                congue condimentum. Non mi sit sed dui. Quis egestas sodales
                eget duis sit mauris id quis. Convallis quis faucibus adipiscing
                malesuada pulvinar dolor viverra. Fermentum, cursus aliquam
                massa sed urna eu neque, ullamcorper. Tristique enim viverra
                nulla sed sagittis consequat sem. Leo facilisis orci, nisi, sem
                elementum maecenas at integer. Vel lorem augue condimentum lorem
                adipiscing enim.
              </p>
            </div>
            <div className="row gy-3">
              <div className="col-md-6">
                <img src="/images/card_game.png" className="img-fluid" />
              </div>
              <div className="col-md-6">
                <img src="/images/card_game2.png" className="img-fluid" />
              </div>
            </div>
            <div className="width_set">
              <p className="P_gry_normal">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus ut faucibus fusce integer vel. Id massa ullamcorper
                sed dignissim vel. Pellentesque sit enim id ornare consequat,
                faucibus egestas congue condimentum. Non mi sit sed dui. Quis
                egestas sodales eget duis sit mauris id quis. Convallis quis
                faucibus adipiscing malesuada pulvinar dolor viverra. Fermentum,
                cursus aliquam massa sed urna eu neque, ullamcorper. Tristique
                enim viverra nulla sed sagittis consequat sem. Leo facilisis
                orci, nisi, sem elementum maecenas at integer. Vel lorem augue
                condimentum lorem adipiscing enim.
              </p>
              <p className="P_gry_normal">
                Donec aliquam volutpat orci volutpat urna, turpis. At placerat
                praesent enim vitae vel. Vel, diam egestas molestie lacus, eu.
                In sed mi, sed vestibulum scelerisque pulvinar risus. Aliquam
                vestibulum arcu, est sed vitae velit dictum ornare.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Phasellus ut
                faucibus fusce integer vel. Id massa ullamcorper sed dignissim
                vel. Pellentesque sit enim id ornare consequat, faucibus egestas
                congue condimentum. Non mi sit sed dui. Quis egestas sodales
                eget duis sit mauris id quis. Convallis quis faucibus adipiscing
                malesuada pulvinar dolor viverra. Fermentum, cursus aliquam
                massa sed urna eu neque, ullamcorper. Tristique enim viverra
                nulla sed sagittis consequat sem. Leo facilisis orci, nisi, sem
                elementum maecenas at integer. Vel lorem augue condimentum lorem
                adipiscing enim.
              </p>
            </div> */}
              <Banner />
            </div>
          </section>
        )}
      </div>
      <Footer />
    </>
  );
};

export default AcademyBlog;
