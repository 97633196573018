import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { onUserFriendList } from "../../../redux/homeAction";
import Banner from "../../Banner/Banner";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import Profile from "../Profile/Profile";

const FriendReferrelas = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(0);
  const [addClass, setAddClass] = useState(false);
  const [iconShow, setIconShow] = useState(false);

  const getOnUserFriendList = useSelector(
    (state) => state.home.getOnUserFriendList
  );

  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    const data = {
      user_id: userDeatils && userDeatils.user_id,
      // user_id: "1",
    };
    dispatch(onUserFriendList(data));
  }, []);

  const classSend = "position-relative";

  const showListMenu = (i) => {
    setIconShow(i);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIconShow();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <>
      <Navbar classSend={classSend} />
      <Profile />
      <section id="Tabs_section">
        <div className="container">
          <div className="tabs_container">
            <div className="tabs_nav">
              <ul id="tabs">
                {/* <li>
                  <div>
                    <NavLink to="/dashboard-profile">Wall</NavLink>
                  </div>
                </li> */}
                <li>
                  <div>
                    <NavLink to="/dashboard-notification">notification</NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-my-wallet">my wallet</NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-my-bets">my bets</NavLink>
                  </div>
                </li>
                <li className={`${addClass ? "active_tab" : ""} `}>
                  <div>
                    <NavLink
                      to="/dashboard-friend-referrelas"
                      className={({ isActive }) => {
                        setAddClass(isActive);
                      }}
                    >
                      friends &amp; referrelas
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-setting">settings</NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="tebs_content">
            <div className="tabs_section" id="friends">
              <div className="theWall">
                <h1>
                  Friends &amp; <span className="color_yellow">Referrelas</span>
                </h1>
                <div className="bats_nav">
                  <ul id="friends_tabs">
                    <li
                      className={`${show === 0 ? "active_bat" : ""}`}
                      onClick={() => setShow(0)}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <a>FRIENDS</a>
                      </div>
                    </li>
                    <li
                      className={`${show === 1 ? "active_bat" : ""}`}
                      onClick={() => setShow(1)}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <a>SUBMITTED REQUESTS</a>
                      </div>
                    </li>
                    <li
                      className={`${show === 2 ? "active_bat" : ""}`}
                      onClick={() => setShow(2)}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <a>REQUESTS RECEIVED</a>
                      </div>
                    </li>
                    <li
                      className={`${show === 3 ? "active_bat" : ""}`}
                      onClick={() => setShow(3)}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <a>REFERRALS</a>
                      </div>
                    </li>
                    <li
                      className={`${show === 4 ? "active_bat" : ""}`}
                      onClick={() => setShow(4)}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <a>INVITE YOUR FRIENDS</a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="bets_section">
                  {show === 0 && (
                    <div className="friends_content">
                      {getOnUserFriendList && getOnUserFriendList.length ? (
                        <div className="early_posts friends_lists">
                          {getOnUserFriendList &&
                            getOnUserFriendList.map((data, i) => (
                              <div
                                className="post_container friend_list"
                                key={i}
                              >
                                <div className="row align-items-start">
                                  <div className="col-md-1">
                                    <div className="poster_img">
                                      <img src={data.image} />
                                    </div>
                                  </div>
                                  <div className="col-md-10">
                                    <div className="poster_detail">
                                      <p className="poster_name">
                                        {data.username}
                                      </p>
                                      <p className="date_time">
                                        Activity : 53 minuts ago
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-1">
                                    <div
                                      className="post_delete"
                                      ref={wrapperRef}
                                    >
                                      <img
                                        className="menudots"
                                        src="./images/menu_dots.png"
                                        onClick={() => showListMenu(i)}
                                      />
                                      {iconShow === i ? (
                                        <div
                                          className={`Open_menu ${
                                            iconShow === i ? "active" : ""
                                          }`}
                                          id="OpenMenu"
                                        >
                                          <a>
                                            <div className="menu_option">
                                              <img
                                                src="./images/view_icon.png"
                                                className="menu_img"
                                              />
                                              <p className="text_normal">
                                                View
                                              </p>
                                            </div>
                                          </a>
                                          <a>
                                            <div className="menu_option">
                                              <img
                                                src="./images/unfiend_icon.png"
                                                className="menu_img"
                                              />
                                              <p className="text_normal">
                                                Unfreind
                                              </p>
                                            </div>
                                          </a>
                                          <a>
                                            <div className="menu_option">
                                              <img
                                                src="./images/Block_icon.png"
                                                className="menu_img"
                                              />
                                              <p className="text_normal">
                                                Block
                                              </p>
                                            </div>
                                          </a>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <h2 className="text-center mt-3 mb-3">No Data Found</h2>
                      )}
                    </div>
                  )}
                  {show === 1 && (
                    <div className="friends_content">
                      <div className="early_posts friends_lists">
                        <div className="post_container friend_list">
                          <div className="row align-items-start">
                            <div className="col-md-1">
                              <div className="poster_img">
                                <img src="./images/profile_dp.png" />
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="poster_detail">
                                <p className="poster_name">Mukesh jat</p>
                                <p className="date_time">
                                  Activity : 53 minuts ago
                                </p>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="add_post_btn text-center Withdraw_row">
                                <button className="btn post_btn btnFill smallbtn green_btn">
                                  <div className="btnfilltext">
                                    <i className="fa-solid fa-check" />
                                  </div>
                                </button>
                                <button className="btn post_btn btnFill smallbtn red_btn">
                                  <div className="btnfilltext">
                                    <i className="fa-regular fa-trash-can" />
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="post_container friend_list">
                          <div className="row align-items-start">
                            <div className="col-md-1">
                              <div className="poster_img">
                                <img src="./images/profile_dp.png" />
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="poster_detail">
                                <p className="poster_name">Mukesh jat</p>
                                <p className="date_time">
                                  Activity : 53 minuts ago
                                </p>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="add_post_btn text-center Withdraw_row">
                                <button className="btn post_btn btnFill smallbtn green_btn">
                                  <div className="btnfilltext">
                                    <i className="fa-solid fa-check" />
                                  </div>
                                </button>
                                <button className="btn post_btn btnFill smallbtn red_btn">
                                  <div className="btnfilltext">
                                    <i className="fa-regular fa-trash-can" />
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="post_container friend_list">
                          <div className="row align-items-start">
                            <div className="col-md-1">
                              <div className="poster_img">
                                <img src="./images/profile_dp.png" />
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="poster_detail">
                                <p className="poster_name">Mukesh jat</p>
                                <p className="date_time">
                                  Activity : 53 minuts ago
                                </p>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="add_post_btn text-center Withdraw_row">
                                <button className="btn post_btn btnFill smallbtn green_btn">
                                  <div className="btnfilltext">Approved</div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="post_container friend_list">
                          <div className="row align-items-start">
                            <div className="col-md-1">
                              <div className="poster_img">
                                <img src="./images/profile_dp.png" />
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="poster_detail">
                                <p className="poster_name">Mukesh jat</p>
                                <p className="date_time">
                                  Activity : 53 minuts ago
                                </p>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="add_post_btn text-center Withdraw_row">
                                <button className="btn post_btn btnFill smallbtn green_btn">
                                  <div className="btnfilltext">
                                    <i className="fa-solid fa-check" />
                                  </div>
                                </button>
                                <button className="btn post_btn btnFill smallbtn red_btn">
                                  <div className="btnfilltext">
                                    <i className="fa-regular fa-trash-can" />
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="post_container friend_list">
                          <div className="row align-items-start">
                            <div className="col-md-1">
                              <div className="poster_img">
                                <img src="./images/profile_dp.png" />
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="poster_detail">
                                <p className="poster_name">Mukesh jat</p>
                                <p className="date_time">
                                  Activity : 53 minuts ago
                                </p>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="add_post_btn text-center Withdraw_row">
                                <button className="btn post_btn btnFill smallbtn">
                                  <div className="btnfilltext">
                                    Request Sent
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {show === 2 && (
                    <div className="friends_content">
                      <div className="early_posts friends_lists no_users">
                        <p className="h1_30">There are no users</p>
                      </div>
                    </div>
                  )}
                  {show === 3 && (
                    <div className="friends_content">
                      <div className="early_posts friends_lists">
                        <div className="post_container friend_list">
                          <div className="row align-items-start">
                            <div className="col-md-1">
                              <div className="poster_img">
                                <img src="./images/profile_dp.png" />
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="poster_detail">
                                <p className="poster_name">Mukesh jat</p>
                                <p className="date_time">
                                  Activity : 53 minuts ago
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {show === 4 && (
                    <div className="friends_content">
                      <div className="form_container">
                        <form>
                          <div className="row g-5">
                            <div className="col-md-6">
                              <label className="Label" htmlFor="link">
                                Use this link to invite your friends
                              </label>
                              <input
                                type="text"
                                id="link"
                                className="input"
                                defaultValue="http://www.triunfador.net/"
                                placeholder="http://www.triunfador.net/"
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="Label" htmlFor="link">
                                Invite by mail
                              </label>
                              <input
                                type="mail"
                                id="Email"
                                className="input"
                                placeholder="E-mail ID"
                              />
                            </div>
                          </div>
                          <input
                            type="submit"
                            className="btn post_btn btnFill smallbtn"
                            defaultValue="Send"
                          />
                        </form>
                      </div>
                      <div className="social_content">
                        <h5 className="text20black700">SOCIAL NETWORKS</h5>
                        <div className="social_sites">
                          <button className="btn post_btn btnFill smallbtn black_btn">
                            <div className="btnfilltext">
                              <i className="fa-brands fa-facebook-f" />
                            </div>
                          </button>
                          <button className="btn post_btn btnFill smallbtn black_btn">
                            <div className="btnfilltext">
                              <i className="fa-brands fa-instagram" />
                            </div>
                          </button>
                          <button className="btn post_btn btnFill smallbtn black_btn">
                            <div className="btnfilltext">
                              <i className="fa-brands fa-twitter" />
                            </div>
                          </button>
                          <button className="btn post_btn btnFill smallbtn black_btn">
                            <div className="btnfilltext">
                              <i className="fa-solid fa-paper-plane" />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <Banner />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FriendReferrelas;
