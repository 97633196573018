import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  onDeleteNotification,
  onUserNotification,
} from "../../../redux/homeAction";
import Banner from "../../Banner/Banner";
import Footer from "../../Footer/Footer";
import Loader from "../../loader/Loader";
import Navbar from "../../Navbar/Navbar";
import Profile from "../Profile/Profile";

const Notification = () => {
  const dispatch = useDispatch();
  const [addClass, setAddClass] = useState(false);
  const [loader, setLoader] = useState(false);

  const getOnUserNotification = useSelector(
    (state) => state.home.getOnUserNotification
  );
  const getOnDeleteNotification = useSelector(
    (state) => state.home.getOnDeleteNotification
  );

  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    setLoader(true);
    const data = {
      user_id: userDeatils && userDeatils.user_id,
      // user_id: "470037",
    };
    dispatch(onUserNotification(data, setLoader));
  }, [getOnDeleteNotification]);

  const deleteNotification = (id) => {
    const data = {
      user_id: userDeatils && userDeatils.user_id,
      notifications_id: id,
    };
    dispatch(onDeleteNotification(data));
  };

  const classSend = "position-relative";

  return (
    <>
      <Navbar classSend={classSend} />
      <Profile />
      <section id="Tabs_section">
        <div className="container">
          <div className="tabs_container">
            <div className="tabs_nav">
              <ul id="tabs">
                {/* <li>
                  <div>
                    <NavLink to="/dashboard-profile">Wall</NavLink>
                  </div>
                </li> */}
                <li className={`${addClass ? "active_tab" : ""} `}>
                  <div>
                    <NavLink
                      to="/dashboard-notification"
                      className={({ isActive }) => {
                        setAddClass(isActive);
                      }}
                    >
                      notification
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-my-wallet">my wallet</NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-my-bets">my bets</NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-friend-referrelas">
                      friends &amp; referrelas
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-setting">settings</NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="tebs_content">
            <div className="tabs_section" id="notification">
              <div className="theWall">
                <h1>Notification</h1>
                {loader ? (
                  <div className="text-center mt-5">
                    <Loader />
                  </div>
                ) : (
                  <div className="early_posts">
                    {getOnUserNotification && getOnUserNotification.length ? (
                      <>
                        {getOnUserNotification &&
                          getOnUserNotification.map((data, i) => (
                            <div className="post_container" key={i}>
                              <div className="row align-items-start">
                                <div className="col-md-1">
                                  <div className="poster_img">
                                    <img src={data.image} />
                                  </div>
                                </div>
                                <div className="col-md-10">
                                  <div className="poster_detail">
                                    <p className="poster_name">
                                      {userDeatils && userDeatils.full_name}
                                      <span className="poster_username">
                                        @{userDeatils && userDeatils.username}
                                      </span>
                                    </p>
                                    <p>{data.message}</p>
                                    <p className="date_time">
                                      {moment(data.created_at).format(
                                        "DD-MM-YYYY | LT"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <div
                                    className="post_delete"
                                    onClick={() => deleteNotification(data.id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img src="./images/delete_icon.png" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </>
                    ) : (
                      <h4 className="text-center mt-3 mb-3">No Data Found</h4>
                    )}
                  </div>
                )}
                <Banner />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Notification;
