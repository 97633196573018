import moment from "moment";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import { onUserBalance } from "../../../redux/homeAction";

const BetModel = ({
  userBetsGame,
  matchData,
  setTeamId,
  setAmount,
  amount,
  teamId,
}) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState(() =>
    new Date().getTime() ? "" : new Date().getTime()
  );
  const getUserBalance = useSelector((state) => state.home.getUserBalance);
  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onUserBalance(data));
  }, []);

  useEffect(() => {
    if (matchData.start_date) {
      const latestDate = moment(
        matchData.start_date,
        "YYYY-MM-DD hh:mm:ss A"
      ).format("YYYY-MM-DDTHH:mm:ss");
      const checkInTime = new Date(latestDate).getTime();
      const interValTime = setInterval(() => {
        setTime(checkInTime - new Date().getTime());
      }, 1000);

      return () => {
        clearInterval(interValTime);
      };
    }
  }, [getUserBalance, matchData]);

  const verifyAmount = (amount) => {
    const validated = /^[0-9\b]+$/;
    if (amount === "" || validated.test(amount)) {
      setAmount(amount);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="betHere"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="modal-body text-center">
              <h1>{matchData.sport_name}</h1>
              <p className="color_yellow">
                {/* <Countdown
                  date={
                    Date.now() +
                    parseInt(moment(matchData.start_date).format("x"))
                  }
                />{" "} */}
                {/* {moment(matchData.start_date).endOf("day").fromNow()}{" "} */}
                {time && new Date(time).toISOString().slice(11, 19)}{" "}
                {time && "hrs left"}
              </p>
              <div className="match_detail">
                <div className="team_content">
                  <div className="team_box">
                    <div
                      className={`team_logo team_logo_border ${
                        matchData.team1 === teamId ? "active" : ""
                      }`}
                    >
                      <img src={matchData.team2pitcher} className="team_logo" />
                    </div>
                    <h5
                      className={`${
                        matchData.team1 === teamId ? "active" : ""
                      }`}
                    >
                      {matchData.team1display}
                    </h5>
                    <p>Garter Type</p>
                  </div>
                  <div className="match_timing team_box text-center">
                    <p className="textGRY700 textspaceAdd">
                      {moment(matchData.start_date).format("DD MMM  YYYY")}
                    </p>
                    <p className="text20black">
                      {moment(matchData.start_date).format("LT")}
                    </p>
                  </div>
                  <div className="team_box">
                    <div
                      className={`team_logo team_logo_border ${
                        matchData.team2 === teamId ? "active" : ""
                      }`}
                    >
                      <img src={matchData.team1pitcher} className="team_logo" />
                    </div>
                    <h5
                      className={`${
                        matchData.team2 === teamId ? "active" : ""
                      }`}
                    >
                      {matchData.team2display}
                    </h5>
                    <p>{matchData.league_name}</p>
                  </div>
                </div>
                <div className="match_contest">
                  <div className="contect_detail">
                    <p className="P_gry_normal">Home</p>
                    <p className="H5_bold_black">
                      {matchData?.moneyline?.home}
                    </p>
                  </div>
                  <div className="contect_detail">
                    <p className="P_gry_normal">Tie</p>
                    <p className="H5_bold_black">-</p>
                  </div>
                  <div className="contect_detail">
                    <p className="P_gry_normal">Away</p>
                    <p className="H5_bold_black">
                      {matchData?.moneyline?.away}
                    </p>
                  </div>
                </div>
                <div className="bet_btns match_contest">
                  <div
                    className={`btn post_btn btnFill bet_btn`}
                    onClick={() => setTeamId(matchData.team1)}
                  >
                    <div className="btnfilltext">
                      {matchData.team1 === teamId ? "Cancel" : "BET"}
                    </div>
                  </div>
                  <div
                    className={`btn post_btn btnFill bet_btn`}
                    onClick={() => setTeamId(matchData.team2)}
                  >
                    <div className="btnfilltext">
                      {matchData.team2 === teamId ? "Cancel" : "BET"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bet_action text-center">
                <div className="bet_amount">
                  <input
                    type="text"
                    className="input"
                    onChange={(e) => verifyAmount(e.target.value)}
                    value={amount}
                  />
                  <div
                    className="btn post_btn fly_btn"
                    onClick={() => userBetsGame(matchData.id)}
                  >
                    <div>
                      <img src="./images/fly.png" className="fly_img" />
                    </div>
                  </div>
                </div>
                <p className="text_normal mt-2">
                  Possible Gain :{" "}
                  <span className="text_green">
                    {getUserBalance && getUserBalance.balance}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BetModel;
