import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Footer from "../../componets/Footer/Footer";
import Loader from "../../componets/loader/Loader";
import Navbar from "../../componets/Navbar/Navbar";
import { onUserContactUs } from "../../redux/homeAction";

const ContactUs = () => {
  const dispatch = useDispatch();
  const classSend = "position-relative";
  const [value, setValue] = useState({});
  const [loader, setLoader] = useState(false);

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  var numberRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  const inputChangeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const submitContact = (e) => {
    e.preventDefault();
    if (!value.fullName) {
      toast.error("please enter full name");
    } else if (!value.email) {
      toast.error("please enter a email");
    } else if (!emailRegex.test(value.email)) {
      toast.error("Please enter a correct email");
    } else if (!value.phone) {
      toast.error("please enter a mobile number");
    } else if (!numberRegex.test(value.phone)) {
      toast.error("please enter a correct mobile number");
    } else if (!value.message) {
      toast.error("please enter a message");
    } else {
      setLoader(true);
      const data = {
        full_name: value.fullName,
        email: value.email,
        mobile: value.phone,
        message: value.message,
      };
      setValue({
        fullName: "",
        email: "",
        phone: "",
        message: "",
      });
      dispatch(onUserContactUs(data, setLoader, setValue));
    }
  };

  return (
    <>
      <Navbar classSend={classSend} />

      <section className="contact_us">
        <div className="container">
          <div className="contact_heading position-relative">
            <h1>CONTACT</h1>
            <h2>GET IN TOUCH</h2>
          </div>
          <div className="row contact_us_content justify-content-between mt-sm-1 mt-md-5 mt-1 gy-3">
            <div className="col-md-5">
              <h1>DON’T BE SHY</h1>
              <p className="mt-3 mb-5">
                If you have any concerns, errors or suggestions, please do not
                hesitate to contact us. We will respond to your message as soon
                as possible, but it may take up to 48 hours. Please check your
                SPAM folder just in case, as messages can sometimes end up
                there.
              </p>
              <div className="contact_box d-flex mb-4">
                <div className="contact_box_img me-3">
                  <img src="./images/email.png" className="conact_box_img" />
                </div>
                <div className="contact_box_detail">
                  <h6>Email</h6>
                  <p>soporte@triunfador.net</p>
                </div>
              </div>
              {/* <div className="contact_box d-flex mb-4">
                <div className="contact_box_img me-3">
                  <img src="./images/call.png" className="conact_box_img" />
                </div>
                <div className="contact_box_detail">
                  <h6>Phone</h6>
                  <p>+91 9876543210</p>
                </div>
              </div> */}
              {/* <div className="contact_box d-flex">
                <div className="contact_box_img me-3">
                  <img src="./images/location.png" className="conact_box_img" />
                </div>
                <div className="contact_box_detail">
                  <h6>Address</h6>
                  <p>
                    4044 Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. 5088
                  </p>
                </div>
              </div> */}
            </div>
            <div className="col-md-6">
              <form className="contact_us_form" onSubmit={submitContact}>
                <div className="row gy-3">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control input"
                        placeholder="Full Name"
                        name="fullName"
                        value={value.fullName}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control input"
                        placeholder="Email"
                        name="email"
                        value={value.email}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control input"
                        placeholder="Phone number"
                        name="phone"
                        value={value.phone}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        cols={15}
                        rows={4}
                        className="input form-control"
                        placeholder="Message"
                        name="message"
                        value={value.message}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    {loader ? (
                      <div className="text-center">
                        {" "}
                        <Loader />
                      </div>
                    ) : (
                      <input
                        type="submit"
                        className="input submit_input"
                        defaultValue="Submit"
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
