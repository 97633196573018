import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { onMyProfile } from "../../redux/homeAction";
import navbarImage from "./logo.png";
import navbarProfile from "./profile_dp.png";

const Navbar = ({ classSend }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectLan, setSelectLan] = useState("");

  const [openMenu, setOpenMenu] = useState(false);

  const getOnMyProfile = useSelector((state) => state.home.getOnMyProfile);
  const getOnUpdateProfile = useSelector(
    (state) => state.home.getOnUpdateProfile
  );

  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));

  let lang = localStorage.getItem("lang") || "/en";

  const setCookie = (key, value, expiry) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
    document.cookie = key + "=" + value + ";expires=" + expires.toUTCString();
  };

  useEffect(() => {
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onMyProfile(data));
  }, [getOnUpdateProfile]);

  useEffect(() => {
    if (lang === "/en") {
      setCookie("googtrans", "/en", 1);
    }
  }, [lang]);

  const reDirectPage = () => {
    navigate("/login");
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenMenu(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const userLogOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  const userToken = localStorage.getItem("userToken");

  const languageChangeHandler = (e) => {
    if (e === "en") {
      setCookie("googtrans", "/en", 1);
      localStorage.setItem("lang", "en");
      setSelectLan("en");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      setCookie("googtrans", "/en/es", 1);
      localStorage.setItem("lang", "es");
      setSelectLan("es");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  useEffect(() => {
    setSelectLan(localStorage.getItem("lang"));
  }, []);

  return (
    <>
      <header
        className={`header page-header header-transprant ${classSend}`}
        ref={wrapperRef}
      >
        <nav className="navbar navbar-expand-lg navbar-dar">
          <div className="container position-relative">
            <Link className="navbar-brnad" to="/">
              <img src={navbarImage} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fa fa-bars" />
            </button>
            <div className="profile_box_responsive">
              {userToken ? (
                <>
                  <button
                    className="btn-black"
                    onClick={() => setOpenMenu(!openMenu)}
                  >
                    <div className="text-box">
                      <img
                        className="btn-icon"
                        src={
                          getOnMyProfile && getOnMyProfile.image
                            ? getOnMyProfile && getOnMyProfile.image
                            : navbarProfile
                        }
                      />
                      {getOnMyProfile && getOnMyProfile.username}
                      <i className="fa fa-caret-down px-2"></i>
                    </div>
                  </button>
                  {openMenu && (
                    <div
                      className={`Open_menu ${openMenu ? "active" : ""}`}
                      id="profileOpenn"
                    >
                      <Link to="/dashboard-edit-profile">
                        <div className="menu_option">
                          <i className="fa-regular fa-user" />
                          <p className="text_normal">My profile</p>
                        </div>
                      </Link>
                      <Link to={`/dashboard-my-bets/?path=bet`}>
                        <div className="menu_option">
                          <svg
                            width={25}
                            height={25}
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.48228 9.98662C8.24257 9.98111 8.00756 9.91807 7.79655 9.80271C7.58554 9.68736 7.4045 9.52294 7.26834 9.32298C7.13216 9.12301 7.04473 8.89318 7.01319 8.65234C6.98164 8.4115 7.0069 8.16651 7.08688 7.93748C7.15576 7.75039 7.26652 7.58205 7.41045 7.44568C7.62045 7.23229 7.8884 7.08715 8.18016 7.02875C8.47192 6.97034 8.77424 7.00137 9.04853 7.11782C9.23769 7.19034 9.40987 7.302 9.55411 7.44568C9.6912 7.58781 9.80093 7.75459 9.87768 7.93748C9.99705 8.21798 10.03 8.52849 9.9723 8.82832C9.91456 9.12815 9.76879 9.40331 9.55411 9.61777C9.40987 9.76146 9.23769 9.87311 9.04853 9.94563C8.86476 9.99965 8.67177 10.0136 8.48228 9.98662Z"
                              fill="#7F7F7F"
                            />
                            <path
                              d="M12.4995 14C12.1019 14 11.7204 13.842 11.4393 13.5605C11.158 13.2793 11 12.8976 11 12.4997C11.0046 12.3025 11.0382 12.107 11.1 11.9196C11.1829 11.7401 11.2983 11.5775 11.4399 11.4395C11.5786 11.3039 11.7414 11.1952 11.9197 11.1194C12.0995 11.0406 12.2934 11 12.4895 11C12.6857 11 12.8796 11.0406 13.0594 11.1194C13.2463 11.1902 13.4166 11.2993 13.5592 11.4395C13.6948 11.5783 13.8033 11.7409 13.8791 11.9196C13.9971 12.1934 14.0297 12.4965 13.9727 12.7892C13.9155 13.082 13.7715 13.3505 13.5592 13.5599C13.2759 13.8378 12.8962 13.9954 12.4995 14Z"
                              fill="#7F7F7F"
                            />
                            <path
                              d="M16.4999 18C16.307 17.9984 16.1162 17.9575 15.9394 17.8798C15.7523 17.8089 15.5817 17.6997 15.439 17.5593C15.1579 17.2776 15 16.8956 15 16.4975C15 16.0992 15.1579 15.7174 15.439 15.4357C15.5817 15.2953 15.7523 15.1861 15.9394 15.1152C16.2111 15.0014 16.5101 14.971 16.799 15.0281C17.0878 15.0852 17.3531 15.2272 17.5609 15.4357C17.7706 15.6459 17.9134 15.9135 17.9712 16.2048C18.0291 16.4961 17.9992 16.798 17.8857 17.0723C17.7722 17.3467 17.5801 17.5813 17.3335 17.7464C17.0866 17.9115 16.7968 17.9998 16.4999 18Z"
                              fill="#7F7F7F"
                            />
                            <path
                              d="M18.4862 22.9999H6.51383C5.91818 23.0032 5.32785 22.8878 4.77722 22.6606C4.2266 22.4334 3.72668 22.0989 3.30659 21.6766C2.88652 21.2542 2.55468 20.7526 2.33041 20.2007C2.10613 19.6489 1.9939 19.058 2.00026 18.4623V6.48977C2.00026 5.89816 2.11717 5.31237 2.34429 4.76608C2.57141 4.2198 2.90425 3.7238 3.3237 3.30657C3.74314 2.88935 4.24091 2.55915 4.78838 2.33492C5.33585 2.11071 5.92224 1.9969 6.51383 2.00006H18.4862C19.0778 1.9969 19.6641 2.11071 20.2116 2.33492C20.7591 2.55915 21.2569 2.88935 21.6763 3.30657C22.0957 3.7238 22.4287 4.2198 22.6558 4.76608C22.8829 5.31237 22.9997 5.89816 22.9997 6.48977V18.4623C23.0061 19.058 22.8939 19.6489 22.6697 20.2007C22.4453 20.7526 22.1136 21.2542 21.6934 21.6766C21.2733 22.0989 20.7735 22.4334 20.2228 22.6606C19.6722 22.8878 19.0818 23.0032 18.4862 22.9999ZM6.51383 3.79595C6.15807 3.79279 5.80521 3.86013 5.47561 3.99409C5.14603 4.12806 4.84623 4.32597 4.59355 4.57644C4.34086 4.82689 4.1403 5.12493 4.00343 5.45333C3.86657 5.78173 3.7961 6.134 3.79611 6.48977V18.4623C3.7897 18.8221 3.85544 19.1796 3.98945 19.5135C4.12346 19.8474 4.32301 20.1512 4.57633 20.4068C4.82967 20.6623 5.13164 20.8646 5.46441 21.0015C5.79718 21.1384 6.154 21.2073 6.51383 21.2041H18.4862C18.8461 21.2073 19.2028 21.1384 19.5357 21.0015C19.8684 20.8646 20.1703 20.6623 20.4237 20.4068C20.677 20.1512 20.8766 19.8474 21.0105 19.5135C21.1446 19.1796 21.2104 18.8221 21.2039 18.4623V6.48977C21.2039 6.134 21.1335 5.78173 20.9967 5.45333C20.8597 5.12493 20.6592 4.82689 20.4064 4.57644C20.1538 4.32597 19.854 4.12806 19.5244 3.99409C19.1948 3.86013 18.842 3.79279 18.4862 3.79595H6.51383Z"
                              fill="#7F7F7F"
                            />
                          </svg>
                          <p className="text_normal">My Bets</p>
                        </div>
                      </Link>
                      <Link to="/dashboard-notification">
                        <div className="menu_option">
                          <i className="fa-regular fa-bell" />
                          <p className="text_normal">Notification</p>
                        </div>
                      </Link>
                      <Link to="/dashboard-my-wallet">
                        <div className="menu_option">
                          <svg
                            width={25}
                            height={25}
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.1875 13.2812H18.75V14.8438H17.1875V13.2812Z"
                              fill="black"
                              fillOpacity="0.5"
                            />
                            <path
                              d="M21.875 6.25H3.125V3.90625H20.3125V2.34375H3.125C2.7106 2.34375 2.31317 2.50837 2.02015 2.8014C1.72712 3.09442 1.5625 3.49185 1.5625 3.90625V20.3125C1.5625 20.7269 1.72712 21.1243 2.02015 21.4174C2.31317 21.7104 2.7106 21.875 3.125 21.875H21.875C22.2894 21.875 22.6868 21.7104 22.9799 21.4174C23.2729 21.1243 23.4375 20.7269 23.4375 20.3125V7.8125C23.4375 7.3981 23.2729 7.00067 22.9799 6.70765C22.6868 6.41462 22.2894 6.25 21.875 6.25V6.25ZM3.125 20.3125V7.8125H21.875V10.1562H15.625C15.2106 10.1562 14.8132 10.3209 14.5201 10.6139C14.2271 10.9069 14.0625 11.3043 14.0625 11.7188V16.4062C14.0625 16.8207 14.2271 17.2181 14.5201 17.5111C14.8132 17.8041 15.2106 17.9688 15.625 17.9688H21.875V20.3125H3.125ZM21.875 11.7188V16.4062H15.625V11.7188H21.875Z"
                              fill="black"
                              fillOpacity="0.5"
                            />
                          </svg>
                          <p className="text_normal">Wallet</p>
                        </div>
                      </Link>
                      <Link to={`/dashboard-my-bets/?path=win`}>
                        <div className="menu_option">
                          <svg
                            width={25}
                            height={25}
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.3125 2.73438H4.6875C4.0659 2.73438 3.46976 2.98131 3.03022 3.42084C2.59068 3.86038 2.34375 4.45652 2.34375 5.07812V19.9219C2.34375 20.5435 2.59068 21.1396 3.03022 21.5792C3.46976 22.0187 4.0659 22.2656 4.6875 22.2656H20.3125C20.9341 22.2656 21.5302 22.0187 21.9698 21.5792C22.4093 21.1396 22.6562 20.5435 22.6562 19.9219V5.07812C22.6562 4.45652 22.4093 3.86038 21.9698 3.42084C21.5302 2.98131 20.9341 2.73438 20.3125 2.73438ZM21.0938 19.9219C21.0938 20.1291 21.0114 20.3278 20.8649 20.4743C20.7184 20.6208 20.5197 20.7031 20.3125 20.7031H4.6875C4.4803 20.7031 4.28159 20.6208 4.13507 20.4743C3.98856 20.3278 3.90625 20.1291 3.90625 19.9219V5.07812C3.90625 4.87092 3.98856 4.67221 4.13507 4.5257C4.28159 4.37919 4.4803 4.29688 4.6875 4.29688H20.3125C20.5197 4.29688 20.7184 4.37919 20.8649 4.5257C21.0114 4.67221 21.0938 4.87092 21.0938 5.07812V19.9219Z"
                              fill="#7F7F7F"
                            />
                            <path
                              d="M17.5783 11.3285C17.7855 11.3285 17.9842 11.2462 18.1307 11.0997C18.2773 10.9531 18.3596 10.7544 18.3596 10.5472C18.3596 10.34 18.2773 10.1413 18.1307 9.99481C17.9842 9.84829 17.7855 9.76598 17.5783 9.76598H8.91816L9.92597 8.75426C10.054 8.60481 10.1208 8.41256 10.1132 8.21594C10.1057 8.01932 10.0241 7.8328 9.885 7.69367C9.74587 7.55453 9.55935 7.47302 9.36273 7.46543C9.16611 7.45783 8.97386 7.52471 8.82441 7.6527L6.48066 9.99645C6.37094 10.1054 6.29604 10.2445 6.26544 10.3961C6.23485 10.5477 6.24993 10.705 6.30878 10.848C6.36825 10.9906 6.46864 11.1123 6.59726 11.1978C6.72589 11.2833 6.87698 11.3288 7.03144 11.3285H17.5783Z"
                              fill="#7F7F7F"
                            />
                            <path
                              d="M18.3594 14.0625H7.8125C7.6053 14.0625 7.40659 14.1448 7.26007 14.2913C7.11356 14.4378 7.03125 14.6366 7.03125 14.8438C7.03125 15.051 7.11356 15.2497 7.26007 15.3962C7.40659 15.5427 7.6053 15.625 7.8125 15.625H16.4727L15.4648 16.6367C15.3831 16.7068 15.3166 16.7929 15.2697 16.8899C15.2229 16.9868 15.1965 17.0924 15.1923 17.2C15.1882 17.3076 15.2063 17.4148 15.2456 17.5151C15.2849 17.6154 15.3444 17.7064 15.4206 17.7825C15.4967 17.8587 15.5878 17.9183 15.688 17.9575C15.7883 17.9968 15.8956 18.0149 16.0032 18.0108C16.1108 18.0066 16.2163 17.9803 16.3133 17.9334C16.4102 17.8865 16.4964 17.8201 16.5664 17.7383L18.9102 15.3945C19.0199 15.2855 19.0948 15.1464 19.1254 14.9948C19.156 14.8432 19.1409 14.686 19.082 14.543C19.0226 14.4004 18.9222 14.2787 18.7936 14.1932C18.6649 14.1077 18.5138 14.0622 18.3594 14.0625Z"
                              fill="#7F7F7F"
                            />
                          </svg>
                          <p className="text_normal">My transaction</p>
                        </div>
                      </Link>
                      <Link onClick={userLogOut}>
                        <div className="menu_option">
                          <svg
                            width={25}
                            height={25}
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.19 17.8711H19.4737C19.3565 17.8711 19.2466 17.9224 19.1734 18.0127C19.0025 18.2203 18.8194 18.4204 18.6265 18.6109C17.8377 19.4005 16.9033 20.0298 15.8751 20.4639C14.8098 20.9139 13.6648 21.1447 12.5084 21.1426C11.3389 21.1426 10.2061 20.9131 9.14166 20.4639C8.11341 20.0298 7.17903 19.4005 6.39019 18.6109C5.59995 17.8239 4.96982 16.8912 4.53472 15.8643C4.08306 14.7998 3.85601 13.6695 3.85601 12.5C3.85601 11.3306 4.0855 10.2002 4.53472 9.13578C4.96929 8.10795 5.59429 7.18265 6.39019 6.3892C7.18609 5.59574 8.11138 4.97074 9.14166 4.53617C10.2061 4.08695 11.3389 3.85746 12.5084 3.85746C13.6778 3.85746 14.8106 4.08451 15.8751 4.53617C16.9053 4.97074 17.8306 5.59574 18.6265 6.3892C18.8194 6.58207 19.0001 6.78226 19.1734 6.98734C19.2466 7.07767 19.3589 7.12894 19.4737 7.12894H21.19C21.3438 7.12894 21.439 6.95805 21.3536 6.82865C19.481 3.91849 16.2046 1.99222 12.4815 2.00199C6.63189 2.01664 1.94195 6.76517 2.00054 12.6075C2.05914 18.357 6.74175 22.9981 12.5084 22.9981C16.2217 22.9981 19.4835 21.0743 21.3536 18.1714C21.4366 18.042 21.3438 17.8711 21.19 17.8711ZM23.3604 12.3462L19.896 9.61185C19.7667 9.50932 19.5787 9.60209 19.5787 9.76566V11.6211H11.9127C11.8052 11.6211 11.7173 11.709 11.7173 11.8164V13.1836C11.7173 13.2911 11.8052 13.3789 11.9127 13.3789H19.5787V15.2344C19.5787 15.398 19.7691 15.4908 19.896 15.3882L23.3604 12.6538C23.3838 12.6356 23.4026 12.6122 23.4156 12.5856C23.4286 12.5589 23.4353 12.5297 23.4353 12.5C23.4353 12.4704 23.4286 12.4411 23.4156 12.4145C23.4026 12.3878 23.3838 12.3645 23.3604 12.3462Z"
                              fill="black"
                              fillOpacity="0.5"
                            />
                          </svg>
                          <p className="text_normal">Log Out</p>
                        </div>
                      </Link>
                    </div>
                  )}
                </>
              ) : (
                <button className="btn-black" onClick={reDirectPage}>
                  <div className="text-box">
                    <img className="btn-icon" src="./images/user.svg" />
                    Login
                  </div>
                </button>
              )}
            </div>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav mx-auto  mr-auto d-lg-flex">
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    end
                  >
                    START
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/sports-betting"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                  >
                    SPORTS BETTING
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/prizes"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                  >
                    PRIZES
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/academy"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                  >
                    ACADEMY
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/earn-points"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                  >
                    EARN POINTS
                  </NavLink>
                </li>
                <li className="nav-item" style={{ padding: "0px" }}>
                  <select
                    className="nav-link select"
                    onChange={(e) => languageChangeHandler(e.target.value)}
                    value={selectLan}
                  >
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                  </select>
                </li>
              </ul>
              <div className="d-flex profile_box position-relative">
                {userToken ? (
                  <>
                    <button
                      className="btn-black"
                      onClick={() => setOpenMenu(!openMenu)}
                    >
                      <div className="text-box">
                        {getOnMyProfile && getOnMyProfile.image ? (
                          <img
                            className="btn-icon"
                            src={getOnMyProfile && getOnMyProfile.image}
                          />
                        ) : (
                          <img
                            src={navbarProfile}
                            className="profile_dp"
                            style={{
                              width: "40px",
                              height: "40px",
                              marginRight: "5px",
                            }}
                          />
                        )}
                        {getOnMyProfile && getOnMyProfile.username}
                        <i className="fa fa-caret-down px-2"></i>
                      </div>
                    </button>
                    {openMenu && (
                      <div
                        className={`Open_menu ${openMenu ? "active" : ""}`}
                        id="profileOpenn"
                      >
                        <Link to="/dashboard-edit-profile">
                          <div className="menu_option">
                            <i className="fa-regular fa-user" />
                            <p className="text_normal">My profile</p>
                          </div>
                        </Link>
                        <Link to={`/dashboard-my-bets/?path=bet`}>
                          <div className="menu_option">
                            <svg
                              width={25}
                              height={25}
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.48228 9.98662C8.24257 9.98111 8.00756 9.91807 7.79655 9.80271C7.58554 9.68736 7.4045 9.52294 7.26834 9.32298C7.13216 9.12301 7.04473 8.89318 7.01319 8.65234C6.98164 8.4115 7.0069 8.16651 7.08688 7.93748C7.15576 7.75039 7.26652 7.58205 7.41045 7.44568C7.62045 7.23229 7.8884 7.08715 8.18016 7.02875C8.47192 6.97034 8.77424 7.00137 9.04853 7.11782C9.23769 7.19034 9.40987 7.302 9.55411 7.44568C9.6912 7.58781 9.80093 7.75459 9.87768 7.93748C9.99705 8.21798 10.03 8.52849 9.9723 8.82832C9.91456 9.12815 9.76879 9.40331 9.55411 9.61777C9.40987 9.76146 9.23769 9.87311 9.04853 9.94563C8.86476 9.99965 8.67177 10.0136 8.48228 9.98662Z"
                                fill="#7F7F7F"
                              />
                              <path
                                d="M12.4995 14C12.1019 14 11.7204 13.842 11.4393 13.5605C11.158 13.2793 11 12.8976 11 12.4997C11.0046 12.3025 11.0382 12.107 11.1 11.9196C11.1829 11.7401 11.2983 11.5775 11.4399 11.4395C11.5786 11.3039 11.7414 11.1952 11.9197 11.1194C12.0995 11.0406 12.2934 11 12.4895 11C12.6857 11 12.8796 11.0406 13.0594 11.1194C13.2463 11.1902 13.4166 11.2993 13.5592 11.4395C13.6948 11.5783 13.8033 11.7409 13.8791 11.9196C13.9971 12.1934 14.0297 12.4965 13.9727 12.7892C13.9155 13.082 13.7715 13.3505 13.5592 13.5599C13.2759 13.8378 12.8962 13.9954 12.4995 14Z"
                                fill="#7F7F7F"
                              />
                              <path
                                d="M16.4999 18C16.307 17.9984 16.1162 17.9575 15.9394 17.8798C15.7523 17.8089 15.5817 17.6997 15.439 17.5593C15.1579 17.2776 15 16.8956 15 16.4975C15 16.0992 15.1579 15.7174 15.439 15.4357C15.5817 15.2953 15.7523 15.1861 15.9394 15.1152C16.2111 15.0014 16.5101 14.971 16.799 15.0281C17.0878 15.0852 17.3531 15.2272 17.5609 15.4357C17.7706 15.6459 17.9134 15.9135 17.9712 16.2048C18.0291 16.4961 17.9992 16.798 17.8857 17.0723C17.7722 17.3467 17.5801 17.5813 17.3335 17.7464C17.0866 17.9115 16.7968 17.9998 16.4999 18Z"
                                fill="#7F7F7F"
                              />
                              <path
                                d="M18.4862 22.9999H6.51383C5.91818 23.0032 5.32785 22.8878 4.77722 22.6606C4.2266 22.4334 3.72668 22.0989 3.30659 21.6766C2.88652 21.2542 2.55468 20.7526 2.33041 20.2007C2.10613 19.6489 1.9939 19.058 2.00026 18.4623V6.48977C2.00026 5.89816 2.11717 5.31237 2.34429 4.76608C2.57141 4.2198 2.90425 3.7238 3.3237 3.30657C3.74314 2.88935 4.24091 2.55915 4.78838 2.33492C5.33585 2.11071 5.92224 1.9969 6.51383 2.00006H18.4862C19.0778 1.9969 19.6641 2.11071 20.2116 2.33492C20.7591 2.55915 21.2569 2.88935 21.6763 3.30657C22.0957 3.7238 22.4287 4.2198 22.6558 4.76608C22.8829 5.31237 22.9997 5.89816 22.9997 6.48977V18.4623C23.0061 19.058 22.8939 19.6489 22.6697 20.2007C22.4453 20.7526 22.1136 21.2542 21.6934 21.6766C21.2733 22.0989 20.7735 22.4334 20.2228 22.6606C19.6722 22.8878 19.0818 23.0032 18.4862 22.9999ZM6.51383 3.79595C6.15807 3.79279 5.80521 3.86013 5.47561 3.99409C5.14603 4.12806 4.84623 4.32597 4.59355 4.57644C4.34086 4.82689 4.1403 5.12493 4.00343 5.45333C3.86657 5.78173 3.7961 6.134 3.79611 6.48977V18.4623C3.7897 18.8221 3.85544 19.1796 3.98945 19.5135C4.12346 19.8474 4.32301 20.1512 4.57633 20.4068C4.82967 20.6623 5.13164 20.8646 5.46441 21.0015C5.79718 21.1384 6.154 21.2073 6.51383 21.2041H18.4862C18.8461 21.2073 19.2028 21.1384 19.5357 21.0015C19.8684 20.8646 20.1703 20.6623 20.4237 20.4068C20.677 20.1512 20.8766 19.8474 21.0105 19.5135C21.1446 19.1796 21.2104 18.8221 21.2039 18.4623V6.48977C21.2039 6.134 21.1335 5.78173 20.9967 5.45333C20.8597 5.12493 20.6592 4.82689 20.4064 4.57644C20.1538 4.32597 19.854 4.12806 19.5244 3.99409C19.1948 3.86013 18.842 3.79279 18.4862 3.79595H6.51383Z"
                                fill="#7F7F7F"
                              />
                            </svg>
                            <p className="text_normal">My Bets</p>
                          </div>
                        </Link>
                        <Link to="/dashboard-notification">
                          <div className="menu_option">
                            <i className="fa-regular fa-bell" />
                            <p className="text_normal">Notification</p>
                          </div>
                        </Link>
                        <Link to="/dashboard-my-wallet">
                          <div className="menu_option">
                            <svg
                              width={25}
                              height={25}
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.1875 13.2812H18.75V14.8438H17.1875V13.2812Z"
                                fill="black"
                                fillOpacity="0.5"
                              />
                              <path
                                d="M21.875 6.25H3.125V3.90625H20.3125V2.34375H3.125C2.7106 2.34375 2.31317 2.50837 2.02015 2.8014C1.72712 3.09442 1.5625 3.49185 1.5625 3.90625V20.3125C1.5625 20.7269 1.72712 21.1243 2.02015 21.4174C2.31317 21.7104 2.7106 21.875 3.125 21.875H21.875C22.2894 21.875 22.6868 21.7104 22.9799 21.4174C23.2729 21.1243 23.4375 20.7269 23.4375 20.3125V7.8125C23.4375 7.3981 23.2729 7.00067 22.9799 6.70765C22.6868 6.41462 22.2894 6.25 21.875 6.25V6.25ZM3.125 20.3125V7.8125H21.875V10.1562H15.625C15.2106 10.1562 14.8132 10.3209 14.5201 10.6139C14.2271 10.9069 14.0625 11.3043 14.0625 11.7188V16.4062C14.0625 16.8207 14.2271 17.2181 14.5201 17.5111C14.8132 17.8041 15.2106 17.9688 15.625 17.9688H21.875V20.3125H3.125ZM21.875 11.7188V16.4062H15.625V11.7188H21.875Z"
                                fill="black"
                                fillOpacity="0.5"
                              />
                            </svg>
                            <p className="text_normal">Wallet</p>
                          </div>
                        </Link>
                        <Link to={`/dashboard-my-bets/?path=win`}>
                          <div className="menu_option">
                            <svg
                              width={25}
                              height={25}
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.3125 2.73438H4.6875C4.0659 2.73438 3.46976 2.98131 3.03022 3.42084C2.59068 3.86038 2.34375 4.45652 2.34375 5.07812V19.9219C2.34375 20.5435 2.59068 21.1396 3.03022 21.5792C3.46976 22.0187 4.0659 22.2656 4.6875 22.2656H20.3125C20.9341 22.2656 21.5302 22.0187 21.9698 21.5792C22.4093 21.1396 22.6562 20.5435 22.6562 19.9219V5.07812C22.6562 4.45652 22.4093 3.86038 21.9698 3.42084C21.5302 2.98131 20.9341 2.73438 20.3125 2.73438ZM21.0938 19.9219C21.0938 20.1291 21.0114 20.3278 20.8649 20.4743C20.7184 20.6208 20.5197 20.7031 20.3125 20.7031H4.6875C4.4803 20.7031 4.28159 20.6208 4.13507 20.4743C3.98856 20.3278 3.90625 20.1291 3.90625 19.9219V5.07812C3.90625 4.87092 3.98856 4.67221 4.13507 4.5257C4.28159 4.37919 4.4803 4.29688 4.6875 4.29688H20.3125C20.5197 4.29688 20.7184 4.37919 20.8649 4.5257C21.0114 4.67221 21.0938 4.87092 21.0938 5.07812V19.9219Z"
                                fill="#7F7F7F"
                              />
                              <path
                                d="M17.5783 11.3285C17.7855 11.3285 17.9842 11.2462 18.1307 11.0997C18.2773 10.9531 18.3596 10.7544 18.3596 10.5472C18.3596 10.34 18.2773 10.1413 18.1307 9.99481C17.9842 9.84829 17.7855 9.76598 17.5783 9.76598H8.91816L9.92597 8.75426C10.054 8.60481 10.1208 8.41256 10.1132 8.21594C10.1057 8.01932 10.0241 7.8328 9.885 7.69367C9.74587 7.55453 9.55935 7.47302 9.36273 7.46543C9.16611 7.45783 8.97386 7.52471 8.82441 7.6527L6.48066 9.99645C6.37094 10.1054 6.29604 10.2445 6.26544 10.3961C6.23485 10.5477 6.24993 10.705 6.30878 10.848C6.36825 10.9906 6.46864 11.1123 6.59726 11.1978C6.72589 11.2833 6.87698 11.3288 7.03144 11.3285H17.5783Z"
                                fill="#7F7F7F"
                              />
                              <path
                                d="M18.3594 14.0625H7.8125C7.6053 14.0625 7.40659 14.1448 7.26007 14.2913C7.11356 14.4378 7.03125 14.6366 7.03125 14.8438C7.03125 15.051 7.11356 15.2497 7.26007 15.3962C7.40659 15.5427 7.6053 15.625 7.8125 15.625H16.4727L15.4648 16.6367C15.3831 16.7068 15.3166 16.7929 15.2697 16.8899C15.2229 16.9868 15.1965 17.0924 15.1923 17.2C15.1882 17.3076 15.2063 17.4148 15.2456 17.5151C15.2849 17.6154 15.3444 17.7064 15.4206 17.7825C15.4967 17.8587 15.5878 17.9183 15.688 17.9575C15.7883 17.9968 15.8956 18.0149 16.0032 18.0108C16.1108 18.0066 16.2163 17.9803 16.3133 17.9334C16.4102 17.8865 16.4964 17.8201 16.5664 17.7383L18.9102 15.3945C19.0199 15.2855 19.0948 15.1464 19.1254 14.9948C19.156 14.8432 19.1409 14.686 19.082 14.543C19.0226 14.4004 18.9222 14.2787 18.7936 14.1932C18.6649 14.1077 18.5138 14.0622 18.3594 14.0625Z"
                                fill="#7F7F7F"
                              />
                            </svg>
                            <p className="text_normal">My transaction</p>
                          </div>
                        </Link>
                        <Link onClick={userLogOut}>
                          <div className="menu_option">
                            <svg
                              width={25}
                              height={25}
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.19 17.8711H19.4737C19.3565 17.8711 19.2466 17.9224 19.1734 18.0127C19.0025 18.2203 18.8194 18.4204 18.6265 18.6109C17.8377 19.4005 16.9033 20.0298 15.8751 20.4639C14.8098 20.9139 13.6648 21.1447 12.5084 21.1426C11.3389 21.1426 10.2061 20.9131 9.14166 20.4639C8.11341 20.0298 7.17903 19.4005 6.39019 18.6109C5.59995 17.8239 4.96982 16.8912 4.53472 15.8643C4.08306 14.7998 3.85601 13.6695 3.85601 12.5C3.85601 11.3306 4.0855 10.2002 4.53472 9.13578C4.96929 8.10795 5.59429 7.18265 6.39019 6.3892C7.18609 5.59574 8.11138 4.97074 9.14166 4.53617C10.2061 4.08695 11.3389 3.85746 12.5084 3.85746C13.6778 3.85746 14.8106 4.08451 15.8751 4.53617C16.9053 4.97074 17.8306 5.59574 18.6265 6.3892C18.8194 6.58207 19.0001 6.78226 19.1734 6.98734C19.2466 7.07767 19.3589 7.12894 19.4737 7.12894H21.19C21.3438 7.12894 21.439 6.95805 21.3536 6.82865C19.481 3.91849 16.2046 1.99222 12.4815 2.00199C6.63189 2.01664 1.94195 6.76517 2.00054 12.6075C2.05914 18.357 6.74175 22.9981 12.5084 22.9981C16.2217 22.9981 19.4835 21.0743 21.3536 18.1714C21.4366 18.042 21.3438 17.8711 21.19 17.8711ZM23.3604 12.3462L19.896 9.61185C19.7667 9.50932 19.5787 9.60209 19.5787 9.76566V11.6211H11.9127C11.8052 11.6211 11.7173 11.709 11.7173 11.8164V13.1836C11.7173 13.2911 11.8052 13.3789 11.9127 13.3789H19.5787V15.2344C19.5787 15.398 19.7691 15.4908 19.896 15.3882L23.3604 12.6538C23.3838 12.6356 23.4026 12.6122 23.4156 12.5856C23.4286 12.5589 23.4353 12.5297 23.4353 12.5C23.4353 12.4704 23.4286 12.4411 23.4156 12.4145C23.4026 12.3878 23.3838 12.3645 23.3604 12.3462Z"
                                fill="black"
                                fillOpacity="0.5"
                              />
                            </svg>
                            <p className="text_normal">Log Out</p>
                          </div>
                        </Link>
                      </div>
                    )}
                  </>
                ) : (
                  <button className="btn-black" onClick={reDirectPage}>
                    <div className="text-box">
                      <img className="btn-icon" src="./images/user.svg" />
                      Login
                    </div>
                  </button>
                )}
                {/* <div className="Open_menu" id="profileOpen">
                  <a href="./profile.html">
                    <div className="menu_option">
                      <i className="fa-regular fa-user" />
                      <p className="text_normal">My profile</p>
                    </div>
                  </a>
                  <a href="./bets.html">
                    <div className="menu_option">
                      <svg
                        width={25}
                        height={25}
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.48228 9.98662C8.24257 9.98111 8.00756 9.91807 7.79655 9.80271C7.58554 9.68736 7.4045 9.52294 7.26834 9.32298C7.13216 9.12301 7.04473 8.89318 7.01319 8.65234C6.98164 8.4115 7.0069 8.16651 7.08688 7.93748C7.15576 7.75039 7.26652 7.58205 7.41045 7.44568C7.62045 7.23229 7.8884 7.08715 8.18016 7.02875C8.47192 6.97034 8.77424 7.00137 9.04853 7.11782C9.23769 7.19034 9.40987 7.302 9.55411 7.44568C9.6912 7.58781 9.80093 7.75459 9.87768 7.93748C9.99705 8.21798 10.03 8.52849 9.9723 8.82832C9.91456 9.12815 9.76879 9.40331 9.55411 9.61777C9.40987 9.76146 9.23769 9.87311 9.04853 9.94563C8.86476 9.99965 8.67177 10.0136 8.48228 9.98662Z"
                          fill="#7F7F7F"
                        />
                        <path
                          d="M12.4995 14C12.1019 14 11.7204 13.842 11.4393 13.5605C11.158 13.2793 11 12.8976 11 12.4997C11.0046 12.3025 11.0382 12.107 11.1 11.9196C11.1829 11.7401 11.2983 11.5775 11.4399 11.4395C11.5786 11.3039 11.7414 11.1952 11.9197 11.1194C12.0995 11.0406 12.2934 11 12.4895 11C12.6857 11 12.8796 11.0406 13.0594 11.1194C13.2463 11.1902 13.4166 11.2993 13.5592 11.4395C13.6948 11.5783 13.8033 11.7409 13.8791 11.9196C13.9971 12.1934 14.0297 12.4965 13.9727 12.7892C13.9155 13.082 13.7715 13.3505 13.5592 13.5599C13.2759 13.8378 12.8962 13.9954 12.4995 14Z"
                          fill="#7F7F7F"
                        />
                        <path
                          d="M16.4999 18C16.307 17.9984 16.1162 17.9575 15.9394 17.8798C15.7523 17.8089 15.5817 17.6997 15.439 17.5593C15.1579 17.2776 15 16.8956 15 16.4975C15 16.0992 15.1579 15.7174 15.439 15.4357C15.5817 15.2953 15.7523 15.1861 15.9394 15.1152C16.2111 15.0014 16.5101 14.971 16.799 15.0281C17.0878 15.0852 17.3531 15.2272 17.5609 15.4357C17.7706 15.6459 17.9134 15.9135 17.9712 16.2048C18.0291 16.4961 17.9992 16.798 17.8857 17.0723C17.7722 17.3467 17.5801 17.5813 17.3335 17.7464C17.0866 17.9115 16.7968 17.9998 16.4999 18Z"
                          fill="#7F7F7F"
                        />
                        <path
                          d="M18.4862 22.9999H6.51383C5.91818 23.0032 5.32785 22.8878 4.77722 22.6606C4.2266 22.4334 3.72668 22.0989 3.30659 21.6766C2.88652 21.2542 2.55468 20.7526 2.33041 20.2007C2.10613 19.6489 1.9939 19.058 2.00026 18.4623V6.48977C2.00026 5.89816 2.11717 5.31237 2.34429 4.76608C2.57141 4.2198 2.90425 3.7238 3.3237 3.30657C3.74314 2.88935 4.24091 2.55915 4.78838 2.33492C5.33585 2.11071 5.92224 1.9969 6.51383 2.00006H18.4862C19.0778 1.9969 19.6641 2.11071 20.2116 2.33492C20.7591 2.55915 21.2569 2.88935 21.6763 3.30657C22.0957 3.7238 22.4287 4.2198 22.6558 4.76608C22.8829 5.31237 22.9997 5.89816 22.9997 6.48977V18.4623C23.0061 19.058 22.8939 19.6489 22.6697 20.2007C22.4453 20.7526 22.1136 21.2542 21.6934 21.6766C21.2733 22.0989 20.7735 22.4334 20.2228 22.6606C19.6722 22.8878 19.0818 23.0032 18.4862 22.9999ZM6.51383 3.79595C6.15807 3.79279 5.80521 3.86013 5.47561 3.99409C5.14603 4.12806 4.84623 4.32597 4.59355 4.57644C4.34086 4.82689 4.1403 5.12493 4.00343 5.45333C3.86657 5.78173 3.7961 6.134 3.79611 6.48977V18.4623C3.7897 18.8221 3.85544 19.1796 3.98945 19.5135C4.12346 19.8474 4.32301 20.1512 4.57633 20.4068C4.82967 20.6623 5.13164 20.8646 5.46441 21.0015C5.79718 21.1384 6.154 21.2073 6.51383 21.2041H18.4862C18.8461 21.2073 19.2028 21.1384 19.5357 21.0015C19.8684 20.8646 20.1703 20.6623 20.4237 20.4068C20.677 20.1512 20.8766 19.8474 21.0105 19.5135C21.1446 19.1796 21.2104 18.8221 21.2039 18.4623V6.48977C21.2039 6.134 21.1335 5.78173 20.9967 5.45333C20.8597 5.12493 20.6592 4.82689 20.4064 4.57644C20.1538 4.32597 19.854 4.12806 19.5244 3.99409C19.1948 3.86013 18.842 3.79279 18.4862 3.79595H6.51383Z"
                          fill="#7F7F7F"
                        />
                      </svg>
                      <p className="text_normal">My Bets</p>
                    </div>
                  </a>
                  <a href="./profile.html#notification">
                    <div className="menu_option">
                      <i className="fa-regular fa-bell" />
                      <p className="text_normal">Notification</p>
                    </div>
                  </a>
                  <a href="#">
                    <div className="menu_option">
                      <svg
                        width={25}
                        height={25}
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.1875 13.2812H18.75V14.8438H17.1875V13.2812Z"
                          fill="black"
                          fillOpacity="0.5"
                        />
                        <path
                          d="M21.875 6.25H3.125V3.90625H20.3125V2.34375H3.125C2.7106 2.34375 2.31317 2.50837 2.02015 2.8014C1.72712 3.09442 1.5625 3.49185 1.5625 3.90625V20.3125C1.5625 20.7269 1.72712 21.1243 2.02015 21.4174C2.31317 21.7104 2.7106 21.875 3.125 21.875H21.875C22.2894 21.875 22.6868 21.7104 22.9799 21.4174C23.2729 21.1243 23.4375 20.7269 23.4375 20.3125V7.8125C23.4375 7.3981 23.2729 7.00067 22.9799 6.70765C22.6868 6.41462 22.2894 6.25 21.875 6.25V6.25ZM3.125 20.3125V7.8125H21.875V10.1562H15.625C15.2106 10.1562 14.8132 10.3209 14.5201 10.6139C14.2271 10.9069 14.0625 11.3043 14.0625 11.7188V16.4062C14.0625 16.8207 14.2271 17.2181 14.5201 17.5111C14.8132 17.8041 15.2106 17.9688 15.625 17.9688H21.875V20.3125H3.125ZM21.875 11.7188V16.4062H15.625V11.7188H21.875Z"
                          fill="black"
                          fillOpacity="0.5"
                        />
                      </svg>
                      <p className="text_normal">Wallet</p>
                    </div>
                  </a>
                  <a href="./profile.html#bets">
                    <div className="menu_option">
                      <svg
                        width={25}
                        height={25}
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.3125 2.73438H4.6875C4.0659 2.73438 3.46976 2.98131 3.03022 3.42084C2.59068 3.86038 2.34375 4.45652 2.34375 5.07812V19.9219C2.34375 20.5435 2.59068 21.1396 3.03022 21.5792C3.46976 22.0187 4.0659 22.2656 4.6875 22.2656H20.3125C20.9341 22.2656 21.5302 22.0187 21.9698 21.5792C22.4093 21.1396 22.6562 20.5435 22.6562 19.9219V5.07812C22.6562 4.45652 22.4093 3.86038 21.9698 3.42084C21.5302 2.98131 20.9341 2.73438 20.3125 2.73438ZM21.0938 19.9219C21.0938 20.1291 21.0114 20.3278 20.8649 20.4743C20.7184 20.6208 20.5197 20.7031 20.3125 20.7031H4.6875C4.4803 20.7031 4.28159 20.6208 4.13507 20.4743C3.98856 20.3278 3.90625 20.1291 3.90625 19.9219V5.07812C3.90625 4.87092 3.98856 4.67221 4.13507 4.5257C4.28159 4.37919 4.4803 4.29688 4.6875 4.29688H20.3125C20.5197 4.29688 20.7184 4.37919 20.8649 4.5257C21.0114 4.67221 21.0938 4.87092 21.0938 5.07812V19.9219Z"
                          fill="#7F7F7F"
                        />
                        <path
                          d="M17.5783 11.3285C17.7855 11.3285 17.9842 11.2462 18.1307 11.0997C18.2773 10.9531 18.3596 10.7544 18.3596 10.5472C18.3596 10.34 18.2773 10.1413 18.1307 9.99481C17.9842 9.84829 17.7855 9.76598 17.5783 9.76598H8.91816L9.92597 8.75426C10.054 8.60481 10.1208 8.41256 10.1132 8.21594C10.1057 8.01932 10.0241 7.8328 9.885 7.69367C9.74587 7.55453 9.55935 7.47302 9.36273 7.46543C9.16611 7.45783 8.97386 7.52471 8.82441 7.6527L6.48066 9.99645C6.37094 10.1054 6.29604 10.2445 6.26544 10.3961C6.23485 10.5477 6.24993 10.705 6.30878 10.848C6.36825 10.9906 6.46864 11.1123 6.59726 11.1978C6.72589 11.2833 6.87698 11.3288 7.03144 11.3285H17.5783Z"
                          fill="#7F7F7F"
                        />
                        <path
                          d="M18.3594 14.0625H7.8125C7.6053 14.0625 7.40659 14.1448 7.26007 14.2913C7.11356 14.4378 7.03125 14.6366 7.03125 14.8438C7.03125 15.051 7.11356 15.2497 7.26007 15.3962C7.40659 15.5427 7.6053 15.625 7.8125 15.625H16.4727L15.4648 16.6367C15.3831 16.7068 15.3166 16.7929 15.2697 16.8899C15.2229 16.9868 15.1965 17.0924 15.1923 17.2C15.1882 17.3076 15.2063 17.4148 15.2456 17.5151C15.2849 17.6154 15.3444 17.7064 15.4206 17.7825C15.4967 17.8587 15.5878 17.9183 15.688 17.9575C15.7883 17.9968 15.8956 18.0149 16.0032 18.0108C16.1108 18.0066 16.2163 17.9803 16.3133 17.9334C16.4102 17.8865 16.4964 17.8201 16.5664 17.7383L18.9102 15.3945C19.0199 15.2855 19.0948 15.1464 19.1254 14.9948C19.156 14.8432 19.1409 14.686 19.082 14.543C19.0226 14.4004 18.9222 14.2787 18.7936 14.1932C18.6649 14.1077 18.5138 14.0622 18.3594 14.0625Z"
                          fill="#7F7F7F"
                        />
                      </svg>
                      <p className="text_normal">My transaction</p>
                    </div>
                  </a>
                  <a href="#">
                    <div className="menu_option">
                      <svg
                        width={25}
                        height={25}
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.19 17.8711H19.4737C19.3565 17.8711 19.2466 17.9224 19.1734 18.0127C19.0025 18.2203 18.8194 18.4204 18.6265 18.6109C17.8377 19.4005 16.9033 20.0298 15.8751 20.4639C14.8098 20.9139 13.6648 21.1447 12.5084 21.1426C11.3389 21.1426 10.2061 20.9131 9.14166 20.4639C8.11341 20.0298 7.17903 19.4005 6.39019 18.6109C5.59995 17.8239 4.96982 16.8912 4.53472 15.8643C4.08306 14.7998 3.85601 13.6695 3.85601 12.5C3.85601 11.3306 4.0855 10.2002 4.53472 9.13578C4.96929 8.10795 5.59429 7.18265 6.39019 6.3892C7.18609 5.59574 8.11138 4.97074 9.14166 4.53617C10.2061 4.08695 11.3389 3.85746 12.5084 3.85746C13.6778 3.85746 14.8106 4.08451 15.8751 4.53617C16.9053 4.97074 17.8306 5.59574 18.6265 6.3892C18.8194 6.58207 19.0001 6.78226 19.1734 6.98734C19.2466 7.07767 19.3589 7.12894 19.4737 7.12894H21.19C21.3438 7.12894 21.439 6.95805 21.3536 6.82865C19.481 3.91849 16.2046 1.99222 12.4815 2.00199C6.63189 2.01664 1.94195 6.76517 2.00054 12.6075C2.05914 18.357 6.74175 22.9981 12.5084 22.9981C16.2217 22.9981 19.4835 21.0743 21.3536 18.1714C21.4366 18.042 21.3438 17.8711 21.19 17.8711ZM23.3604 12.3462L19.896 9.61185C19.7667 9.50932 19.5787 9.60209 19.5787 9.76566V11.6211H11.9127C11.8052 11.6211 11.7173 11.709 11.7173 11.8164V13.1836C11.7173 13.2911 11.8052 13.3789 11.9127 13.3789H19.5787V15.2344C19.5787 15.398 19.7691 15.4908 19.896 15.3882L23.3604 12.6538C23.3838 12.6356 23.4026 12.6122 23.4156 12.5856C23.4286 12.5589 23.4353 12.5297 23.4353 12.5C23.4353 12.4704 23.4286 12.4411 23.4156 12.4145C23.4026 12.3878 23.3838 12.3645 23.3604 12.3462Z"
                          fill="black"
                          fillOpacity="0.5"
                        />
                      </svg>

                      <p className="text_normal">Log Out</p>
                    </div>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
