import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onMyProfile, onUserBalance } from "../../../redux/homeAction";
import moment from "moment";

const Profile = () => {
  const dispatch = useDispatch();
  const getOnMyProfile = useSelector((state) => state.home.getOnMyProfile);

  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));
  const getUserBalance = useSelector((state) => state.home.getUserBalance);

  useEffect(() => {
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onUserBalance(data));
  }, []);

  useEffect(() => {
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onMyProfile(data));
  }, []);

  return (
    <>
      <section id="Profile">
        <div className="container">
          <div className="row justify-content-between align-items-end gy-3">
            <div className="col-md-auto">
              <div className="profile_section_left">
                <div className="profile_img d-flex justify-content-center align-items-center">
                  {getOnMyProfile && getOnMyProfile.image ? (
                    <img
                      src={getOnMyProfile && getOnMyProfile.image}
                      className="profile_dp"
                    />
                  ) : (
                    <img src="/images/profile_dp.png" className="profile_dp" />
                  )}
                </div>
                <div className="profile_detail">
                  <h1 className="user_name">
                    {getOnMyProfile && getOnMyProfile.full_name}
                  </h1>
                  <p>
                    Email :{" "}
                    <span>{getOnMyProfile && getOnMyProfile.email}</span>
                  </p>
                  <p>
                    user name :{" "}
                    <span>{getOnMyProfile && getOnMyProfile.username}</span>
                  </p>
                  <p>
                    Date of admission :
                    <span>
                      {" "}
                      {moment(
                        getOnMyProfile && getOnMyProfile.created_at
                      ).format("DD/MM/YY")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-auto">
              <div className="profile_section_right">
                <Link to="/dashboard-edit-profile" className="edit_profile">
                  <img src="/images/Edit_icon.png" /> Edit profile
                </Link>
                <p className="balence_p">
                  Balance{" "}
                  <span>
                    {" "}
                    : {getUserBalance && getUserBalance.balance} Pts |{" "}
                    {getUserBalance && getUserBalance.usd} usd |{" "}
                    {getUserBalance && getUserBalance.btc}BTC
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
