import React from "react";

const CookiesPolicySection = () => {
  return (
    <>
      <section className="about">
        <div className="container">
          <div className="about-content">
            <h1 className="section_head text-center">
              Cookies <span className="color_yellow"> Policy</span>
            </h1>
          </div>
          <div className="content-container">
            <ul className="termCondition">
              <li>
                Cookie Notice
                <ul>
                  <li>
                    You should be aware that when you access or use our content,
                    products, and services, we may collect information from your
                    devices through the use of "cookies". Cookies perform
                    different functions, so the purpose of this policy is to
                    offer a clearer understanding of them.
                  </li>
                </ul>
              </li>
              <li>
                What are cookies?
                <ul>
                  <li>
                    Cookies are text files with small amounts of information
                    that are downloaded to your device when you visit a website.
                    The browser activates the cookies each time you visit our
                    web pages.
                  </li>
                </ul>
              </li>
              <li>
                Why do we use cookies?
                <ul>
                  <li>
                    Web pages use cookies to enhance their online experience and
                    ensure that relevant contents and functions are offered and
                    used in a more effective manner.
                  </li>
                  <li>
                    We use session cookies and persistent cookies to perform
                    various functions on our web pages. Session cookies are
                    temporarily downloaded to your device during the period you
                    remain on a particular website to enable you to navigate
                    from one page to another more effectively or for websites to
                    remember the preferences you have selected. Persistent
                    cookies can be used to help websites remember that you have
                    visited them previously or ensure that the ads you receive
                    online are focused on your interests.
                  </li>
                </ul>
              </li>
              <li>
                How do we use cookies?
                <ul>
                  <li>
                    When you visit our websites, cookies are stored on your
                    device in two ways. Cookies stored by Triunfador, as the
                    site operator, are referred to as "first-party" cookies. In
                    addition to Triunfador, third parties may also store cookies
                    on your device. These are referred to as "third-party"
                    cookies. Third-party cookies can be used, for example, to
                    collect information about browsing habits and offer you
                    customized content.
                  </li>
                  <li>
                    Although first-party and third-party cookies have many
                    different uses, we group our cookies into four main
                    categories. Below you will find more information about these
                    cookie categories, including their purpose and whether or
                    not they collect personal data.
                  </li>
                </ul>
              </li>
              <li>
                Strictly necessary cookies
                <ul>
                  <li>
                    These cookies are strictly necessary to allow you to
                    navigate our websites and use the features you request
                    during your betting experience (such as using the betting
                    sheet). They are used to provide you with the content,
                    products, and services you request as a user.
                  </li>
                  <li>
                    These cookies are essential for your device to download or
                    transmit information, so you can navigate our websites, use
                    their functions, and return to the pages you visited
                    previously.
                  </li>
                  <li>
                    These are some examples of the use of strictly necessary
                    cookies:
                  </li>
                  <li>
                    <ul>
                      <li>
                        Locate information on your device or screen so you can
                        view our websites and navigate through them.
                      </li>
                      <li>
                        Store the open session when using our website so you do
                        not have to log in on each visit. • Remember and update
                        your selections on the betting sheet during a live
                        session until the time of your bet.
                      </li>
                    </ul>
                    <li>
                      {" "}
                      These cookies do not remember your preferences or username
                      once your current session ends.
                    </li>
                    <li>Data collection</li>
                    <li>
                      Yes, some of these cookies collect personal data to
                      function. They may collect the following types of personal
                      data:
                    </li>
                    <li>
                      <ul>
                        <li>IP address</li>
                        <li>Location data</li>
                        <li>
                          Knowing if you have already logged in to our websites
                        </li>
                      </ul>
                    </li>
                    <li>Cookie validity</li>
                    <li>
                      These cookies are deleted when you close the web browser
                      (browser session)."
                    </li>
                  </li>
                </ul>
              </li>
              <li>
                Functional Cookies
                <ul>
                  <li>
                    These cookies are used to identify you when you return to
                    our websites and allow us to remember your selections and
                    offer improved functions. They also contribute to improving
                    the functionality of our websites by storing your
                    preferences. Functional cookies record information about
                    your selections (such as language preferences) and allow us
                    to offer more personalized functions.
                  </li>
                  <li>
                    Here are some examples of the use of functional cookies:
                  </li>
                  <li>
                    <ul>
                      <li>
                        Remember your preferences and settings, such as language
                        preferences, location, and advertisements, from your
                        last visit.
                      </li>
                      <li>Remember your selections on the betting sheet.</li>
                      <li>
                        Remember if you have used a specific product on the
                        website.
                      </li>
                    </ul>
                  </li>

                  <li>Data Collection</li>
                  <li>
                    Some of these cookies collect personal data to allow us to
                    offer the services mentioned above.
                  </li>
                  <li>Cookie Validity</li>
                  <li>
                    Functional cookies expire after a maximum of 2 years.
                    Functional cookies can be deleted from your browser history
                    at any time before their expiration date.
                  </li>
                </ul>
              </li>
              {/* <li>
                COOKIES
                <ul className="big_ul">
                  <li>
                    Information recorded on your computer
                    <ul>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus ut faucibus fusce integer vel. Id massa
                        ullamcorper sed dignissim vel. Pellentesque sit enim id
                        ornare consequat, faucibus egestas congue condimentum.
                        Non mi sit sed dui. Quis egestas sodales eget duis sit
                        mauris id quis. Convallis quis faucibus adipiscing
                        malesuada pulvinar dolor viverra. Fermentum, cursus
                        aliquam massa sed urna eu neque, ullamcorper. Tristique
                        enim viverra nulla sed sagittis consequat sem. Leo
                        facilisis orci, nisi, sem elementum maecenas at integer.
                        Vel lorem augue condimentum lorem adipiscing enim.
                      </li>
                      <li>
                        Donec aliquam volutpat orci volutpat urna, turpis. At
                        placerat praesent enim vitae vel. Vel, diam egestas
                        molestie lacus, eu. In sed mi, sed vestibulum
                        scelerisque pulvinar risus. Aliquam vestibulum arcu, est
                        sed vitae velit dictum ornare.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Collection of cookies
                    <ul>
                      <li>
                        Donec aliquam volutpat orci volutpat urna, turpis. At
                        placerat praesent enim vitae vel. Vel, diam egestas
                        molestie lacus, eu. In sed mi, sed vestibulum
                        scelerisque pulvinar risus. Aliquam vestibulum arcu, est
                        sed vitae velit dictum ornare.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Can cookies be rejected?
                    <ul>
                      <li>
                        Donec aliquam volutpat orci volutpat urna, turpis. At
                        placerat praesent enim vitae vel. Vel, diam egestas
                        molestie lacus, eu. In sed mi, sed vestibulum
                        scelerisque pulvinar risus. Aliquam vestibulum arcu, est
                        sed vitae velit dictum ornare.s.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}
              <li>
                Performance Cookies
                <ul>
                  <li>
                    Performance cookies are used to collect information about
                    the use of the website. They serve to obtain additional
                    statistics about the visitors to our websites. These
                    statistics are used for internal purposes, such as helping
                    us test and improve the performance of our websites or offer
                    a better user experience.
                  </li>
                  <li>
                    Here are some examples of the use of performance cookies:
                  </li>
                  <li>
                    <ul>
                      <li>Provide statistics on the use of our websites.</li>
                      <li>Check the effectiveness of our advertisements.</li>
                      <li>Correct user experience errors.</li>
                    </ul>
                  </li>
                  <li>
                    In addition, we use a number of tools to evaluate cookie
                    information at an aggregated level for analytical purposes
                    on our website (Google Analytics or Maximizer).
                  </li>
                  <li>Data Collection</li>
                  <li>
                    Although these cookies do not record your personal identity
                    when logging in, they do collect anonymous information and
                    data from your browser for the purposes mentioned above.
                    Sometimes, some of these cookies come from third parties,
                    such as Google Analytics. These third parties are only
                    allowed to use the cookies for the purposes described above
                    and are not authorized to personally identify you.
                  </li>
                  <li>Cookie Validity</li>
                  <li>
                    Performance cookies are valid for different periods of time.
                    Some are deleted as soon as you close the browser, while
                    others have an indefinite validity period. These latter can
                    be deleted at any time from the browser settings.
                  </li>
                </ul>
              </li>
              <li>
                Third-party Advertising and Tracking Cookies
                <ul>
                  <li>
                    These cookies are used to provide relevant Triunfador
                    advertisements based on your interests. They are also used
                    to limit the number of times you see an advertisement and to
                    measure the effectiveness of an advertising campaign. These
                    cookies track your visit to our website, the pages you have
                    visited, and the products you have selected. We will use
                    this information to guide our website and the advertising
                    displayed on it to your interests. It is also possible that
                    we will share this information with third parties for the
                    same purpose and to measure the effectiveness of advertising
                    campaigns.
                  </li>
                  <li>Third-party tracking cookies are also used for:</li>
                  <li>
                    <ul>
                      <li>
                        Collecting information about browsing habits to display
                        targeted web content and advertising;
                      </li>
                      <li>
                        Linking to social networks that will use your visit
                        information to display targeted advertising;
                      </li>
                      <li>
                        Providing information to advertisers about our users'
                        visits to advertising agencies so that they can offer
                        advertisements of interest;
                      </li>
                      <li>
                        Offering content and marketing communications tailored
                        to your interests based on your visit information;
                      </li>
                      <li>
                        Performing geographical targeting, allowing our
                        advertisements to be displayed on third-party websites
                        based on your location;
                      </li>
                      <li>
                        Redirecting you to parts of the website that you have
                        already visited through a redirect pixel, a small code
                        unit on our website stores a cookie in the user's
                        browser so that our advertisements are displayed or not
                        when you visit certain pages of the redirect network
                        (e.g., Google Display Network); and
                      </li>
                      <li>
                        Conducting search redirects so that our advertisements
                        are displayed on third-party websites based on your
                        previous web searches.
                      </li>
                      <li>Data Collection</li>
                      <li>
                        Third parties (such as advertising networks and external
                        service providers like web traffic analysis services)
                        may also use cookies on our website. These cookies are
                        likely to be performance, analysis, or redirect cookies.
                        Please note that no personal data is collected, and an
                        advertiser cannot associate the cookie with a particular
                        person's name, address, or other personal information.
                        These cookies are stored by third-party advertising
                        networks with our consent.
                      </li>
                      <li>Cookie Validity</li>
                      <li>
                        Third-party cookies are valid for different periods of
                        time. Some cookies expire up to 13 months after their
                        last update
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                How can you control the use of cookies?
                <ul>
                  <li>
                    You can modify your browser settings to block all cookies or
                    some of them. To do this, follow the instructions of the
                    respective web browser. Please note that if you block the
                    cookies from the websites, some or all of the functions of
                    these pages may stop working properly. To facilitate access
                    to references, we offer the following links with
                    instructions on how to remove cookies in some of the most
                    common web browsers.
                  </li>
                  <li>
                    <a href="bit.ly/40vxDNL" target="__blank">
                      Firefox
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="bit.ly/3YB1NNT" target="__blank">
                      Internet Explorer
                    </a>
                  </li>
                  <li>
                    <a href="bit.ly/3HGO1Cj" target="__blank">
                      Google Chrome
                    </a>
                  </li>
                  <li>
                    Mobile phone users may need to consult the device manual to
                    learn how to block cookies from the mobile browser.
                  </li>
                </ul>
              </li>
              <li>
                How can you manage your cookie preferences?
                <ul>
                  <li>
                    You can access the Preference Center where you can change
                    your cookie preferences. Please note that if you change and
                    withdraw your consent for a specific cookie class, it is
                    possible that it will remain on your device. However, it
                    will be deactivated and will not be collected by us. For
                    more information on how you can delete cookies from your
                    device, see "How can you control the use of cookies?"
                  </li>
                </ul>
              </li>
              <li>
                Do you use ad-blocker technologies?
                <ul>
                  <li>
                    If you use ad-blocker technologies, please note that some
                    ad-blockers may block our ability to inform you about your
                    cookie settings or may prevent you from opting out of all
                    the incredible experience we offer you as a user. If you
                    want to enjoy a better experience, deactivate the ad-blocker
                    technology and review your cookie settings through our
                    cookie notice.
                  </li>
                </ul>
              </li>
              <li>
                Contact
                <ul>
                  <li>
                    If you have any questions about the use of cookies, contact
                    us through: soporte@triunfador.net.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default CookiesPolicySection;
