import React from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";

const Banner = () => {
  const navigate = useNavigate();
  const loginPage = () => {
    navigate("/login");
  };
  const userLogin = localStorage.getItem("userToken");
  return (
    <>
      {userLogin ? (
        ""
      ) : (
        <div className="ad_section">
          <div className="row ad_content">
            <div className="col-md-12">
              <img
                src="/images/bonus_ad.png"
                className="img-fluid"
                onClick={loginPage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
