import React, { useEffect } from "react";
import { useState } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  onUpdateProfile,
  onUpdateProfileImage,
  onUserEditProfileOther,
  onUserRemoveProfile,
  onUserUpdateProfileOther,
} from "../../../redux/homeAction";
import Banner from "../../Banner/Banner";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";

const EditProfile = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(0);
  const [value, setValue] = useState({});
  const [imageFile, setImageFile] = useState();
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);

  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));
  const getOnMyProfile = useSelector((state) => state.home.getOnMyProfile);
  const getUserEditProfileOther = useSelector(
    (state) => state.home.getUserEditProfileOther
  );

  useEffect(() => {
    setValue(getOnMyProfile);
  }, [getOnMyProfile]);

  const onInputHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const updateProfile = (e) => {
    e.preventDefault();
    const data = {
      user_id: userDeatils && userDeatils.user_id,
      username: value.username,
      full_name: value.full_name,
      gender: value.gender,
    };
    if (!value.username) {
      toast.error("please enter a user name");
    } else if (!value.full_name) {
      toast.error("please enter a full name");
    } else if (!value.gender) {
      toast.error("please selete gender");
    } else {
      dispatch(onUpdateProfile(data));
    }
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    const base64 = await ConvertBase64(file);
    setImage(base64);
    setImageFile(base64.slice(23, base64.length));
    if (file) {
      const formData = new FormData();
      formData.append("user_id", userDeatils && userDeatils.user_id);
      formData.append("file", file);
      dispatch(onUpdateProfileImage(formData));
    }
  };

  const ConvertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  };

  const userOtherProfileUpdate = (e) => {
    setLoader(true);
    e.preventDefault();
    const data = {
      user_id: userDeatils && userDeatils.user_id,
      country: value.country,
      marital_status: value.marital_status,
      profession: value.profession,
      whats_you_job: value.whats_you_job,
      education_level: value.education_level,
      school_name: value.school_name,
      university_name: value.university_name,
      spoken_language: value.spoken_language,
      countries_visited: value.countries_visited,
      favorite_sport: value.favorite_sport,
      facebook_account: value.facebook_account,
      twitter_account: value.twitter_account,
      favorite_movies: value.favorite_movies,
      favorite_song: value.favorite_song,
      favorite_book: value.favorite_book,
      favorite_singers: value.favorite_singers,
      favorite_historical: value.favorite_historical,
      favorite_soccer_team: value.favorite_soccer_team,
      favorite_nba_team: value.favorite_nba_team,
      perferred_mlb_team: value.perferred_mlb_team,
      perferred_nfl_team: value.perferred_nfl_team,
      football_favorites_goalkeeper: value.football_favorites_goalkeeper,
      football_favorites_center_back: value.football_favorites_center_back,
      football_favorites_side: value.football_favorites_side,
      football_favorites_flyer: value.football_favorites_flyer,
      football_favorites_striker: value.football_favorites_striker,
      football_favorites_player: value.football_favorites_player,
      football_favorites_midfielder: value.football_favorites_midfielder,
    };
    dispatch(onUserUpdateProfileOther(data, setLoader));
  };

  useEffect(() => {
    setLoader(true);
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onUserEditProfileOther(data, setLoader));
  }, []);

  const classSend = "position-relative";

  const deleteUserImage = () => {
    setLoader(true);
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onUserRemoveProfile(data, setLoader));
  };

  return (
    <>
      <Navbar classSend={classSend} />

      <section id="Tabs_section">
        <div className="container">
          <div className="tabs_container">
            <h1 className="text-center mt-3">
              Edit <span className="color_yellow">Profile</span>
            </h1>
            <div className="bats_nav">
              <ul id="edit_tabs">
                <li
                  onClick={() => setShow(0)}
                  className={`${show === 0 ? "active_bat" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <a>ESSENTIAL</a>
                  </div>
                </li>
                <li
                  onClick={() => {
                    if (getUserEditProfileOther) {
                      setValue(getUserEditProfileOther);
                    }
                    setShow(1);
                  }}
                  className={`${show === 1 ? "active_bat" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <a>OTHERS</a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="bets_section">
              {show === 0 && (
                <div className="edit_section">
                  <div className="Edit_essential">
                    {loader ? (
                      <div className="text-center">
                        <Loader />
                      </div>
                    ) : (
                      <div className="edit_profile_photo">
                        <div className="profile_photo">
                          <div className="profile_img">
                            <input
                              type="file"
                              name="file"
                              id="file"
                              className="d-none"
                              onChange={onFileChange}
                            />
                            {imageFile ? (
                              <img src={image} className="profile_photo" />
                            ) : (
                              <img
                                src={`${
                                  getOnMyProfile?.image
                                    ? getOnMyProfile && getOnMyProfile.image
                                    : "./images/Profile_photo.png"
                                }`}
                                className="profile_photo"
                              />
                            )}
                            <label htmlFor="file">
                              <div className="edit_profile_photo_icon">
                                <i className="fas fa-pencil-alt"></i>
                              </div>
                            </label>
                          </div>
                        </div>

                        {getOnMyProfile && getOnMyProfile.image ? (
                          <div
                            className="delete_profile_photo"
                            onClick={deleteUserImage}
                          >
                            <i className="fa fa-trash-o"></i>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <div className="preference_container">
                      <div className="form_container">
                        <form onSubmit={updateProfile}>
                          <div className="row g-3">
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="E - mail"
                                name="email"
                                onChange={(e) => onInputHandler(e)}
                                value={value?.email}
                                disabled
                              />
                            </div>

                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="User Name"
                                name="username"
                                onChange={(e) => onInputHandler(e)}
                                value={value?.username}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="mail"
                                className="input"
                                placeholder="Full Name"
                                name="full_name"
                                onChange={(e) => onInputHandler(e)}
                                value={value?.full_name}
                              />
                            </div>
                            <div className="col-md-6">
                              <div className="gender_input position-relative">
                                <input
                                  type="mail"
                                  id="gender"
                                  className="input"
                                  placeholder="Gender"
                                  disabled
                                />
                                <div className="labels">
                                  <label className="control control--radio control--checkbox">
                                    <input
                                      type="radio"
                                      name="gender"
                                      onChange={(e) => onInputHandler(e)}
                                      defaultValue="male"
                                    />
                                    <span>Male</span>
                                    <div className="control__indicator" />
                                  </label>
                                  <label className="control control--radio control--checkbox">
                                    <input
                                      type="radio"
                                      name="gender"
                                      onChange={(e) => onInputHandler(e)}
                                      defaultValue="female"
                                    />
                                    <span>Female</span>
                                    <div className="control__indicator" />
                                  </label>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <select
                                id="country"
                                className="input"
                                name="country"
                                onChange={(e) => onInputHandler(e)}
                              >
                                <option>Country</option>
                                <option defaultValue="India">India</option>
                                <option defaultValue="Pakisthan">
                                  Pakisthan
                                </option>
                                <option defaultValue="China">China</option>
                                <option defaultValue="Japan">Japan</option>
                              </select>
                            </div> */}
                          </div>
                          <input
                            type="submit"
                            className="btn post_btn btnFill smallbtn mt-4 mb-4"
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {show === 1 && (
                <div className="edit_section">
                  <div className="Edit_others">
                    <div className="preference_container">
                      <div className="form_container">
                        <form>
                          <div className="row g-3">
                            <p className="boldP">Others</p>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Country"
                                name="country"
                                onChange={(e) => onInputHandler(e)}
                                value={value.country}
                              />
                            </div>
                            <div className="col-md-6">
                              <select
                                id="country"
                                className="input"
                                name="marital_status"
                                onChange={(e) => onInputHandler(e)}
                              >
                                <option>Marital status</option>
                                <option defaultValue="single">Single</option>
                                <option defaultValue="married">married</option>
                                <option defaultValue="unmarried">
                                  unmarried
                                </option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Profession"
                                name="profession"
                                onChange={(e) => onInputHandler(e)}
                                value={value.profession}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="What's your job"
                                name="whats_you_job"
                                onChange={(e) => onInputHandler(e)}
                                value={value.whats_you_job}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Education level"
                                name="education_level"
                                onChange={(e) => onInputHandler(e)}
                                value={value.education_level}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="What's your university name"
                                name="university_name"
                                onChange={(e) => onInputHandler(e)}
                                value={value.university_name}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Name of your school"
                                name="school_name"
                                onChange={(e) => onInputHandler(e)}
                                value={value.school_name}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Spoken languages"
                                name="spoken_language"
                                onChange={(e) => onInputHandler(e)}
                                value={value.spoken_language}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Countries visited"
                                name="countries_visited"
                                onChange={(e) => onInputHandler(e)}
                                value={value.countries_visited}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Favorite sport"
                                name="favorite_sport"
                                onChange={(e) => onInputHandler(e)}
                                value={value.favorite_sport}
                              />
                            </div>
                            <p className="boldP">Social Networks</p>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Link to facebook account"
                                name="facebook_account"
                                onChange={(e) => onInputHandler(e)}
                                value={value.facebook_account}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Link to the twitter account"
                                name="twitter_account"
                                onChange={(e) => onInputHandler(e)}
                                value={value.twitter_account}
                              />
                            </div>
                            <p className="boldP">Favorites</p>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Top 3 Favorite Movies"
                                name="favorite_movies"
                                onChange={(e) => onInputHandler(e)}
                                value={value.favorite_movies}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Top 3 Favorite Songs"
                                name="favorite_song"
                                onChange={(e) => onInputHandler(e)}
                                value={value.favorite_song}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Top 3 Favorite books"
                                name="favorite_book"
                                onChange={(e) => onInputHandler(e)}
                                value={value.favorite_book}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Top 3 Bands / Favorite Singers"
                                name="favorite_singers"
                                onChange={(e) => onInputHandler(e)}
                                value={value.favorite_singers}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Favourite historical character	"
                                name="favorite_historical"
                                onChange={(e) => onInputHandler(e)}
                                value={value.favorite_historical}
                              />
                            </div>
                            <p className="boldP">Sports</p>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Favorite soccer team	"
                                name="favorite_soccer_team"
                                onChange={(e) => onInputHandler(e)}
                                value={value.favorite_soccer_team}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Favorite NBA team"
                                name="favorite_nba_team"
                                onChange={(e) => onInputHandler(e)}
                                value={value.favorite_nba_team}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Preferred MLB Team"
                                name="perferred_mlb_team"
                                onChange={(e) => onInputHandler(e)}
                                value={value.perferred_mlb_team}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Preferred NFL Team"
                                name="perferred_nfl_team"
                                onChange={(e) => onInputHandler(e)}
                                value={value.perferred_nfl_team}
                              />
                            </div>
                            <p className="boldP">Football Favorites</p>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="What is your favorite goalkeeper?"
                                name="football_favorites_goalkeeper"
                                onChange={(e) => onInputHandler(e)}
                                value={value.football_favorites_goalkeeper}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Who is your favorite center back?	
                                
                      "
                                name="football_favorites_center_back"
                                onChange={(e) => onInputHandler(e)}
                                value={value.football_favorites_center_back}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="What is your favorite side?"
                                name="football_favorites_side"
                                onChange={(e) => onInputHandler(e)}
                                value={value.football_favorites_side}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="What is your favorite recovery flyer?"
                                name="football_favorites_flyer"
                                onChange={(e) => onInputHandler(e)}
                                value={value.football_favorites_flyer}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="What is your favorite striker?"
                                name="football_favorites_striker"
                                onChange={(e) => onInputHandler(e)}
                                value={value.football_favorites_striker}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="Who do you consider the most overrated player?"
                                name="football_favorites_player"
                                onChange={(e) => onInputHandler(e)}
                                value={value.football_favorites_player}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="input"
                                placeholder="What is your favorite midfielder?"
                                name="football_favorites_midfielder"
                                onChange={(e) => onInputHandler(e)}
                                value={value.football_favorites_midfielder}
                              />
                            </div>
                          </div>
                          {loader ? (
                            <Loader />
                          ) : (
                            <input
                              type="submit"
                              className="btn post_btn btnFill smallbtn"
                              onClick={userOtherProfileUpdate}
                            />
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Banner />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default EditProfile;
