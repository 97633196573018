export const ONREGISTER = "ONREGISTER";

export const ONLOGIN = "ONLOGIN";

export const ONRESETPASSWORD = "ONRESETPASSWORD";

export const ONCHANGEPASSWORD = "ONCHANGEPASSWORD";

export const ONUPDATEPROFILE = "ONUPDATEPROFILE";

export const ONUPDATEPROFILEIMAGE = "ONUPDATEPROFILEIMAGE";

export const ONDELETENOTIFICATION = "ONDELETENOTIFICATION";

export const ONUSERFRIENDLIST = "ONUSERFRIENDLIST";

export const ONUSERNOTIFICATION = "ONUSERNOTIFICATION";

export const ONSEENNOTIFICATION = "ONSEENNOTIFICATION";

export const ONCOUNTNOTIFICATION = "ONCOUNTNOTIFICATION";

export const ONMYPROFILE = "ONMYPROFILE";

export const ONEDDITPROFILE = "ONEDDITPROFILE";

export const ONGETMATCHLIST = "ONGETMATCHLIST";

export const ONGETCOUNTRIESLEAGUES = "ONGETCOUNTRIESLEAGUES";

export const ONGETCOUNTRIESLIST = "ONGETCOUNTRIESLIST";

export const ONGETFETCHSPORTS = "ONGETFETCHSPORTS";

export const ONUSERSETTINGPERFERENCE = "ONUSERSETTINGPERFERENCE";

export const ONUSERUPDATESETTINGPERFERENCE = "ONUSERUPDATESETTINGPERFERENCE";

export const ONUSERAWARDS = "ONUSERAWARDS";

export const ONUSERAWARDSDETAILS = "ONUSERAWARDSDETAILS";

export const ONUSERACADEMY = "ONUSERACADEMY";

export const ONUSERACADEMYDETAILS = "ONUSERACADEMYDETAILS";

export const ONUSERPOSTMESSAGE = "ONUSERPOSTMESSAGE";

export const ONFECTHUSERPOSTMESSAGE = "ONFECTHUSERPOSTMESSAGE";

export const ONUSERPOSTMESSAGEDELETE = "ONUSERPOSTMESSAGEDELETE";

export const ONUSERMESSAGE = "ONUSERMESSAGE";

export const ONUSERRELOADBALANCE = "ONUSERRELOADBALANCE";

export const ONUSERREDEEM = "ONUSERREDEEM";

export const ONUSERMYTRANSACTION = "ONUSERMYTRANSACTION";

export const ONUSERBETSSPORTS = "ONUSERBETSSPORTS";

export const ONUSERREQUESTWITHDRAW = "ONUSERREQUESTWITHDRAW";

export const ONUSERMYBETS = "ONUSERMYBETS";

export const ONUSERAWARDSSEARCH = "ONUSERAWARDSSEARCH";

export const ONUSERUPDATEPROFILEOTHER = "ONUSERUPDATEPROFILEOTHER";

export const ONUSEREDITPROFILEOTHER = "ONUSEREDITPROFILEOTHER";

export const ONUSERREMOVEPROFILE = "ONUSERREMOVEPROFILE";

export const ONUSERAWARDSAPI = "ONUSERAWARDSAPI";

export const ONUSERCONTACTUS = "ONUSERCONTACTUS";

export const ONFACEBOOKLOGIN = "ONFACEBOOKLOGIN";

export const ONUSERSUBMISSION = "ONUSERSUBMISSION";

export const ONGETFAQ = "ONGETFAQ";

export const ONFAQCATEGORIES = "ONFAQCATEGORIES";
