import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onResetPassword } from "../../redux/homeAction";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [captcha, setCaptcha] = useState("");

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const generateCaptchaHandler = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return setCaptcha(result);
  };

  useEffect(() => {
    generateCaptchaHandler(6);
  }, []);

  const forgetPassword = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("please enter a email");
    } else if (!emailRegex.test(email)) {
      toast.error("please enter correct gmail");
    } else if (!code) {
      toast.error("Please enter captcha code");
    } else if (code !== captcha) {
      toast.error("Captcha Code Not Correct");
    } else {
      const data = {
        email: email,
      };
      dispatch(onResetPassword(data));
    }
  };

  return (
    <>
      <section id="login">
        <div className="login_content">
          <div className="row gy-5">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <div className="login-container text-center">
                <h1>
                  Forgot Your <span className="color_yellow">Password</span>
                </h1>
                <p className="text_normal">
                  To recover your password please fill in the field below with
                  the same email address you registered in your account. You
                  will receive the recovery details via email. Please check your
                  SPAM folder.
                </p>
                <form onSubmit={forgetPassword}>
                  <div className="row gy-3">
                    <div className="col-md-12">
                      <div className="form-group floating position-relative">
                        <input
                          type="text"
                          className="form-control floating input"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <label htmlFor="usr">E mail</label>
                        <div className="input_icon">
                          <i className="fa-regular fa-envelope" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-end">
                      <div className="code position-relative">
                        <div className="capcha">{captcha}</div>
                        <i
                          className="fa-solid fa-arrows-rotate refresh_icon"
                          onClick={() => generateCaptchaHandler(6)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group floating position-relative">
                        <input
                          type="text"
                          className="form-control floating input"
                          name="quizCode"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                        <label htmlFor="usr">Enter The Code</label>
                        <div className="input_icon">
                          <img src="./images/code.png" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <input type="submit" className="input submit_input" />
                    </div>
                  </div>
                </form>
                <Link to="/login" className="text_normal">
                  Have an account?
                  <span className="color_yellow">Log in</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <div className="login_right">
                <img src="./images/login_right.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPassword;
