import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onLogin } from "../../redux/homeAction";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";

const Login = ({ facebookLogin, loader, setLoader, googleLogin }) => {
  const [value, setValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inputHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const generateCaptchaHandler = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return setCaptcha(result);
  };

  useEffect(() => {
    generateCaptchaHandler(6);
  }, []);

  // var strongRegex =
  //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/;

  const onSubmitLogin = (e) => {
    e.preventDefault();
    if (!value.email) {
      toast.error("please enter a email");
    } else if (!emailRegex.test(value.email)) {
      toast.error("please enter a correct gmail");
    } else if (!value.password) {
      toast.error("please enter a password");
    } else if (value.password.length < 4) {
      toast.error("The password must be at least 4 characters");
    } else if (!value.quizCode) {
      toast.error("Please enter captcha code");
    } else if (value.quizCode !== captcha) {
      toast.error("Captcha Code Not Correct");
    }
    //  else if (!strongRegex.test(value.password)) {
    //   toast.error(
    //     "password should contain numeric value, special character, upper case letter and lower case letter"
    //   );
    // }
    else {
      setLoader(true);
      const data = {
        email: value.email,
        password: value.password,
      };
      dispatch(onLogin(data, navigate, setLoader));
    }
  };

  return (
    <>
      <section className="Login_body" id="login">
        <div className="container login_content">
          <div className="row gy-5">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <div className="login-container text-center">
                <h1>
                  Welcome to <span className="color_yellow">Triunfador</span>
                </h1>
                <p className="text_normal">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  in volutpat turpis suscipit etiam. Etiam malesuada amet enim.
                </p>
                <div className="row w-100 gy-3">
                  <div className="col-md-6">
                    <button
                      className="btn post_btn btnFill"
                      onClick={facebookLogin}
                    >
                      <div className="btnfilltext">
                        <img src="./images/fb.png" />
                        Login with Facebook
                      </div>
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      className="btn post_btn btnFill"
                      onClick={googleLogin}
                    >
                      <div className="btnfilltext">
                        <img src="./images/google.png" className="img-fluid" />
                        Login with Google
                      </div>
                    </button>
                  </div>
                </div>
                <form onSubmit={onSubmitLogin}>
                  <div className="row gy-3">
                    <div className="col-md-12">
                      <div className="form-group floating">
                        <input
                          type="email"
                          className="form-control floating input"
                          name="email"
                          value={value.email}
                          onChange={(e) => inputHandler(e)}
                          required
                        />
                        <label htmlFor="usr">E-mail address</label>
                        <div className="input_icon">
                          <i className="fa-regular fa-envelope" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group floating position-relative mb-3">
                        <input
                          type={`${showPassword ? "text" : "password"}`}
                          className="form-control floating input"
                          name="password"
                          value={value.password}
                          onChange={(e) => inputHandler(e)}
                          required
                        />
                        <label htmlFor="usr">Password</label>
                        <div className="input_icon">
                          {showPassword ? (
                            <i
                              className="fa-regular fa-eye"
                              onClick={() => setShowPassword(false)}
                            />
                          ) : (
                            <i
                              className="fa fa-eye-slash"
                              onClick={() => setShowPassword(true)}
                            ></i>
                          )}
                        </div>
                        <Link className="forgetpasword" to="/forget-password">
                          Forgot Password ?
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-end">
                      <div className="code position-relative">
                        <div className="capcha">{captcha}</div>
                        <i
                          className="fa-solid fa-arrows-rotate refresh_icon"
                          onClick={() => generateCaptchaHandler(6)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group floating position-relative">
                        <input
                          type="text"
                          className="form-control floating input"
                          name="quizCode"
                          value={value.quizCode}
                          onChange={(e) => inputHandler(e)}
                        />
                        <label htmlFor="usr">Enter The Code</label>
                        <div className="input_icon">
                          <img src="./images/code.png" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      {loader ? (
                        <Loader />
                      ) : (
                        <input type="submit" className="input submit_input" />
                      )}
                    </div>
                  </div>
                </form>
                <Link to="/register" className="text_normal">
                  Not Registered yet ?
                  <span className="color_yellow">Create an Account</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <div className="login_right">
                <img src="./images/login_right.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
