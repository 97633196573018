import React from "react";

const RestrictedModel = () => {
  return (
    <>
      <div
        className="modal fade"
        id="Restricted"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="modal-body">
              <h1>Restricted</h1>
              <p className="normal_p">
                Our Software detects that you may be accessing the Betfair
                website from a country that Betfair does not accept bets from or
                the traffic from your network was detected as being unusual. If
                you believe that this detection has occurred in error, please
                Contact us for further assistance.
              </p>
              <hr className="manully_hr" />
              <p className="boldP">Access denied</p>
              <p className="normal_p">
                You cannot access promotions.betfair.com. Refresh the page or
                contact the site owner to request access.
              </p>
              <ul className="model_hr">
                <li>Ray ID: 742381677a122e7d</li>
                <li>Timestamp: 2022-08-29 07:17:10 UTC</li>
                <li>Your IP address: 106.212.152.141</li>
                <li>
                  Requested URL:
                  promotions.betfair.com/prs/latam-ready-exchange-excr20-new?utm_medium=Partnerships&amp;utm_source=18070&amp;utm
                  _campaign=127029&amp;utm_content=4660382&amp;utm_ad=369307&amp;clkID=40256_EAB121593F104D20ABB876E70CB2AB99&amp;rfr=40256&amp;ttp
                  =111&amp;pid=2626243&amp;bid=8300
                </li>
                <li>Error reference number: 1020</li>
                <li>Server ID: FL_202F111</li>
                <li>
                  User-Agent: Mozilla/5.0 (Windows NT 10.0; Win64; x64)
                  AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0
                  Safari/537.36
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RestrictedModel;
