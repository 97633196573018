import React from "react";
import Footer from "../../componets/Footer/Footer";
import Navbar from "../../componets/Navbar/Navbar";
import TermAndConditionSection from "./TermAndConditionSection";

const TermAndCondition = () => {
  const classSend = "position-relative";

  return (
    <>
      <Navbar classSend={classSend} />
      <TermAndConditionSection />
      <Footer />
    </>
  );
};

export default TermAndCondition;
