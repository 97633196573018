import {
  ONCHANGEPASSWORD,
  ONCOUNTNOTIFICATION,
  ONDELETENOTIFICATION,
  ONEDDITPROFILE,
  ONFACEBOOKLOGIN,
  ONFAQCATEGORIES,
  ONFECTHUSERPOSTMESSAGE,
  ONGETCOUNTRIESLEAGUES,
  ONGETCOUNTRIESLIST,
  ONGETFAQ,
  ONGETFETCHSPORTS,
  ONGETMATCHLIST,
  ONLOGIN,
  ONMYPROFILE,
  ONREGISTER,
  ONRESETPASSWORD,
  ONSEENNOTIFICATION,
  ONUPDATEPROFILE,
  ONUPDATEPROFILEIMAGE,
  ONUSERACADEMY,
  ONUSERACADEMYDETAILS,
  ONUSERAWARDS,
  ONUSERAWARDSAPI,
  ONUSERAWARDSDETAILS,
  ONUSERAWARDSSEARCH,
  ONUSERBETSSPORTS,
  ONUSERCONTACTUS,
  ONUSEREDITPROFILEOTHER,
  ONUSERFRIENDLIST,
  ONUSERMESSAGE,
  ONUSERMYBETS,
  ONUSERMYTRANSACTION,
  ONUSERNOTIFICATION,
  ONUSERPOSTMESSAGE,
  ONUSERPOSTMESSAGEDELETE,
  ONUSERREDEEM,
  ONUSERRELOADBALANCE,
  ONUSERREMOVEPROFILE,
  ONUSERREQUESTWITHDRAW,
  ONUSERSETTINGPERFERENCE,
  ONUSERSUBMISSION,
  ONUSERUPDATEPROFILEOTHER,
  ONUSERUPDATEPROFILEUPDATE,
  ONUSERUPDATESETTINGPERFERENCE,
} from "./type";

const initialState = {};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ONREGISTER:
      return {
        ...state,
        getOnRegister: action.payload,
      };
    case ONLOGIN:
      return {
        ...state,
        getOnLogin: action.payload,
      };
    case ONRESETPASSWORD:
      return {
        ...state,
        getOnResetPassword: action.payload,
      };
    case ONCHANGEPASSWORD:
      return {
        ...state,
        getOnChangePassword: action.payload,
      };
    case ONUPDATEPROFILE:
      return {
        ...state,
        getOnUpdateProfile: action.payload,
      };
    case ONUPDATEPROFILEIMAGE:
      return {
        ...state,
        getOnUpdateProfileImage: action.payload,
      };
    case ONDELETENOTIFICATION:
      return {
        ...state,
        getOnDeleteNotification: action.payload,
      };
    case ONUSERFRIENDLIST:
      return {
        ...state,
        getOnUserFriendList: action.payload,
      };
    case ONUSERNOTIFICATION:
      return {
        ...state,
        getOnUserNotification: action.payload,
      };
    case ONSEENNOTIFICATION:
      return {
        ...state,
        getOnSeenNotification: action.payload,
      };
    case ONCOUNTNOTIFICATION:
      return {
        ...state,
        getOnCountNotification: action.payload,
      };
    case ONMYPROFILE:
      return {
        ...state,
        getOnMyProfile: action.payload,
      };
    case ONEDDITPROFILE:
      return {
        ...state,
        getOnEditProfile: action.payload,
      };
    case ONGETMATCHLIST:
      return {
        ...state,
        getOnGetMatchList: action.payload,
      };
    case ONGETCOUNTRIESLEAGUES:
      return {
        ...state,
        getOnGetCountriesLeagues: action.payload,
      };
    case ONGETCOUNTRIESLIST:
      return {
        ...state,
        getOnGetCountriesList: action.payload,
      };
    case ONGETFETCHSPORTS:
      return {
        ...state,
        getOnGetFetchSports: action.payload,
      };
    case ONUSERSETTINGPERFERENCE:
      return {
        ...state,
        getUserSettingPreference: action.payload,
      };
    case ONUSERUPDATESETTINGPERFERENCE:
      return {
        ...state,
        getUserUpdateSettingPreference: action.payload,
      };
    case ONUSERAWARDS:
      return {
        ...state,
        getUserAwards: action.payload,
      };
    case ONUSERAWARDSDETAILS:
      return {
        ...state,
        getUserAwardsDetails: action.payload,
      };
    case ONUSERACADEMY:
      return {
        ...state,
        getUserAcademy: action.payload,
      };
    case ONUSERACADEMYDETAILS:
      return {
        ...state,
        getUserAcademyDetails: action.payload,
      };
    case ONUSERPOSTMESSAGE:
      return {
        ...state,
        getUserPostMessage: action.payload,
      };
    case ONFECTHUSERPOSTMESSAGE:
      return {
        ...state,
        getFecthUserPostMessage: action.payload,
      };
    case ONUSERPOSTMESSAGEDELETE:
      return {
        ...state,
        getUserPostMessageDelete: action.payload,
      };
    case ONUSERMESSAGE:
      return {
        ...state,
        getUserBalance: action.payload,
      };
    case ONUSERRELOADBALANCE:
      return {
        ...state,
        getUserReloadBalance: action.payload,
      };
    case ONUSERREDEEM:
      return {
        ...state,
        getUserRedeem: action.payload,
      };
    case ONUSERMYTRANSACTION:
      return {
        ...state,
        getUserMyTransaction: action.payload,
      };
    case ONUSERBETSSPORTS:
      return {
        ...state,
        getUserBetsSports: action.payload,
      };
    case ONUSERREQUESTWITHDRAW:
      return {
        ...state,
        getUserRequestWithdraw: action.payload,
      };
    case ONUSERMYBETS:
      return {
        ...state,
        getUserMyBets: action.payload,
      };
    case ONUSERAWARDSSEARCH:
      return {
        ...state,
        getUserAwardsSearch: action.payload,
      };
    case ONUSERUPDATEPROFILEOTHER:
      return {
        ...state,
        getUserUpdateProfileOther: action.payload,
      };
    case ONUSEREDITPROFILEOTHER:
      return {
        ...state,
        getUserEditProfileOther: action.payload,
      };
    case ONUSERREMOVEPROFILE:
      return {
        ...state,
        getUserRemoveProfile: action.payload,
      };
    case ONUSERAWARDSAPI:
      return {
        ...state,
        getUserAwardsApi: action.payload,
      };
    case ONUSERCONTACTUS:
      return {
        ...state,
        getUserContactUs: action.payload,
      };
    case ONFACEBOOKLOGIN:
      return {
        ...state,
        getFaceBookLogin: action.payload,
      };
    case ONFACEBOOKLOGIN:
      return {
        ...state,
        getFaceBookLogin: action.payload,
      };
    case ONUSERSUBMISSION:
      return {
        ...state,
        getUserSubmission: action.payload,
      };
    case ONFAQCATEGORIES:
      return {
        ...state,
        getFaqCategories: action.payload,
      };
    case ONGETFAQ:
      return {
        ...state,
        getGetFaq: action.payload,
      };
    default:
      return state;
  }
};

export default homeReducer;
