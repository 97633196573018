import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onFaqCategories, onGetFaq } from "../../redux/homeAction";
import Loader from "../../componets/loader/Loader";

const FAQSection = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [faqId, setFaqId] = useState(null);
  const [faqDetails, setFaqDetails] = useState(null);
  const getFaqCategories = useSelector((state) => state.home.getFaqCategories);
  const getGetFaq = useSelector((state) => state.home.getGetFaq);

  useEffect(() => {
    setLoader(true);
    dispatch(onFaqCategories(setLoader));
  }, []);

  useEffect(() => {
    if (getFaqCategories) {
      setFaqId(getFaqCategories && getFaqCategories[0]?.id);
      const data = {
        category_id: getFaqCategories && getFaqCategories[0]?.id,
      };
      setLoader(true);
      dispatch(onGetFaq(data, setLoader));
    }
  }, [getFaqCategories]);

  useEffect(() => {
    if (getGetFaq) {
      setFaqDetails(getGetFaq && getGetFaq[0]?.id);
    }
  }, [getGetFaq]);

  const fetchFaqDetails = (id) => {
    const data = {
      category_id: id,
    };
    setLoader(true);
    dispatch(onGetFaq(data, setLoader));
  };

  return (
    <>
      <section className="faq">
        <div className="container">
          <h1 className="h1_60">FAQ</h1>
          <div className="faq-section">
            <div className="d-flex align-items-start">
              <div
                className="faq-items nav flex-column nav-pills me-lg-5"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                {getFaqCategories &&
                  getFaqCategories.map((data, i) => (
                    <button
                      className={`nav-link ${
                        parseFloat(data.id) === parseFloat(faqId)
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-general-tab"
                      data-bs-toggle="pill"
                      data-bs-target="v-pills-general"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-general"
                      aria-selected="true"
                      key={data.id}
                      onClick={() => fetchFaqDetails(data.id)}
                    >
                      {data.name}
                    </button>
                  ))}
              </div>
              <div className="faq-content tab-content" id="v-pills-tabContent">
                {loader ? (
                  <div className="mt-4 mb-4 text-center">
                    <Loader />
                  </div>
                ) : (
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-general"
                    role="tabpanel"
                    aria-labelledby="v-pills-general-tab"
                  >
                    <div className="faq_accordios">
                      <div className="accordion" id="accordionExample">
                        {getGetFaq &&
                          getGetFaq.map((data, i) => (
                            <div className="accordion-item" key={data.id}>
                              <h2
                                className="accordion-header"
                                id="headingSport"
                              >
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse${data.id}`}
                                  aria-expanded="true"
                                  aria-controls="collapse"
                                  onClick={() => setFaqDetails(data.id)}
                                >
                                  {data.question}
                                </button>
                              </h2>
                              <div
                                id={`collapse${data.id}`}
                                className={`accordion-collapse collapse ${
                                  parseFloat(data.id) === parseFloat(faqDetails)
                                    ? "show"
                                    : ""
                                }`}
                                aria-labelledby="headingSport"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  {data.answer}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="ad_section">
            <div className="row ad_content">
              <div className="col-md-12">
                <img src="./images/bonus_ad.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQSection;
