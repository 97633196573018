import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
  onChangePassword,
  onUserSettingPreference,
  onUserUpdateSettingPreference,
} from "../../../redux/homeAction";
import Banner from "../../Banner/Banner";
import Footer from "../../Footer/Footer";
import Loader from "../../loader/Loader";
import Navbar from "../../Navbar/Navbar";
import Profile from "../Profile/Profile";

const Setting = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(0);
  const [value, setValue] = useState({});
  const [preferenceId, setPreferenceId] = useState([]);
  const [loader, setLoader] = useState(false);
  const [addClass, setAddClass] = useState(false);
  const getUserSettingPreference = useSelector(
    (state) => state.home.getUserSettingPreference
  );
  const getUserUpdateSettingPreference = useSelector(
    (state) => state.home.getUserUpdateSettingPreference
  );
  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onUserSettingPreference(data));
  }, [getUserUpdateSettingPreference]);

  const inputHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const changePassword = (e) => {
    e.preventDefault();
    if (!value.oldPassword) {
      toast.error("please enter previous password");
    } else if (!value.newPassword) {
      toast.error("please enter new password");
    } else if (!value.confirmPassword) {
      toast.error("please enter repeat password");
    } else if (value.newPassword !== value.confirmPassword) {
      toast.error("Password and repeat password should be match");
    } else {
      const data = {
        user_id: userDeatils && userDeatils.user_id,
        password: value.newPassword,
        oldpassword: value.oldPassword,
      };
      dispatch(onChangePassword(data));
    }
  };
  const classSend = "position-relative";

  const checkEvnetHandler = (id) => {
    let updateList = [...preferenceId];
    if (id.event.target.checked) {
      updateList = [...preferenceId, id.id];
    } else {
      updateList.splice(preferenceId.indexOf(id.id), 1);
    }
    setPreferenceId(updateList);
  };

  const changeUserSetting = () => {
    setLoader(true);
    const data = {
      preferences_sub_category_id:
        preferenceId.length === 0 ? "0," : preferenceId.join(),
      user_id: userDeatils && userDeatils.user_id,
    };

    dispatch(onUserUpdateSettingPreference(data, setLoader));
  };

  return (
    <>
      <Navbar classSend={classSend} />
      <Profile />
      <section id="Tabs_section">
        <div className="container">
          <div className="tabs_container">
            <div className="tabs_nav">
              <ul id="tabs">
                {/* <li>
                  <div>
                    <NavLink to="/dashboard-profile">Wall</NavLink>
                  </div>
                </li> */}
                <li>
                  <div>
                    <NavLink to="/dashboard-notification">notification</NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-my-wallet">my wallet</NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-my-bets">my bets</NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-friend-referrelas">
                      friends &amp; referrelas
                    </NavLink>
                  </div>
                </li>
                <li className={`${addClass ? "active_tab" : ""} `}>
                  <div>
                    <NavLink
                      to="/dashboard-setting"
                      className={({ isActive }) => {
                        setAddClass(isActive);
                      }}
                    >
                      settings
                    </NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="tebs_content">
            <div className="tabs_section" id="settings">
              <div className="theWall">
                <h1>Preferences</h1>
                <div className="bats_nav">
                  <ul id="Setting_tabs">
                    <li
                      className={`${show === 0 ? "active_bat" : ""}`}
                      onClick={() => setShow(0)}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <a>Preferences</a>
                      </div>
                    </li>
                    <li
                      className={`${show === 1 ? "active_bat" : ""}`}
                      onClick={() => setShow(1)}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <a>Change Password</a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="bets_section">
                  {getUserSettingPreference === undefined ? (
                    <div className="text-center">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {show === 0 && (
                        <div className="Setting_content">
                          {getUserSettingPreference &&
                            getUserSettingPreference.map((data, i) => (
                              <div className="preference_container" key={i}>
                                <div className="notification_box">
                                  <h5 className="text20black700">
                                    {data.name}
                                  </h5>
                                  <div className="row gx-5 checkbox_flield">
                                    {data &&
                                      data.category &&
                                      data.category.map((category, i) => (
                                        <div className="col-md-6" key={i}>
                                          <label className="Label">
                                            {category.name}
                                          </label>
                                          {category &&
                                            category.subcategory &&
                                            category.subcategory.map(
                                              (subcategory, i) => (
                                                <div
                                                  className="checkboxs"
                                                  key={i}
                                                >
                                                  <label className="control control--checkbox">
                                                    <input
                                                      type="checkbox"
                                                      defaultChecked={`${
                                                        subcategory.is_active ===
                                                        1
                                                          ? "checked"
                                                          : ""
                                                      }`}
                                                      onChange={(e) =>
                                                        checkEvnetHandler({
                                                          id: subcategory.preferences_category_id,
                                                          event: e,
                                                        })
                                                      }
                                                    />
                                                    <span>
                                                      {subcategory.name}
                                                    </span>
                                                    <div className="control__indicator" />
                                                  </label>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                          {loader ? (
                            <Loader />
                          ) : (
                            <div className="submit_box notification_box mb-5">
                              <input
                                type="submit"
                                className="btn post_btn btnFill smallbtn"
                                defaultValue="Save"
                                onClick={changeUserSetting}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {show === 1 && (
                    <div className="Setting_content">
                      <div className="preference_container">
                        <div className="form_container">
                          <form onSubmit={changePassword}>
                            <div className="row g-5">
                              <div className="col-md-6">
                                <input
                                  type="text"
                                  className="input"
                                  placeholder="Current Password"
                                  name="oldPassword"
                                  onChange={(e) => inputHandler(e)}
                                  value={value.oldPassword}
                                />
                              </div>
                              <div className="col-md-6">
                                <input
                                  type="text"
                                  className="input"
                                  placeholder="New Password"
                                  name="newPassword"
                                  onChange={(e) => inputHandler(e)}
                                  value={value.newPassword}
                                />
                              </div>
                              <div className="col-md-6">
                                <input
                                  type="text"
                                  className="input"
                                  placeholder="Confirm Password"
                                  name="confirmPassword"
                                  onChange={(e) => inputHandler(e)}
                                  value={value.confirmPassword}
                                />
                              </div>
                            </div>
                            <input
                              type="submit"
                              className="btn post_btn btnFill smallbtn mt-4 mb-4"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <Banner />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Setting;
