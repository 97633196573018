import Axios from "./axios";

import {
  ONCHANGEPASSWORD,
  ONCOUNTNOTIFICATION,
  ONDELETENOTIFICATION,
  ONEDDITPROFILE,
  ONFACEBOOKLOGIN,
  ONFAQCATEGORIES,
  ONFECTHUSERPOSTMESSAGE,
  ONGETCOUNTRIESLEAGUES,
  ONGETCOUNTRIESLIST,
  ONGETFAQ,
  ONGETFETCHSPORTS,
  ONGETMATCHLIST,
  ONLOGIN,
  ONMYPROFILE,
  ONREGISTER,
  ONRESETPASSWORD,
  ONSEENNOTIFICATION,
  ONUPDATEPROFILE,
  ONUPDATEPROFILEIMAGE,
  ONUSERACADEMY,
  ONUSERACADEMYDETAILS,
  ONUSERAWARDS,
  ONUSERAWARDSAPI,
  ONUSERAWARDSDETAILS,
  ONUSERAWARDSSEARCH,
  ONUSERBETSSPORTS,
  ONUSERCONTACTUS,
  ONUSEREDITPROFILEOTHER,
  ONUSERFRIENDLIST,
  ONUSERMESSAGE,
  ONUSERMYBETS,
  ONUSERMYTRANSACTION,
  ONUSERNOTIFICATION,
  ONUSERPOSTMESSAGE,
  ONUSERPOSTMESSAGEDELETE,
  ONUSERREDEEM,
  ONUSERRELOADBALANCE,
  ONUSERREMOVEPROFILE,
  ONUSERREQUESTWITHDRAW,
  ONUSERSETTINGPERFERENCE,
  ONUSERSUBMISSION,
  ONUSERUPDATEPROFILEOTHER,
  ONUSERUPDATESETTINGPERFERENCE,
} from "./type";
import { toast } from "react-toastify";

const config = {
  headers: {
    Authorization: `${localStorage.getItem("userToken")}`,
  },
};

export const onRegister = (data, setLoader) => (dispatch) => {
  Axios.post("/register_new", data)
    .then((res) => {
      setLoader(false);
      if (res.data.status === 1) {
        toast.success(res.data.message);
        window.location.href = "/login";
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONREGISTER,
        payload: res,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onLogin = (data, navigate, setLoader) => (dispatch) => {
  Axios.post("/login-new", data)
    .then((res) => {
      setLoader(false);
      if (res.data.status === 1) {
        toast.success(res.data.message);
        localStorage.setItem("userToken", res.data.result.jwt_token);
        localStorage.setItem("userDetails", JSON.stringify(res.data.result));
        navigate("/");
        window.location.reload();
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONLOGIN,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onFaceBookLogin = (data, navigate, setLoader) => (dispatch) => {
  Axios.post("/social-login", data)
    .then((res) => {
      setLoader(false);
      if (res.data.status === 1) {
        toast.success(res.data.message);
        localStorage.setItem("userToken", res.data.result.jwt_token);
        localStorage.setItem("userDetails", JSON.stringify(res.data.result));
        navigate("/");
        window.location.reload();
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONFACEBOOKLOGIN,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onResetPassword = (data) => (dispatch) => {
  Axios.post("/resetPassword", data)
    .then((res) => {
      if (res.data.status === 1) {
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      }
      toast.success(res.data.message);
      dispatch({
        type: ONRESETPASSWORD,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onChangePassword = (data) => (dispatch) => {
  Axios.post("/changepassword", data, config)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.message);
        localStorage.clear();
        window.location.reload();
        window.location.href = "/";
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONCHANGEPASSWORD,
        payload: res,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUpdateProfile = (data) => (dispatch) => {
  Axios.post("/update-profile", data, config)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONUPDATEPROFILE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUpdateProfileImage = (data) => (dispatch) => {
  Axios.post("/update-profile-image", data, config)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success("your image change successfully");
      }

      dispatch({
        type: ONUPDATEPROFILEIMAGE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onDeleteNotification = (data) => (dispatch) => {
  Axios.post("/userdeletenotifications", data, config)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONDELETENOTIFICATION,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserFriendList = (data) => (dispatch) => {
  Axios.post("/userfriendlist", data, config)
    .then((res) => {
      dispatch({
        type: ONUSERFRIENDLIST,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserNotification = (data, setLoader) => (dispatch) => {
  Axios.post("/usernotifications", data, config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONUSERNOTIFICATION,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onSeenNotification = (data) => (dispatch) => {
  Axios.post("/seennotifications", data, config)
    .then((res) => {
      dispatch({
        type: ONSEENNOTIFICATION,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onCountNotification = (data) => (dispatch) => {
  Axios.post("/countnotification", data, config)
    .then((res) => {
      dispatch({
        type: ONCOUNTNOTIFICATION,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onMyProfile = (data) => (dispatch) => {
  Axios.post("/my-profile", data, config)
    .then((res) => {
      dispatch({
        type: ONMYPROFILE,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      if (err?.response?.data?.error === "invalid_token") {
        localStorage.removeItem("userToken");
        localStorage.removeItem("userDetails");
      }
    });
};
export const onEditProfile = (data) => (dispatch) => {
  Axios.post("/edit-profile", data, config)
    .then((res) => {
      dispatch({
        type: ONEDDITPROFILE,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onGetMatchList = (data) => (dispatch) => {
  Axios.post("/getmatchlist", data, config)
    .then((res) => {
      dispatch({
        type: ONGETMATCHLIST,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onGetCountriesLeagues = (data) => (dispatch) => {
  Axios.post("/get-countries-leagues", data, config)
    .then((res) => {
      dispatch({
        type: ONGETCOUNTRIESLEAGUES,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onGetCountriesList = (data) => (dispatch) => {
  Axios.get("/get-countries-list", config)
    .then((res) => {
      dispatch({
        type: ONGETCOUNTRIESLIST,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onFetchSports = (setLoader) => (dispatch) => {
  Axios.get("/fetch-sports", config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONGETFETCHSPORTS,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onFaqCategories = (setLoader) => (dispatch) => {
  Axios.get("/get-faq-categories", config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONFAQCATEGORIES,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onGetFaq = (data, setLoader) => (dispatch) => {
  Axios.post("/get-faq", data)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONGETFAQ,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserSettingPreference = (data) => (dispatch) => {
  Axios.post("/userpreferences", data, config)
    .then((res) => {
      dispatch({
        type: ONUSERSETTINGPERFERENCE,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserUpdateSettingPreference =
  (data, setLoader) => (dispatch) => {
    Axios.post("/udateduserpreferences", data, config)
      .then((res) => {
        setLoader(false);
        if (res.data.status === 1) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        dispatch({
          type: ONUSERUPDATESETTINGPERFERENCE,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
export const onUserAwards = (data, setLoader) => (dispatch) => {
  Axios.post("/awards", data, config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONUSERAWARDS,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserAwardsDetails = (data, setLoader) => (dispatch) => {
  Axios.post("/awardsdetails", data, config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONUSERAWARDSDETAILS,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onUserAcademy = (data, setLoader) => (dispatch) => {
  Axios.post("/academy", data, config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONUSERACADEMY,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onUserAcademyDetails = (data, setLoader) => (dispatch) => {
  Axios.post("/get-academy-details", data, config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONUSERACADEMYDETAILS,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onUserPostMessage = (data, setMessage, setImage) => (dispatch) => {
  Axios.post("/postmessage", data, config)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.message);
        setMessage("");
        setImage("");
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONUSERPOSTMESSAGE,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onFetchUserPostMessage = (data, setLoader) => (dispatch) => {
  Axios.post("/fetchpostmessage", data, config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONFECTHUSERPOSTMESSAGE,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserPostMessageDelect = (data) => (dispatch) => {
  Axios.post("/postmessagedelete", data, config)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONUSERPOSTMESSAGEDELETE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserBalance = (data) => (dispatch) => {
  Axios.post("/userbalance", data, config)
    .then((res) => {
      dispatch({
        type: ONUSERMESSAGE,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserReloadBalance = (data) => (dispatch) => {
  Axios.post("/reloaduserbalance", data, config)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONUSERRELOADBALANCE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserRedeemAwards = (data) => (dispatch) => {
  Axios.post("/redeemawards", data, config)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONUSERREDEEM,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onUserMyTransaction = (data, setLoader) => (dispatch) => {
  Axios.post("/mytransactions", data, config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONUSERMYTRANSACTION,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserBetSport = (data, setTeamId, setAmount) => (dispatch) => {
  Axios.post("/betsport", data, config)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.message);
        setTeamId("");
        setAmount("");
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONUSERBETSSPORTS,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserRequestWithdraw = (data, setLoader) => (dispatch) => {
  Axios.post("/requestWithdraw", data, config)
    .then((res) => {
      setLoader(false);
      if (res.data.status === 1) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONUSERREQUESTWITHDRAW,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserMyBets = (data, setLoader) => (dispatch) => {
  Axios.post("/mybets", data, config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONUSERMYBETS,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserAwardsSearch = (data, setLoader) => (dispatch) => {
  Axios.post("/awardsSearch", data, config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONUSERAWARDSSEARCH,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserUpdateProfileOther = (data, setLoader) => (dispatch) => {
  Axios.post("/updateprofileother", data, config)
    .then((res) => {
      setLoader(false);
      if (res.data.status === 1) {
        toast.success(res.data.message);
        window.location.reload();
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONUSERUPDATEPROFILEOTHER,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onUserEditProfileOther = (data, setLoader) => (dispatch) => {
  Axios.post("/editprofileother", data, config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONUSEREDITPROFILEOTHER,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onUserAwardsApi = (data, setLoader) => (dispatch) => {
  Axios.post("/userawards", data, config)
    .then((res) => {
      setLoader(false);
      dispatch({
        type: ONUSERAWARDSAPI,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onUserRemoveProfile = (data, setLoader) => (dispatch) => {
  Axios.post("/removeProfileImage", data, config)
    .then((res) => {
      setLoader(false);
      if (res.data.status === 1) {
        toast.success(res.data.message);
        window.location.reload();
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONUSERREMOVEPROFILE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const onUserContactUs = (data, setLoader, setValue) => (dispatch) => {
  Axios.post("/usercontestus", data, config)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.message);
        setValue({});
      } else {
        toast.error(res.data.message);
      }
      setLoader(false);
      dispatch({
        type: ONUSERCONTACTUS,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onUserOfferSubmission = (data, setEmail) => (dispatch) => {
  Axios.post("/newsletter", data, config)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.message);
        setEmail("");
      } else {
        toast.error(res.data.message);
      }
      dispatch({
        type: ONUSERSUBMISSION,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
