import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onRegister } from "../../redux/homeAction";
import { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";

const Register = ({ facebookLogin, loader, setLoader, googleLogin }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [input, setInput] = useState("");
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const generateCaptchaHandler = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return setCaptcha(result);
  };

  useEffect(() => {
    generateCaptchaHandler(6);
  }, []);

  const onInputHandler = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const onHandleRegister = (e) => {
    e.preventDefault();
    if (!input.name) {
      toast.error("Please enter a user name");
    } else if (!input.fullName) {
      toast.error("Please enter a full name");
    } else if (!input.email) {
      toast.error("Please enter a email");
    } else if (!emailRegex.test(input.email)) {
      toast.error("Please enter a correct email");
    } else if (!input.password) {
      toast.error("Please enter a password");
    } else if (input.password.length < 4) {
      toast.error("The password must be at least 4 characters");
    } else if (input.password.length > 15) {
      toast.error("Password must not exceed 15 characters");
    } else if (!input.repeatPassword) {
      toast.error("Please enter a repeat password");
    } else if (input.password !== input.repeatPassword) {
      toast.error("Password and confirm password should be match");
    } else if (!input.quizCode) {
      toast.error("Please enter captcha code");
    } else if (input.quizCode !== captcha) {
      toast.error("Captcha Code Not Correct");
    } else {
      setLoader(true);
      const data = {
        email: input.email,
        username: input.name,
        password: input.password,
        full_name: input.fullName,
      };
      dispatch(onRegister(data, setLoader));
    }
  };

  return (
    <>
      <section className="Login_body" id="login">
        <div className="login_content">
          <div className="row gy-5">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <div className="login-container text-center">
                <h1>
                  Welcome to <span className="color_yellow">Triunfador</span>
                </h1>
                <div className="row w-100 gy-3">
                  <div className="col-md-6">
                    <button
                      className="btn post_btn btnFill"
                      onClick={facebookLogin}
                    >
                      <div className="btnfilltext">
                        <img src="./images/fb.png" />
                        Login with Facebook
                      </div>
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      className="btn post_btn btnFill"
                      onClick={googleLogin}
                    >
                      <div className="btnfilltext">
                        <img src="./images/google.png" className="img-fluid" />
                        Login with Google
                      </div>
                    </button>
                  </div>
                </div>
                <form onSubmit={onHandleRegister}>
                  <div className="row gy-3">
                    <div className="col-md-6">
                      <div className="form-group floating">
                        <input
                          type="text"
                          className="form-control floating input "
                          name="name"
                          value={input.name}
                          onChange={(e) => onInputHandler(e)}
                        />
                        <label htmlFor="usr">Username</label>
                        <div className="input_icon">
                          <i className="fa-regular fa-user" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group floating position-relative">
                        <input
                          type="text"
                          className="form-control floating input"
                          name="fullName"
                          value={input.fullName}
                          onChange={(e) => onInputHandler(e)}
                        />
                        <label htmlFor="usr">Full Name</label>
                        <div className="input_icon">
                          <i className="fa-regular fa-user" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group floating position-relative">
                        <input
                          type="email"
                          className="form-control floating input"
                          name="email"
                          value={input.email}
                          onChange={(e) => onInputHandler(e)}
                        />
                        <label htmlFor="usr">E-mail</label>
                        <div className="input_icon">
                          <i className="fa-regular fa-envelope" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group floating position-relative">
                        <input
                          type={`${showPassword1 ? "text" : "password"}`}
                          className="form-control floating input"
                          name="password"
                          value={input.password}
                          onChange={(e) => onInputHandler(e)}
                        />
                        <label htmlFor="usr">Password</label>
                        <div className="input_icon">
                          {showPassword1 ? (
                            <i
                              className="fa-regular fa-eye"
                              onClick={() => setShowPassword1(false)}
                            />
                          ) : (
                            <i
                              className="fa fa-eye-slash"
                              onClick={() => setShowPassword1(true)}
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group floating position-relative">
                        <input
                          type={`${showPassword ? "text" : "password"}`}
                          className="form-control floating input"
                          name="repeatPassword"
                          value={input.repeatPassword}
                          onChange={(e) => onInputHandler(e)}
                        />
                        <label htmlFor="usr">Repeat Password</label>
                        <div className="input_icon">
                          {showPassword ? (
                            <i
                              className="fa-regular fa-eye"
                              onClick={() => setShowPassword(false)}
                            />
                          ) : (
                            <i
                              className="fa fa-eye-slash"
                              onClick={() => setShowPassword(true)}
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-end">
                      <div className="code position-relative">
                        <div className="capcha">{captcha}</div>
                        <i
                          className="fa-solid fa-arrows-rotate refresh_icon"
                          onClick={() => generateCaptchaHandler(6)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group floating position-relative">
                        <input
                          type="text"
                          className="form-control floating input"
                          name="quizCode"
                          value={input.quizCode}
                          onChange={(e) => onInputHandler(e)}
                        />
                        <label htmlFor="usr">Enter The Code</label>
                        <div className="input_icon">
                          <img src="./images/code.png" />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                      <label className="control control--checkbox register">
                        <input type="checkbox" defaultChecked="checked" />
                        <span>
                          I am over 18 years old and I accept the Terms and
                          Conditions of the site
                        </span>
                        <div className="control__indicator" />
                      </label>
                    </div> */}
                    <div className="col-md-12">
                      {loader ? (
                        <Loader />
                      ) : (
                        <input type="submit" className="input submit_input" />
                      )}
                    </div>
                  </div>
                </form>
                <Link to="/login" className="text_normal">
                  Have an account?
                  <span className="color_yellow">Log in</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <div className="login_right">
                <img src="./images/login_right.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
