import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import Banner from "../Banner/Banner";
import { useDispatch, useSelector } from "react-redux";
import { onUserAwards } from "../../redux/homeAction";
import Loader from "../loader/Loader";

const Awards = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const classSend = "position-relative";

  const getUserAwards = useSelector((state) => state.home.getUserAwards);
  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));
  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    setLoader(true);
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onUserAwards(data, setLoader));
  }, []);

  return (
    <>
      <Navbar classSend={classSend} />
      <section className="awards_section">
        <div className="container">
          <h1 className="h1_60">AWARDS</h1>
          <p className="P_gry_normal firstP">
            All prizes are FREE. They are available for you to earn without
            investing a dime. Your balance manages a global portfolio so you
            will accumulate points throughout all the sections of the web. The
            prizes are sent to most COUNTRIES of the world through local stores
            (Spain, Mexico, Argentina, United States, Colombia, etc.) without
            any surcharge for shipping or taxes.
          </p>
          <p className="P_gry_normal">
            IMPORTANT: Due to widespread abuse of multi-account activity,
            Venezuelan players can only redeem the prizes: Steam Random Game and
            1 month NETFLIX subscription.
          </p>
          <div className="ad_section ad-top">
            <div className="row ad_content">
              <div className="col-md-12">
                <img src="./images/ad2.png" className="img-fluid" />
              </div>
            </div>
          </div>
          {getUserAwards ? (
            <>
              {loader ? (
                <div className="text-center">
                  <Loader />
                </div>
              ) : (
                <div className="awards_container">
                  {getUserAwards &&
                    getUserAwards.map((data, i) => (
                      <div className="Product_box" key={i}>
                        {getUserAwards && getUserAwards.length ? (
                          <>
                            <img src={data.image} className="product_img" />
                            <h5>{data.tittle}</h5>
                            <p>{data.price} Points</p>
                            <div className="product_option">
                              <Link
                                to={`${
                                  userToken
                                    ? `/award-item/${data.id}`
                                    : "/login"
                                }`}
                              >
                                <button className="detail_btn btn">
                                  Detail
                                </button>
                              </Link>
                              <Link
                                to={`${
                                  userToken
                                    ? `/award-item/${data.id}`
                                    : "/login"
                                }`}
                              >
                                <button className="Apply_btn btn">
                                  Apply For
                                </button>
                              </Link>
                            </div>{" "}
                          </>
                        ) : (
                          <h2 className="text-center">No Data Found</h2>
                        )}
                      </div>
                    ))}
                </div>
              )}{" "}
            </>
          ) : (
            <div className="">
              <h2 className="text-center">No Data Found</h2>
            </div>
          )}
          <Banner />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Awards;
