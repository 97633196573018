import React from "react";
import Footer from "../../componets/Footer/Footer";
import Navbar from "../../componets/Navbar/Navbar";
import AboutSection from "./AboutSection";

const AboutUs = () => {
  const classSend = "position-relative";

  return (
    <>
      <Navbar classSend={classSend} />
      <AboutSection />
      <Footer />
    </>
  );
};

export default AboutUs;
