import React from "react";
import { Link } from "react-router-dom";

const ReloadModel = () => {
  return (
    <>
      <div
        className="modal fade"
        id="reloadRequest"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="modal-body">
              <img src="./images/reload_img.png" />
              <h1>Reload Succecfully !</h1>
              <p className="normal_p pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam
                congue congue eget in nibh.
              </p>
              <div className="btn post_btn btnFill">
                <Link to="/sports-betting">
                  <div
                    className="btnfilltext"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Start Betting
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReloadModel;
