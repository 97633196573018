import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onUserRequestWithdraw } from "../../../redux/homeAction";
import Loader from "../../loader/Loader";

const WithDrawModel = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const getUserRequestWithdraw = useSelector(
    (state) => state.home.getUserRequestWithdraw
  );
  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));

  const withDrawRequest = () => {
    setLoader(true);
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onUserRequestWithdraw(data, setLoader));
  };

  return (
    <>
      <div
        className="modal fade"
        id="withdrawRequest"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="modal-body text-center">
              <div className="withdraw_modal text=center">
                <img src="./images/withdraw_img.png" className="img-fluid" />
                <h2 className="2_30">Withdraw Request Sent !</h2>
                <p className="textGRY700">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam
                  congue congue eget in nibh.
                </p>
                {loader ? (
                  <div className="">
                    <Loader />
                  </div>
                ) : (
                  <div className="add_post_btn text-center">
                    <button
                      className="btn post_btn btnFill"
                      onClick={withDrawRequest}
                    >
                      <div className="btnfilltext">Okay Thanks !</div>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithDrawModel;
