import React from "react";

const TermAndConditionSection = () => {
  return (
    <>
      <section className="about">
        <div className="container">
          <div className="about-content">
            <h1 className="section_head text-center">
              Terms &amp;<span className="color_yellow"> Conditions</span>
            </h1>
          </div>
          <p>
            This statement establishes the service conditions that govern our
            relationship with users and with all those who interact with
            triunfador.net (“TRIUNFADOR”). By using the services of TRIUNFADOR,
            you are accepting your agreement with these terms and conditions.
          </p>
          <div className="content-container mt-3">
            <ul className="termCondition">
              <li>
                General
                <ul className="big_ul">
                  <li>
                    All users without exception must be of legal age according
                    to the legislation that applies to them, and minors are not
                    authorized to sign up.
                  </li>
                  <li>
                    TRIUNFADOR is aimed at citizens of any nationality and
                    residence, as long as the legislation that applies to them
                    allows them to develop the activity of the Portal. If
                    legislation does not allow this, the registration of users
                    who fall under such legislation is strictly prohibited.
                  </li>
                  <li>
                    A user may only register and manage one TRIUNFADOR account,
                    and this account must have all correct and true information.
                    Any activity of duplicating accounts, multiple registrations
                    or entering false data is considered fraud and the
                    administration of TRIUNFADOR at its sole discretion may
                    cancel any account if there is suspicion of any of these
                    activities. Likewise, only the use of 2 accounts from the
                    same location (family home, shared home, public library,
                    internet cafe, IP address) is allowed as long as they access
                    from different devices. That is, 2 different computers,
                    tablets or mobile phones. This infraction is considered
                    fraudulent and merits NO payment of any prize. The
                    non-compliance of this rule is considered a fraudulent
                    action related to the activity of multi-account,
                    registration with incorrect and fictitious data or
                    administration of an account in the name of another holder.
                    All of this is prohibited and if there is evidence that a
                    user has committed any of these activities, their account
                    will be suspended and NO PAYMENT will be processed, even if
                    they have received a prize.
                  </li>
                  <li>
                    All users are subject to a verification and data validation
                    process. This process may include aspects such as validation
                    of a mobile number or delivery of documentation that allows
                    full identification of the user, which may include, but not
                    be limited to, sending photographs of the ID, proof of
                    address or selfies with a distinctive note. If the identity
                    of a user cannot be fully and certainly verified in this
                    verification process, TRIUNFADOR reserves the total right to
                    cancel that account and void any prize or reward they might
                    receive.
                  </li>
                  <li>
                    If a user receives a prize, gift, present or reward on the
                    TRIUNFADOR portal, they will always have to go through the
                    verification process. If this process is not completed
                    within a period of 1 month, TRIUNFADOR reserves the right to
                    deny such prize, gift, present or reward. Always check your
                    SPAM folder when communicating with TRIUNFADOR, as some
                    messages may reach that folder. It is your responsibility to
                    have valid and correct email information.
                  </li>
                  <li>
                    "TRIUNFADOR" is aimed at citizens of any nationality and
                    residence, as long as the legislation that applies to them
                    allows them to carry out the activity of the portal, in a
                    completely free manner and with a purely promotional
                    purpose. Access to the portal is strictly prohibited for
                    those people whose legislation prohibits it, releasing
                    TRIUNFADOR from any responsibility in case of not complying
                    with this prohibition.
                  </li>
                  <li>
                    The opinions and comments published by users are the sole
                    responsibility of the users and the consequences arising are
                    subject to the legal sanctions that correspond.
                  </li>
                  <li>
                    The opinions and comments published by users are the sole
                    responsibility of the users and the consequences arising are
                    subject to the legal sanctions that correspond.
                  </li>
                  <li>
                    TRIUNFADOR reserves the right to edit or delete any comments
                    and content that it considers inappropriate, at its sole
                    discretion.
                  </li>
                  <li>
                    A POINT DISCOUNT will be applied to all accounts that
                    present INACTIVITY as follows: - 1 month: 100,000 pts. - 2
                    months: an additional 200,000 pts. - 3 months: an additional
                    300,000 pts. - 4 months: an additional 400,000 pts. - 5
                    months: an additional 500,000 pts. -6 months or more: The
                    entire balance will be reset to 20,000 pts.
                  </li>
                  <li>
                    TRIUNFADOR is in no way responsible for the content of user
                    or third-party content that has been posted on the site or
                    in connection with the service. Although we establish
                    standards for the proper conduct of users and their
                    activities and posts within the site, we do not control what
                    users post, transmit, or share, and we are not therefore
                    responsible for it nor any offensive, inappropriate,
                    obscene, illegal or questionable content that you may find
                    on the portal.
                  </li>
                  <li>
                    TRIUNFADOR is not responsible for the online or offline
                    behavior of any user of the portal or service.
                  </li>
                  <li>
                    TRIUNFADOR does not guarantee any specific result derived
                    from the use of the portal, the applications or services
                    offered on the platform. TRIUNFADOR does not declare or
                    guarantee that the software, content, applications and
                    services offered and existing on the portal are complete,
                    accurate, reliable, updated, error-free, or other harmful
                    components. Consequently and without limitation of the
                    above, you acknowledge and accept that you download or
                    obtain data, content or software through any platform
                    application at your own risk and you will be the only one
                    responsible for any damages that your computer device may
                    suffer and for the use you make of it.
                  </li>
                  <li>
                    TRIUNFADOR reserves the right to modify any application,
                    service, content and other elements used or included in the
                    portal at any time and without prior notice.
                  </li>
                  <li>
                    Any reference to services, products, offers, processes or
                    other information through trademarks, manufacturers,
                    commercial names, suppliers or others, in no way manifests
                    or implies that TRIUNFADOR sponsors, recommends, or approves
                    such services, products, processes or information, or that
                    it has a relationship with them.
                  </li>
                  <li>
                    All demographic information that is provided by a user may
                    and will be used by TRIUNFADOR for commercial purposes.
                  </li>
                  <li>
                    All demographic information that is provided by a user may
                    and will be used by TRIUNFADOR for commercial purposes.
                  </li>
                  <li>
                    TRIUNFADOR and any of its advertising brands are not
                    responsible for any incorrect or distorted information,
                    whether caused by a user or by any computer program used in
                    the applications, contests and services of the portal.
                  </li>
                  <li>
                    TRIUNFADOR and any of its advertising brands assume no
                    responsibility for any error, interruption, defect,
                    omission, elimination, delay in operation or transmission,
                    communication line failures, alteration of participations,
                    unauthorized access, technical difficulty of computer
                    systems / programs / servers / providers, caused by
                    technical problems or traffic congestion on the Internet or
                    any other website.
                  </li>
                  <li>
                    TRIUNFADOR and any of its advertising brands assume no
                    responsibility for any damage, harm or injury that any user
                    may suffer due to or as a result of any prize or reward won
                    on our site.
                  </li>
                  <li>
                    TRIUNFADOR and any of its advertising brands assume no
                    responsibility if for any reason an application, service or
                    contest does not develop or function fully as designed and
                    specified, due to causes beyond and outside the control of
                    TRIUNFADOR, which corrupt or disturb the administration,
                    impartiality, security, performance and appropriate conduct
                    of the portal and all the contents and services offered.
                  </li>
                  <li>
                    TRIUNFADOR retains its total and complete power over the
                    consent, maintenance and cancellation of the accounts of all
                    its users. Any decision taken by the administration of
                    TRIUNFADOR in relation to any aspect of user accounts, use
                    of the service or resolution of disputes, will be FINAL AND
                    WILL NOT BE SUBJECT TO ANY APPEAL.
                  </li>
                  <li>
                    TRIUNFADOR retains its total and complete power over the
                    consent, maintenance and cancellation of the accounts of all
                    its users. Any decision taken by the administration of
                    TRIUNFADOR in relation to any aspect of user accounts, use
                    of the service or resolution of disputes, will be FINAL AND
                    WILL NOT BE SUBJECT TO ANY APPEAL.
                  </li>
                  <li>
                    TRIUNFADOR reserves the right to unilaterally suspend,
                    cancel, modify, or terminate any service, application, or
                    contest offered, as well as the participation or use of a
                    user of the same.
                  </li>
                  <li>
                    TRIUNFADOR does not offer any warranty of any kind regarding
                    the products or services advertised or advertised anywhere
                    on the website. Any claim with respect to this will be the
                    exclusive and total responsibility of the advertiser.
                  </li>
                  <li>
                    Upon registration, each user accepts this statement of terms
                    and conditions and expressly authorizes TRIUNFADOR and its
                    affiliates or subsidiaries to collect, process, and
                    commercialize the data contained therein. Based on this,
                    TRIUNFADOR, its affiliates, subsidiaries, or advertising
                    brands may reproduce, adapt, publish, translate, extract, or
                    condense the information and data provided by the users. In
                    the same way, each user gives it the power to dispose of
                    them for a fee or free of charge under the lawful conditions
                    that its free judgment dictates.
                  </li>
                  <li>
                    Upon registering, each user declares that they know and
                    accept that the data provided and contained in the portal
                    can be used to direct, execute, drive, and generally carry
                    out draws, promotional campaigns, commercial contests, and
                    in general any type of activity for advertising purposes of
                    TRIUNFADOR or with whom this activities, by sending messages
                    by email, text messages (SMS) and any analog or digital
                    means of communication.
                  </li>
                  <li>
                    Players of Venezuelan nationality are limited to redeeming
                    the following prizes: - Steam Random Game - 1 month
                    subscription to Prime Video, Disney +, HBO Max, Paramount +,
                    Spotify Premium, Youtube Premium. This may change in the
                    near future if the multi-account activity rate in that
                    country is reduced, and may also apply to new countries that
                    incur in the proliferation of such activity.
                  </li>
                  <li>
                    If a user takes advantage of ultra-fast connections or some
                    fault in the betting ticket to bet knowing in advance the
                    result or a determining action in a game, such a bet may be
                    considered null and the points returned to the balance.
                  </li>
                </ul>
              </li>
              <li>
                RIGHTS AND OBLIGATIONS OF THE USER
                <ul>
                  <li>
                    The user may use the applications, contests, content and
                    services available for their exclusive personal use. Some of
                    these will be restricted according to gender, age of
                    majority or any other category that will be clearly
                    specified in its general description.
                  </li>
                  <li>
                    The user may not use the portal's services and content for
                    illegal or harmful purposes to third parties, or for
                    purposes that may damage or prevent access to them in any
                    way, to the detriment of TRIUNFADOR or third parties.
                  </li>
                  <li>
                    The user may not use the services, either partially or in
                    full, to contract, promote, disclose or sell advertising or
                    information belonging to themselves or third parties without
                    prior authorization.
                  </li>
                  <li>
                    The user may not use the portal for purposes contrary to
                    current legislation, morals, good customs and public order.
                  </li>
                  <li>
                    The use of any type of computer program, software, code,
                    algorithm, computer or telecommunications equipment, or
                    computer virus that may cause damage, harm, or unauthorized
                    alterations of the content, applications, programs, or
                    systems accessible through the services and content provided
                    on the portal is strictly prohibited.
                  </li>
                  <li>
                    The user may not remove or alter in any way the protection
                    mechanisms or identification of TRIUNFADOR or the symbols
                    that TRIUNFADOR or legitimate third-party holders of
                    intellectual or industrial property rights incorporate into
                    their creations that are the subject of property existing in
                    this portal.
                  </li>
                </ul>
              </li>
              <li>
                RIGHTS OF TRIUNFADOR
                <ul>
                  <li>
                    TRIUNFADOR can unilaterally modify this statement of terms
                    and conditions without prior notice to users, without
                    prejudice to the provisions in the particular conditions
                    that regulate the use of a particular service and/or content
                    of the portal.
                  </li>
                  <li>
                    TRIUNFADOR reserves the right to implement particular
                    conditions and, where necessary, the requirement of certain
                    conditions or requirements for access to certain services,
                    content, applications or contests.
                  </li>
                  <li>
                    TRIUNFADOR may modify, update or delete all or part of the
                    contents, services, contests and applications, as well as
                    their access conditions, and may even restrict or impede
                    access to that information.
                  </li>
                  <li>
                    TRIUNFADOR may at any time and without prior notice deny
                    access to the portal to any user who is considered to have
                    committed any type of fraud, who needs to undergo the
                    verification process, or who fails to comply with any
                    condition of this statement.
                  </li>
                  <li>
                    TRIUNFADOR reserves the right to undertake and execute any
                    legal or legal action that is convenient for the protection
                    of the rights of TRIUNFADOR as well as third parties that
                    provide their services or contents through the portal,
                    whenever it is appropriate. It may also demand the
                    compensation that may arise from the improper or illegal use
                    of all or part of the services and contents provided through
                    the portal.
                  </li>
                </ul>
              </li>
              <li>
                DISCLAIMER OF LIABILITY
                <ul>
                  <li>
                    TRIUNFADOR assumes no responsibility related to the contents
                    and services, or the conditions and availabilities, whether
                    technical or not, of access to them, offered by third-party
                    service providers.
                  </li>
                  <li>
                    Under no circumstances and at no time, does TRIUNFADOR
                    assume responsibility for damages or harm that could result
                    from the information, contents, applications, products and
                    services provided, offered, hosted, transmitted, displayed,
                    or offered by third parties not affiliated with TRIUNFADOR,
                    even if they are accessible through a link on the portal.
                  </li>
                  <li>
                    TRIUNFADOR is exempt from the treatment and use of personal
                    data carried out by third parties not affiliated, as well as
                    the relevance of the information requested by them.
                  </li>
                  <li>
                    The user must personally respond to the damages and harm of
                    any nature caused to TRIUNFADOR, directly or indirectly, by
                    non-compliance with any of the obligations derived from this
                    statement or the regulations governing the use of the
                    portal.
                  </li>
                </ul>
              </li>
              <li>
                INTELLECTUAL AND INDUSTRIAL PROPERTY
                <ul>
                  <li>
                    Each user acknowledges that all the contents, applications,
                    computer algorithms, databases, code in various programming
                    languages, and services offered through the portal - without
                    limitation - are protected by intellectual property laws.
                  </li>
                  <li>
                    The economic exploitation of this portal, as well as the
                    copyrights, belong to TRIUNFADOR. As for the contents
                    included in it, the copyrights and economic exploitation
                    rights are the property of TRIUNFADOR or third parties, and
                    in both cases they are protected by the current intellectual
                    property laws.
                  </li>
                  <li>
                    All commercial names, trademarks, or distinctive symbols
                    that appear on the portal are the property of TRIUNFADOR and
                    are protected by the current industrial property laws.
                  </li>
                  <li>
                    The ownership of the corresponding intellectual and
                    industrial property rights will not be implicated in any
                    case, by the publication of contents and provision of
                    services of the portal, nor will it involve its
                    renunciation, assignment, or transmission, either in full or
                    in part.
                  </li>
                  <li>
                    If the infringement of the intellectual and industrial
                    property rights of TRIUNFADOR or of third parties (culpable
                    or negligent, directly or indirectly attributable to the
                    user), originates any type of losses, damages, expenses of
                    any kind, sanctions, fines, or any amount derived from any
                    lawsuit, claim or litigation, whether civil, administrative
                    or criminal; TRIUNFADOR will have the right to address the
                    user through the legal means at its disposal, and may claim
                    any indemnification amount. This includes (by way of example
                    and not limitation) emergent damages, moral damages, image
                    damages, advertising costs, amounts of sanctions or
                    condemnatory sentences, court costs, and the defense in any
                    proceedings in which it could be sued due to the reasons
                    stated above, for damages and losses caused due to its
                    actions or omissions, without prejudice to exercising any
                    other actions that correspond to it in law.
                  </li>
                </ul>
              </li>
              <li>
                AWARDS
                <ul>
                  <li>
                    Any gift or award given in kind will not be redeemable for
                    cash.
                  </li>
                  <li>
                    Any gift or award will not be exchanged by TRIUNFADOR under
                    any circumstances.
                  </li>
                  <li>
                    If a user wants to exchange the award that corresponds to
                    them for any reason, they will not be able to exchange it
                    for another offered award or redeem it for cash.
                  </li>
                  <li>
                    In certain awards, there will be a limited amount per
                    product, so they will be available until stocks run out.
                  </li>
                  <li>
                    The data provided by a user at the time of registration must
                    match the data at the time of claiming a prize obtained. If
                    this is not fulfilled, the user will lose their right to
                    claim the prize, gift or present they were entitled to.
                  </li>
                  <li>
                    Real money prizes won on the portal will be paid through
                    Paypal, Bitcoin, Binance, and altcoin wallets such as TRX,
                    SOL, ADA, LTC, among others.
                  </li>
                  <li>
                    Users in some countries will be able to withdraw real money
                    prizes through a bank account transfer. You should check the
                    feasibility for your country by contacting customer support.
                  </li>
                  <li>
                    Physical prizes can only be received in some countries. You
                    should check the feasibility for your country by contacting
                    customer support.
                  </li>
                </ul>
              </li>
              <li>
                PRIVACY
                <ul>
                  <li>
                    We have designed our Privacy Policy to help you understand
                    how TRIUNFADOR handles your information and how your
                    information is shared with other users and third parties. We
                    recommend that you review that policy and use it to make
                    decisions in your relationship with TRIUNFADOR.
                  </li>
                </ul>
              </li>
              <li>
                SHARED INFORMATION
                <ul>
                  <li>
                    Keep in mind that all the information and content you post
                    on TRIUNFADOR is your property. For content that is
                    protected by intellectual property rights, such as
                    photographs and videos (hereinafter "PI content"), you grant
                    us a royalty-free, non-exclusive, globally applicable,
                    transferable, sub-licensable license. This license will
                    expire when you delete your PI content or close your
                    account, except if the content has been shared with third
                    parties and they have not removed it.
                  </li>
                </ul>
              </li>
              <li>
                SECURITY AND REGISTRATION
                <ul>
                  <li>
                    The TRIUNFADOR team strives to keep the portal completely
                    secure. However, we cannot guarantee it. To achieve the
                    highest possible security, we demand your commitment to:
                  </li>
                  <li>
                    <ul>
                      <li>
                        Do not take any action that could disable, overload, or
                        affect the performance of TRIUNFADOR in any of its
                        services, applications, and content.
                      </li>
                      <li> Do not intimidate or harass any user.</li>
                      <li>
                        Do not post unauthorized commercial communications.
                      </li>
                      <li>
                        Do not upload any type of virus or malicious code.
                      </li>
                      <li>
                        Do not post content that contains offensive, racist, or
                        pornographic language.
                      </li>
                      <li>
                        Do not use TRIUNFADOR for discriminatory, illegal, or
                        misleading acts.
                      </li>
                    </ul>
                    <p>
                      If you breach any of these commitments, your account may
                      be permanently suspended.
                    </p>
                  </li>
                </ul>
                <ul>
                  <li>
                    In relation to the registration and security of your data,
                    you agree to:
                  </li>
                  <li>
                    <ul>
                      <li>
                        Do not use TRIUNFADOR if you are underage according to
                        the applicable legislation.
                      </li>
                      <li>
                        Provide your real data and information at all times.
                      </li>
                      <li>
                        Do not create more than one personal account for any
                        reason.
                      </li>
                      <li>
                        If your account is blocked or suspended, you will not
                        create another without our authorization.
                      </li>
                      <li>
                        You will not be able to use TRIUNFADOR if you have had
                        litigation relating to sexual offenses.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    TRIUNFADOR will carry out verification and identity
                    verification processes. If we find any discrepancies,
                    falsehoods, or confusion in this process, we may, at our
                    sole discretion, delete your account.
                  </li>
                </ul>
              </li>
              <li>
                TERMINATION
                <ul>
                  <li>
                    TRIUNFADOR provides rules and regulations for the conduct of
                    users, but we do not control or direct their actions on the
                    portal and we are not responsible in any way for the content
                    or information that users transmit or share on TRIUNFADOR.
                    We are not responsible for any content that is considered
                    defamatory, obscene, offensive, inappropriate, illegal or
                    unacceptable that you may find on TRIUNFADOR, nor are we
                    responsible for the conduct of any user, either inside or
                    outside of TRIUNFADOR.
                  </li>
                  <li>
                    {" "}
                    If a person, company, institution or any entity brings a
                    lawsuit against us related to the information or content you
                    have posted on the TRIUNFADOR portal, or the actions you
                    have taken on it, you will have to indemnify us and release
                    us from liability for all possible losses, damages and
                    expenses of any kind (including any legal costs and fees)
                    that are related to such lawsuit.
                  </li>
                  <li>
                    Although we always strive to keep TRIUNFADOR in optimal
                    operation, safe and error-free, you use it at your own risk.
                    We provide TRIUNFADOR as is, without any express or implied
                    warranty, including but not limited to warranties of fitness
                    for a particular purpose, merchantability and
                    non-infringement.
                  </li>
                  <li>
                    We cannot guarantee that TRIUNFADOR will always operate
                    without delays or interruptions, be free of errors or always
                    be safe.
                  </li>
                  <li>
                    TRIUNFADOR is in no way responsible for the information,
                    content, data to third parties or actions taken or published
                    by a user; and by this means, you exonerate and exclude
                    TRIUNFADOR, our directors, agents and employees from any
                    claim, damages or losses, known or unknown, derived or
                    related to any lawsuit you have brought against such third
                    parties.
                  </li>
                  <li>
                    The joint liability arising from this statement or from
                    TRIUNFADOR may not exceed an amount greater than 100,000
                    COP.
                  </li>
                  <li>
                    This statement constitutes the complete agreement between
                    the parties with respect to TRIUNFADOR and substantially
                    replaces any previous agreement.
                  </li>
                  <li>
                    If any part of this statement cannot be enforced, the
                    remaining part will continue to have full effect and
                    validity.
                  </li>
                  <li>
                    When you use TRIUNFADOR, you must comply with all applicable
                    laws, and we reserve all rights that we have not granted you
                    expressly.
                  </li>
                  <li>
                    All of our rights and obligations presented in this
                    statement are transferable freely by us in connection with a
                    merger, acquisition or sale of assets or by operation of law
                    or otherwise.
                  </li>
                  <li>
                    When you use TRIUNFADOR, you must comply with all applicable
                    laws, and we reserve all rights that we have not granted you
                    expressly.
                  </li>
                  <li>
                    To make any claim regarding the services provided through
                    the portal, you must send an email to soporte@triunfador.net
                    indicating your data, describing the subject, explaining the
                    facts that motivate the claim and specifying your claim.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermAndConditionSection;
