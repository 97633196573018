import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  onUserBalance,
  onUserMyTransaction,
  onUserReloadBalance,
} from "../../../redux/homeAction";
import Banner from "../../Banner/Banner";
import Footer from "../../Footer/Footer";
import Loader from "../../loader/Loader";
import ReloadModel from "../../Models/ReloadModel/ReloadModel";
import WithDrawModel from "../../Models/WithDrawModel/WithDrawModel";
import Navbar from "../../Navbar/Navbar";
import Pagination from "../../Pagination/Pagination";
import Profile from "../Profile/Profile";

const Wallet = () => {
  const dispatch = useDispatch();
  const classSend = "position-relative";
  const [addClass, setAddClass] = useState(false);
  const [showPerPage, setShowPerPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const getUserReloadBalance = useSelector(
    (state) => state.home.getUserReloadBalance
  );
  const getUserBalance = useSelector((state) => state.home.getUserBalance);
  const getUserMyTransaction = useSelector(
    (state) => state.home.getUserMyTransaction
  );
  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));

  const reloadBalance = () => {
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onUserReloadBalance(data));
  };

  useEffect(() => {
    setLoader(true);
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onUserMyTransaction(data, setLoader));
    dispatch(onUserBalance(data));
  }, [getUserReloadBalance]);

  const lastPostIndex = showPerPage * 10;
  const firstPostIndex = lastPostIndex - 10;

  const currentPost =
    getUserMyTransaction &&
    getUserMyTransaction.result &&
    getUserMyTransaction.result.slice(firstPostIndex, lastPostIndex);

  return (
    <>
      <Navbar classSend={classSend} />
      <Profile />
      <section id="Tabs_section">
        <div className="container">
          <div className="tabs_container">
            <div className="tabs_nav">
              <ul id="tabs">
                {/* <li>
                  <div>
                    <NavLink to="/dashboard-profile">Wall</NavLink>
                  </div>
                </li> */}
                <li>
                  <div>
                    <NavLink to="/dashboard-notification">notification</NavLink>
                  </div>
                </li>
                <li className={`${addClass ? "active_tab" : ""} `}>
                  <div>
                    <NavLink
                      to="/dashboard-my-wallet"
                      className={({ isActive }) => {
                        setAddClass(isActive);
                      }}
                    >
                      my wallet
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-my-bets">my bets</NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-friend-referrelas">
                      friends &amp; referrelas
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div>
                    <NavLink to="/dashboard-setting">settings</NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="tebs_content">
            <div className="tabs_section" id="wallet">
              <div className="theWall">
                <h1>
                  My <span className="color_yellow">Wallet</span>
                </h1>
                <div className="wallet_profile">
                  <div className="row justify-content-between gy-3">
                    <div className="col-md-4">
                      <div className="wallet_profiler_detail">
                        <h1>{userDeatils && userDeatils.full_name}</h1>
                        <img
                          src="./images/profile_dots.png"
                          className="img-fluid profile_dots"
                        />
                        <p>Balance</p>
                        <h2
                          className={` ${
                            getUserBalance &&
                            getUserBalance.balance.toString().length > 6
                              ? "active"
                              : ""
                          }`}
                        >
                          {getUserBalance && getUserBalance.balance}
                        </h2>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="wallet_proflie_boxs">
                        <div className="wallet_profile_box">
                          <img
                            src="./images/PTS.png"
                            className="img-fluid wallet_proflie_boxs_img"
                          />
                          <p>PTS</p>
                          <h2
                            className={` ${
                              getUserBalance &&
                              getUserBalance.balance.toString().length > 6
                                ? "active"
                                : ""
                            }`}
                          >
                            {getUserBalance && getUserBalance.balance}
                          </h2>
                        </div>
                        <div className="wallet_profile_box">
                          <img
                            src="./images/USD.png"
                            className="img-fluid wallet_proflie_boxs_img"
                          />
                          <p>USD</p>
                          <h2
                            className={` ${
                              getUserBalance &&
                              getUserBalance.balance.toString().length > 6
                                ? "active"
                                : ""
                            }`}
                          >
                            {getUserBalance && getUserBalance.usd}
                          </h2>
                        </div>
                        <div className="wallet_profile_box">
                          <img
                            src="./images/BTC.png"
                            className="img-fluid wallet_proflie_boxs_img"
                          />
                          <p>BTC</p>
                          <h2
                            className={` ${
                              getUserBalance &&
                              getUserBalance.balance.toString().length > 6
                                ? "active"
                                : ""
                            }`}
                          >
                            {getUserBalance && getUserBalance.btc}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="add_post_btn text-center Withdraw_row">
                  {getUserBalance && getUserBalance.balance < 8000 ? (
                    <button
                      className="btn post_btn btnFill"
                      data-bs-toggle="modal"
                      data-bs-target="#reloadRequest"
                      onClick={reloadBalance}
                    >
                      <div className="btnfilltext">
                        <svg
                          className="withdraw_icon"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 8C13.976 8 18 6.626 18 4C18 1.374 13.976 0 10 0C6.024 0 2 1.374 2 4C2 6.626 6.024 8 10 8Z"
                            fill="#FF6A0D"
                          />
                          <path
                            d="M2 8C2 10.626 6.024 12 10 12C13.976 12 18 10.626 18 8V6C18 8.626 13.976 10 10 10C6.024 10 2 8.626 2 6V8Z"
                            fill="#FF6A0D"
                          />
                          <path
                            d="M2 12C2 14.626 6.024 16 10 16C13.976 16 18 14.626 18 12V10C18 12.626 13.976 14 10 14C6.024 14 2 12.626 2 10V12Z"
                            fill="#FF6A0D"
                          />
                          <path
                            d="M2 16C2 18.626 6.024 20 10 20C13.976 20 18 18.626 18 16V14C18 16.626 13.976 18 10 18C6.024 18 2 16.626 2 14V16Z"
                            fill="#FF6A0D"
                          />
                        </svg>
                        Reload
                      </div>
                    </button>
                  ) : (
                    <button
                      className="btn post_btn btnFill"
                      data-bs-toggle="modal"
                      data-bs-target="#reloadRequest"
                      disabled
                    >
                      <div className="btnfilltext">
                        <svg
                          className="withdraw_icon"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 8C13.976 8 18 6.626 18 4C18 1.374 13.976 0 10 0C6.024 0 2 1.374 2 4C2 6.626 6.024 8 10 8Z"
                            fill="#FF6A0D"
                          />
                          <path
                            d="M2 8C2 10.626 6.024 12 10 12C13.976 12 18 10.626 18 8V6C18 8.626 13.976 10 10 10C6.024 10 2 8.626 2 6V8Z"
                            fill="#FF6A0D"
                          />
                          <path
                            d="M2 12C2 14.626 6.024 16 10 16C13.976 16 18 14.626 18 12V10C18 12.626 13.976 14 10 14C6.024 14 2 12.626 2 10V12Z"
                            fill="#FF6A0D"
                          />
                          <path
                            d="M2 16C2 18.626 6.024 20 10 20C13.976 20 18 18.626 18 16V14C18 16.626 13.976 18 10 18C6.024 18 2 16.626 2 14V16Z"
                            fill="#FF6A0D"
                          />
                        </svg>
                        Reload
                      </div>
                    </button>
                  )}
                  {getUserBalance && getUserBalance.balance > 8000 ? (
                    <button
                      className="btn post_btn btnFill"
                      data-bs-toggle="modal"
                      data-bs-target="#withdrawRequest"
                    >
                      <div className="btnfilltext">
                        <svg
                          className="withdraw_icon"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1914_11230)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M15.8706 4.10745H14.62L16.4431 19.5718H17.3795C19.163 19.5718 20.3596 19.0548 19.8827 17.0592L17.0237 5.10727C16.8662 4.44751 16.5916 4.10756 15.8707 4.10756L15.8706 4.10745ZM2.63994 5.00697H0.0324245V5.20585C0.0324245 5.39024 0.181887 5.5397 0.366277 5.5397H2.51304L2.63597 5.02558L2.6352 5.02527L2.63978 5.00697L2.63994 5.00697ZM19.9709 5.00697H17.3633L17.3677 5.02527L17.4909 5.53971H19.6374C19.8215 5.53971 19.9711 5.39024 19.9711 5.20585V5.00697L19.9709 5.00697ZM19.6372 0.477539H0.366562C0.182172 0.477539 0.0327096 0.627001 0.0327096 0.811392V3.0088H19.971V0.811392C19.971 0.627001 19.8216 0.477539 19.6373 0.477539H19.6372ZM14.2625 4.10768H12.1034L11.4521 19.572H16.0854L14.2625 4.10768ZM11.7488 4.10768H4.13296C3.40699 4.10768 3.13658 4.45084 2.97997 5.10739L0.12094 17.0594C-0.355976 19.0548 0.840667 19.5719 2.62413 19.5719H11.0977L11.749 4.10756L11.7488 4.10768Z"
                              fill="#FF6A0D"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1914_11230">
                              <rect width={20} height={20} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Withdraw
                      </div>
                    </button>
                  ) : (
                    <button className="btn post_btn btnFill" disabled>
                      <div className="btnfilltext">
                        <svg
                          className="withdraw_icon"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1914_11230)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M15.8706 4.10745H14.62L16.4431 19.5718H17.3795C19.163 19.5718 20.3596 19.0548 19.8827 17.0592L17.0237 5.10727C16.8662 4.44751 16.5916 4.10756 15.8707 4.10756L15.8706 4.10745ZM2.63994 5.00697H0.0324245V5.20585C0.0324245 5.39024 0.181887 5.5397 0.366277 5.5397H2.51304L2.63597 5.02558L2.6352 5.02527L2.63978 5.00697L2.63994 5.00697ZM19.9709 5.00697H17.3633L17.3677 5.02527L17.4909 5.53971H19.6374C19.8215 5.53971 19.9711 5.39024 19.9711 5.20585V5.00697L19.9709 5.00697ZM19.6372 0.477539H0.366562C0.182172 0.477539 0.0327096 0.627001 0.0327096 0.811392V3.0088H19.971V0.811392C19.971 0.627001 19.8216 0.477539 19.6373 0.477539H19.6372ZM14.2625 4.10768H12.1034L11.4521 19.572H16.0854L14.2625 4.10768ZM11.7488 4.10768H4.13296C3.40699 4.10768 3.13658 4.45084 2.97997 5.10739L0.12094 17.0594C-0.355976 19.0548 0.840667 19.5719 2.62413 19.5719H11.0977L11.749 4.10756L11.7488 4.10768Z"
                              fill="#FF6A0D"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1914_11230">
                              <rect width={20} height={20} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Withdraw
                      </div>
                    </button>
                  )}
                </div>
                {loader ? (
                  <div className="text-center mt-4 mb-4">
                    <Loader />
                  </div>
                ) : (
                  <div className="recent_transitions">
                    <div className="recent_transition d-flex justify-content-between">
                      <h5>Recent Transactions</h5>
                      {/* <p>View All</p> */}
                    </div>
                    <div className="transition_table">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">Detail</th>
                              <th scope="col">Debit</th>
                              <th scope="col">Credit</th>
                              <th scope="col">Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentPost &&
                              currentPost
                                // .filter((data, index) => index < 10)
                                .map((data, i) => (
                                  <tr key={i}>
                                    <td scope="row">
                                      {moment(data.created_at).format(
                                        "MMM DD, LT"
                                      )}
                                    </td>
                                    <td>{data.remark}</td>
                                    <td
                                      className={`${
                                        data.amount_type === 2
                                          ? "value_down"
                                          : ""
                                      }`}
                                    >
                                      {data.amount_type === 2 ? (
                                        <>
                                          <i className="fa-solid fa-arrow-down" />
                                          {data.amount}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td className="value_up">
                                      {data.amount_type === 1 ? (
                                        <>
                                          <i className="fa-solid fa-arrow-up" />
                                          {data.amount}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>{data.total_available_amt}</td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                        <Pagination
                          setShowPerPage={setShowPerPage}
                          showPerPage={showPerPage}
                          totalPosts={
                            getUserMyTransaction &&
                            getUserMyTransaction.result &&
                            getUserMyTransaction.result.length
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                <Banner />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <WithDrawModel />
      <ReloadModel />
    </>
  );
};

export default Wallet;
