import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
import Banner from "../Banner/Banner";
import { useDispatch, useSelector } from "react-redux";
import {
  onUserAwardsDetails,
  onUserBalance,
  onUserRedeemAwards,
} from "../../redux/homeAction";
import { useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import Loader from "../loader/Loader";

const AwardsItem = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [loader, setLoader] = useState(false);
  const classSend = "position-relative";
  const getUserAwardsDetails = useSelector(
    (state) => state.home.getUserAwardsDetails
  );
  const userDeatils = JSON.parse(localStorage.getItem("userDetails"));
  const getUserBalance = useSelector((state) => state.home.getUserBalance);

  useEffect(() => {
    setLoader(true);
    const data = {
      user_id: userDeatils && userDeatils.user_id,
      awards_id: id,
    };
    dispatch(onUserAwardsDetails(data, setLoader));
  }, []);

  useEffect(() => {
    const data = {
      user_id: userDeatils && userDeatils.user_id,
    };
    dispatch(onUserBalance(data));
  }, []);

  const awardClaim = (id) => {
    const data = {
      user_id: userDeatils && userDeatils.user_id,
      awards_id: id,
    };
    dispatch(onUserRedeemAwards(data));
  };

  return (
    <>
      <Navbar classSend={classSend} />
      <section id="awardsItem">
        <div className="container">
          <div className="row gy-5">
            <div className="col-md-6">
              <div className="swiper ">
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {getUserAwardsDetails &&
                    getUserAwardsDetails.image &&
                    getUserAwardsDetails.image.map((data, i) => (
                      <SwiperSlide key={i}>
                        <div className="item_img">
                          <img src={data} className="product_item" />
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
              <div className="swiper ">
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {getUserAwardsDetails &&
                    getUserAwardsDetails.image &&
                    getUserAwardsDetails.image.map((data, i) => (
                      <SwiperSlide key={i}>
                        <div className="item_img">
                          <img src={data} className="product_item" />
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
            <div className="col-md-6">
              {loader ? (
                <div className="text-center mt-4">
                  <Loader />
                </div>
              ) : (
                <div className="item_detail">
                  <h1>{getUserAwardsDetails && getUserAwardsDetails.tittle}</h1>
                  <div className="product_information">
                    <p className="boldP">Product information</p>
                    <div className="product_table">
                      <div className="brand_detail">
                        <p className="boldP">Price</p>
                        <p>
                          {getUserAwardsDetails && getUserAwardsDetails.price}
                        </p>
                      </div>
                      {getUserAwardsDetails &&
                        getUserAwardsDetails.product_information &&
                        getUserAwardsDetails.product_information.map(
                          (data, i) =>
                            Object.entries(data).map(([k, v]) => {
                              return (
                                <div className="brand_detail" key={i}>
                                  <p>{k}</p>
                                  <p>{v}</p>
                                </div>
                              );
                            })
                        )}
                    </div>
                    <hr />
                    <div className="product_descrpition">
                      <p
                        className="P_gry_normal"
                        dangerouslySetInnerHTML={{
                          __html:
                            getUserAwardsDetails &&
                            getUserAwardsDetails.description,
                        }}
                      ></p>
                    </div>
                    {parseFloat(getUserBalance && getUserBalance.balance) >
                    parseFloat(
                      getUserAwardsDetails && getUserAwardsDetails.price
                    ) ? (
                      <div
                        className="btn post_btn btnFill"
                        onClick={() =>
                          awardClaim(
                            getUserAwardsDetails && getUserAwardsDetails.id
                          )
                        }
                      >
                        <div className="btnfilltext">Apply For</div>
                      </div>
                    ) : (
                      <div className="btn post_btn btnFill mt-3 mb-3">
                        <div className="btnfilltext">insufficient balance</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <Banner />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AwardsItem;
