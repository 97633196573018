import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC71h7xemRogpfVHBrWMRU7TY3_j3DBSXQ",
  authDomain: "triunfador-d9349.firebaseapp.com",
  projectId: "triunfador-d9349",
  storageBucket: "triunfador-d9349.appspot.com",
  messagingSenderId: "707396711182",
  appId: "1:707396711182:web:b7ad30a9d41c6ce579dd5f",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
